import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BundleOverviewComponent} from './bundle-overview/bundle-overview.component';
import {RouterModule, Routes} from "@angular/router";
import {OfferPagePanelModule} from "../offer-page-panel/offer-page-panel.module";

import {GlobalModule} from "../global/global.module";
import {FlexLayoutModule} from "@angular/flex-layout";
import {ClickOutsideModule} from 'ng-click-outside';
import {PublicBundleResource} from "../generated/cms/resources/public-bundle.resource";

const appRoutes: Routes = [
  {path: 'kategorien/:identifier', component: BundleOverviewComponent}
];

@NgModule({
  declarations: [BundleOverviewComponent],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    OfferPagePanelModule,
    GlobalModule,
    FlexLayoutModule,
    ClickOutsideModule
  ],
  providers: [
    PublicBundleResource
  ]
})
export class BundleModule {
}
