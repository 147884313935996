import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectStudyEndYearComponent} from "../select-study-end-year/select-study-end-year.component";
import {FlexModule} from "@angular/flex-layout";
import {BrowserModule} from "@angular/platform-browser";
import {LocationSelectV1Component} from "../location-select-v1/location-select-v1.component";
import {GlobalModule} from "../../../global/global.module";
import {HidInteractionsModule} from "../../../hid-interactions/hid-interactions.module";
import {GeolocationComponent} from "../geolocation/geolocation.component";
import {SuccessBarComponent} from "../success-bar/success-bar.component";


@NgModule({
  declarations: [
    SelectStudyEndYearComponent,
    LocationSelectV1Component,
    GeolocationComponent,
    SuccessBarComponent
  ],
  imports: [
    CommonModule,
    FlexModule, BrowserModule,
      GlobalModule, HidInteractionsModule
  ],
  exports:[
    SelectStudyEndYearComponent,
      LocationSelectV1Component,
    GeolocationComponent,
    SuccessBarComponent,
  ]
})
export class GlobalCheckoutComponentsModule { }
