<a fxLayout="column" fxLayoutAlign="space-between center"
   class="image-hover offer-panel pointer ita_offer-panel ita_format-{{this.page.cronosFormatId || 0}} ita_page-split-{{this.page.selectedSplitId || 0}}"
   [routerLink]=" (page.type == 'BlogPost'? '/blog/': '/angebote/') + this.page.routingUrl" [queryParams]="queryParams">
  <div class="image-wrap">
    <img id="offer-image" alt="{{altText}}" #image [src]="imageSrc" (load)="loadingImage = false">
    <div class="fake-image fake" *ngIf="loadingImage"></div>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between start" class="description"  *ngIf="!loading">
    <div class="title" [ngClass]="{'small-title': longWordInPageTitle}" [innerHTML]="page.title | safeHtml"></div>
    <!--TODO Dynamic-->
    <div class="rating" fxLayout="row" fxLayoutAlign="start center" *ngIf="page.type != 'BlogPost'">
      <span class="stars">
       <app-feedback-stars [rating]="rating" [scaleDownOnMobile]="true"></app-feedback-stars>
      </span>
      <span class="review-summary" *ngIf="page.count < 50">
        Neu<span *ngIf="!mediaService.isMobile()">es Angebot</span>!
      </span>
      <span class="review-summary" *ngIf="page.count >= 50">
        ({{page.count | number: '':'de-DE'}} <span>{{page.engagementType}}</span>)
      </span>

    </div>
  </div>
  <div class="loading-overlay" *ngIf="loading">
    <div class="title">
      <div class="fake-title fake fake-title-prime"></div>
      <div class="fake-title fake fake-title-second"></div>
    </div>
    <div class="rating">
      <span class="fake-stars fake">
        <i class="fa fa-circle"></i>
        <i class="fa fa-circle"></i>
        <i class="fa fa-circle"></i>
        <i class="fa fa-circle"></i>
        <i class="fa fa-circle"></i>
      </span>
      <span class="review-summary">
        <span class="fake-review-summary fake"></span>
      </span>
    </div>
  </div>
</a>
