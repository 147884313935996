import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-standard-checkout-gateway',
  templateUrl: './standard-checkout-gateway.component.html',
  styleUrls: ['./standard-checkout-gateway.component.sass']
})
export class StandardCheckoutGatewayComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.proceed();
  }

  proceed() {
    let offerUrl = (this.route.params as BehaviorSubject<any>).value['routingUrl'];
    let random = Math.floor(Math.random() * 2);
    let url = 'angebote/' + offerUrl + '/checkout/standard/s1/entry';
    if (random == 1) {
      url = 'angebote/' + offerUrl + '/checkout/standard/s2/entry';
    }
    this.router.navigate([url], {queryParamsHandling: 'preserve'});
  }
}
