import {Component, OnInit} from '@angular/core';
import {DisplayServiceFormData, EnterServiceFormData, PublicStudyAreaData} from "../../generated/cronos/data";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {ServiceFormResource} from "../../generated/cronos/resources";
import {ActivatedRoute, Router} from "@angular/router";
import {MediaService} from 'src/app/media.service';
import {UtilsService} from "../../utils.service";
import {ContactSupportModalComponent} from "../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PhoneNumberFormat, PhoneNumberUtil} from "google-libphonenumber";

@Component({
  selector: 'app-mlp-service-form',
  templateUrl: './mlp-service-form.component.html',
  styleUrls: ['./mlp-service-form.component.scss']
})
export class MlpServiceFormComponent implements OnInit {

  constructor(private fb: FormBuilder,
              public serviceFormResource: ServiceFormResource,
              private route: ActivatedRoute,
              public mediaService: MediaService,
              private utilsService: UtilsService,
              private modal: NgbModal,
              private router: Router) {
  }

  contactTopicsThesis: string [] = ['Karrieremöglichkeiten bei MLP', 'Potenzialanalyse G.P.O.P', 'Gehaltsanalyse', 'Firmendatenbank',
    'Bewerbungscheck', 'Gehaltsverhandlungsseminar', 'Financial Education', 'Bewerbungstraining'];
  contactTopicsExcel: string [] = ['Gehaltsverhandlungsseminar', 'Bewerbungsmappencheck', 'MLP-Stipendienprogramm', 'Rhetorik & Präsentations-Training',
    'Thesis-Guide', 'Financial Education', 'Intelligent Geld anlegen', 'Studienkosten vom Staat zurück holen', 'Strategisches Mehrkontenmodell',
    'Optimierte Berufsunfähigkeitsversicherung'];
  careerOfferTopics: string [] = ['Finanzberater (m/w/d)', 'Praktikum Finanzberater (m/w/d)', '1 Tag als MLP-Berater erleben',
    'MLP erleben', 'MLP Karriere Lounge'];

  createForm: FormGroup;

  loadingServiceFormData: boolean = true;
  loadingStudyAreas: boolean = true;
  token: string;
  formatTitle: string;
  initialServiceFormData: DisplayServiceFormData;
  serviceFormDataInitallyLoaded: boolean = false;
  studyAreasInitiallyLoaded: boolean = false;
  universityData = [];
  studyAreas: PublicStudyAreaData[] = [];
  enteringFormData: boolean = false;
  maxBirthDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  degreeTypes: string [] = ['Bachelor', 'Master'];
  invalidLink: boolean = false;
  phoneUtil = PhoneNumberUtil.getInstance()

  ngOnInit(): void {
    this.createForm = this.fb.group({
      contactTopics: this.fb.array([]),
      careerOfferTopics: this.fb.array([]),
      feedbackAppointmentDate: [new Date(), [Validators.required, this.validateDateInFuture()]],
      gender: ['Unknown', [Validators.required]],
      lastName: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, this.utilsService.validatePhoneNumber()]],
      email: ['', [Validators.required, Validators.email]],
      birthDate: [null, [this.validateBirthdate()]],
      universityName: ['',],
      studyAreaId: [null,],
      degreeType: [null,],
      semester: [null, [Validators.max(16)]],
      studyEndDate: [new Date(),],
      hadAlreadyContactWithMLP: [false],
      alreadyAdvisingConsultantName: [''],
      notes: [''],
      privacyContactLegitimization: [false, [Validators.requiredTrue]]
    })
    this.token = this.route.snapshot.queryParamMap.get("code");
    this.formatTitle = this.route.snapshot.queryParamMap.get("format");
    this.createForm.controls['hadAlreadyContactWithMLP'].valueChanges.subscribe(event => {
      if (event) {
        this.createForm.controls['alreadyAdvisingConsultantName'].setValidators([Validators.required, Validators.minLength(2)])
        this.createForm.controls['alreadyAdvisingConsultantName'].markAsTouched()
        this.createForm.controls['alreadyAdvisingConsultantName'].updateValueAndValidity()

      } else {
        this.createForm.controls['alreadyAdvisingConsultantName'].clearValidators()
        this.createForm.controls['alreadyAdvisingConsultantName'].markAsUntouched()
        this.createForm.controls['alreadyAdvisingConsultantName'].updateValueAndValidity()

      }
    })
    this.loadData();
  }

  loadData() {
    this.loadContactData();
    this.loadStudyAreas();
    // this.serviceFormDataInitallyLoaded = true;
  }

  loadContactData() {
    this.loadingServiceFormData = true;
    if (this.token) {
      this.serviceFormResource.getServiceFormInformationWithCode(this.token).then(
        result => {
          this.initialServiceFormData = result;
          if (result.tokenState == 'INVALID') {
            this.loadingServiceFormData = false;
            this.invalidLink = true;
            return
          }
          this.formatTitle = this.initialServiceFormData.promotionData.title
          this.loadingServiceFormData = false;
          this.serviceFormDataInitallyLoaded = true;
          if (this.initialServiceFormData.alreadyExists == false && this.initialServiceFormData.tokenState == 'VALID') {
            this.updateFormGroup();
          }
        }
      )
    } else {
      if (!this.formatTitle) {
        this.invalidLink = true;
        this.loadingServiceFormData = false;
        return
      }
      this.serviceFormResource.getServiceFormInformationWithoutCode().then(
        result => {
          this.initialServiceFormData = result;
          this.formatTitle;
          this.loadingServiceFormData = false;
          this.serviceFormDataInitallyLoaded = true;

        }
      )
    }

  }

  loadStudyAreas() {
    this.loadingStudyAreas = true;
    this.serviceFormResource.getAllStudyAreas().then(
      result => {
        this.studyAreas = result;
        this.loadingStudyAreas = false;
        this.studyAreasInitiallyLoaded = true;
      }
    )
  }

  updateFormGroup() {
    this.createForm.controls['gender'].setValue(this.initialServiceFormData.contactData.gender);
    this.createForm.controls['lastName'].setValue(this.initialServiceFormData.contactData.lastName);
    this.createForm.controls['firstName'].setValue(this.initialServiceFormData.contactData.firstName);
    this.createForm.controls['phoneNumber'].setValue(this.initialServiceFormData.contactData.phoneNumber);
    this.createForm.controls['email'].setValue(this.initialServiceFormData.contactData.email);
    this.createForm.controls['birthDate'].setValue(this.initialServiceFormData.contactData.birthDate);
    this.createForm.controls['universityName'].setValue(this.initialServiceFormData.contactData.universityName);
    this.createForm.controls['studyAreaId'].setValue(this.initialServiceFormData.contactData.studyAreaId);
    this.createForm.controls['semester'].setValue(this.initialServiceFormData.contactData.semester);
    this.createForm.controls['studyEndDate'].setValue(this.initialServiceFormData.contactData.studyEndDate);
  }

  onChange(event, arrayName: string) {
    const formArray = this.createForm.controls[arrayName] as FormArray
    if (event.checked) {
      formArray.push(new FormControl(event.source.value))
    } else {
      const i = formArray.controls.findIndex(x => x.value === event.source.value)
      formArray.removeAt(i)
    }
  }

  enterFormData() {
    this.enteringFormData = true;
    let formData = this.createForm.value;
    let studyAreaId = null;
    if (formData.studyAreaId) {
      studyAreaId = formData.studyAreaId.id
    }
    let parsedPhoneNumber = this.phoneUtil.parseAndKeepRawInput(formData.phoneNumber)
    let data: EnterServiceFormData = {
      contactData: {
        gender: formData.gender,
        lastName: formData.lastName,
        firstName: formData.firstName,
        phoneNumber: this.phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164),
        email: formData.email,
        birthDate: formData.birthDate,
        universityName: formData.universityName,
        studyAreaId: studyAreaId,
        semester: formData.semester,
        degreeType: formData.degreeType,
        studyEndDate: formData.studyEndDate
      },
      contactTopics: formData.contactTopics,
      careerOfferTopics: formData.careerOfferTopics,
      feedbackAppointmentDate: formData.feedbackAppointmentDate,
      hadAlreadyContactWithMLP: formData.hadAlreadyContactWithMLP,
      alreadyAdvisingConsultantName: formData.alreadyAdvisingConsultantName,
      notes: formData.notes,
      privacyContactLegitimization: formData.privacyContactLegitimization
    };
    if (formData.hadAlreadyContactWithMLP == false) {
      data.alreadyAdvisingConsultantName = null
    }
    if (this.token) {
      this.serviceFormResource.enterServiceFormDataWithCode(data, this.token).then(
        () => {
          this.enteringFormData = false;
          this.router.navigateByUrl("actions/service-form/redirect")
        }
      )
    } else {
      this.serviceFormResource.enterServiceFormDataWithoutCode(data).then(
        () => {
          this.enteringFormData = false;
          this.router.navigateByUrl("actions/service-form/redirect")
        }
      )
    }


  }

  /***
   * validate if contact is at least 18 at point of seminar
   */
  validateBirthdate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      //check for default value
      if (this.sameDay(control.value, new Date())) {
        return null
      }

      let eighteenYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 18))
      if (control.value > eighteenYearsAgo) {
        return {isYoungerThanEighteen: true};
      }
      return null;
    }
  }

  sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  /***
   * validate that date is at least today or in the future
   */
  validateDateInFuture(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return {isNull: true};
      }
      let yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
      if (control.value < yesterday) {
        return {isBeforeToday: true};
      }
      return null;
    }
  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }

  goHome() {
    this.router.navigate(['/home'], {queryParamsHandling: 'preserve'})
  }


}
