import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PublicStaticPageResource} from "../../generated/cms/resources";
import {IsolatedPageData} from "../../generated/cms/data";

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent implements OnInit {

  pageIdentifier;
  page: IsolatedPageData;

  constructor(
    private route: ActivatedRoute,
    private publicStaticPageResource: PublicStaticPageResource
  ) {
    route.params.subscribe(params => {
      this.pageIdentifier = params['identifier'];
      this.load();
    })
  }

  ngOnInit() {
  }


  load() {
    this.publicStaticPageResource.getStaticPage(this.pageIdentifier).then(res => {
      this.page = res;
    })
  }
}
