import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, AccountRegisterData, PasswordResetData, EmailConfirmationData, AccountInfoData, PasswordResetRequestData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.AccountController
 */

@Injectable()
@ResourceParams({})
export class AccountResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/email/confirm',
        endpoint: 'cronos'
    })
    private _confirmEmail: IResourceMethodObservableStrict<void, {emailConfirmationToken: string}, {}, EmailConfirmationData>;
    confirmEmail(requestParams: {emailConfirmationToken: string}): Promise<EmailConfirmationData> {
        return this._confirmEmail(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/account/info',
        endpoint: 'cronos'
    })
    private _getInfo: IResourceMethodObservableStrict<void, {}, {}, AccountInfoData>;
    getInfo(): Promise<AccountInfoData> {
        return this._getInfo(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/account/email/confirm',
        endpoint: 'cronos'
    })
    private _isEmailConfirmed: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<boolean>>;
    isEmailConfirmed(): Promise<JsonWrappedValue<boolean>> {
        return this._isEmailConfirmed(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/survey/logSubmitted',
        endpoint: 'cronos'
    })
    private _logSurveySubmitted: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<number>>;
    logSurveySubmitted(): Promise<JsonWrappedValue<number>> {
        return this._logSurveySubmitted(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/token/login',
        endpoint: 'cronos'
    })
    private _loginWithToken: IResourceMethodObservableStrict<string, {}, {}, JsonWrappedValue<string>>;
    loginWithToken(requestBody: string): Promise<JsonWrappedValue<string>> {
        return this._loginWithToken(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/account/token',
        endpoint: 'cronos'
    })
    private _refreshToken: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<string>>;
    refreshToken(): Promise<JsonWrappedValue<string>> {
        return this._refreshToken(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/register',
        endpoint: 'cronos'
    })
    private _register: IResourceMethodObservableStrict<AccountRegisterData, {}, {}, JsonWrappedValue<string>>;
    register(requestBody: AccountRegisterData): Promise<JsonWrappedValue<string>> {
        return this._register(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/password/reset-request',
        endpoint: 'cronos'
    })
    private _requestPasswordReset: IResourceMethodObservableStrict<PasswordResetRequestData, {}, {}, void>;
    requestPasswordReset(requestBody: PasswordResetRequestData): Promise<void> {
        return this._requestPasswordReset(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/email/resend',
        endpoint: 'cronos'
    })
    private _resendConfirmMail: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<boolean>>;
    resendConfirmMail(): Promise<JsonWrappedValue<boolean>> {
        return this._resendConfirmMail(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/account/password/reset',
        endpoint: 'cronos'
    })
    private _resetPassword: IResourceMethodObservableStrict<PasswordResetData, {}, {}, void>;
    resetPassword(requestBody: PasswordResetData): Promise<void> {
        return this._resetPassword(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/account/email',
        endpoint: 'cronos'
    })
    private _setAccountEmail: IResourceMethodObservableStrict<void, {emailAddress: string}, {}, void>;
    setAccountEmail(requestParams: {emailAddress: string}): Promise<void> {
        return this._setAccountEmail(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/account/name',
        endpoint: 'cronos'
    })
    private _setAccountName: IResourceMethodObservableStrict<void, {displayName: string}, {}, void>;
    setAccountName(requestParams: {displayName: string}): Promise<void> {
        return this._setAccountName(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/account/avatar',
        endpoint: 'cronos'
    })
    private _setAvatarId: IResourceMethodObservableStrict<void, {avatarId: number}, {}, void>;
    setAvatarId(requestParams: {avatarId: number}): Promise<void> {
        return this._setAvatarId(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/account/password/reset/token',
        endpoint: 'cronos'
    })
    private _validatePasswordRequestTokenExists: IResourceMethodObservableStrict<void, {tokenValue: string}, {}, JsonWrappedValue<boolean>>;
    validatePasswordRequestTokenExists(requestParams: {tokenValue: string}): Promise<JsonWrappedValue<boolean>> {
        return this._validatePasswordRequestTokenExists(null, requestParams, null).toPromise()
    }

}