import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {LinkHubItemData, LinkHubPageData} from "../../generated/cms/data";
import {PublicLinkHubResource} from "../../generated/cms/resources";
import {ActivatedRoute} from "@angular/router";
import {buildContainerImageUrl} from "../../utils.service";

@Component({
  selector: 'app-link-hub-page',
  templateUrl: './link-hub-page.component.html',
  styleUrls: ['./link-hub-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LinkHubPageComponent implements OnInit {

  @Input() pathName: string;

  linkHubData : LinkHubPageData;
  style: string = "default";
  imageSrc: string;

  @ViewChild("image", { static: true }) image: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private linkHubResource: PublicLinkHubResource
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {

        let previewId = params["preview"];

        if (previewId != null) {

            this.linkHubResource.getSplitPreview(previewId).then(
                result => {
                    this.linkHubData = result

                    if(this.linkHubData.utmParameter) {
                        this.setUTMTags(this.linkHubData.items)
                    }

                    this.setStyling(this.linkHubData);
                    this.imageSrc = buildContainerImageUrl(this.image, this.linkHubData.logoId)
                }
            );
            return
        }
        this.route.params.subscribe(params => {
            this.pathName = params['pathName'];
            this.loadLinkHubPageData();
        });
    });


  }

  loadLinkHubPageData() {
    this.linkHubResource.getPageByRoutingUrl(this.pathName).then(linkHubData => {
      this.linkHubData = linkHubData

      if(this.linkHubData.utmParameter) {
          this.setUTMTags(this.linkHubData.items)
      }

      this.setStyling(linkHubData);
      this.imageSrc = buildContainerImageUrl(this.image, linkHubData.logoId)
    });
  }

  setStyling(linkHubData: LinkHubPageData)  {
      this.style = linkHubData.styleType || "default";

      if(linkHubData.customBackgroundCss) {
          this.elementRef.nativeElement.ownerDocument
              .body.style = linkHubData.customBackgroundCss;
      } else {
          this.elementRef.nativeElement.ownerDocument.
          body.classList.add("style-" + this.style + "-background");
      }
  }

  setUTMTags(items: LinkHubItemData[]) {
      items.forEach(item => {
          if (item["url"] && item["useUtmParameter"]) {
              item["url"] = item["url"] + (item["url"].includes("?") ? "&" : "?") + this.linkHubData.utmParameter + '&utm_campaign=' + item["utmCampaign"];
          }
          if (item["links"]) {
              this.setUTMTags(item["links"]);
          } else if (item["icons"]){
              this.setUTMTags(item["icons"]);
          }
      });
  }

  transformLabel(label: string): string {
    return label.replace(/\n/g, '<br>');
  }

  readonly window = window;
}
