import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {ShareOverlayComponent} from "./share-overlay/share-overlay.component";

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }


  showSharingButtons(url: string, utmCampaignIdent: string = null, shareText: string = null, additionalQueryParams: any = null) {
    const comp = this.resolver
      .resolveComponentFactory(ShareOverlayComponent)
      .create(this.injector);


    comp.instance.selfDestroy.subscribe(() => {
      this.destroyOverlay(comp)
    });
    comp.instance.subUrl = url;
    // comp.instance.utmShareSource = utmSourceIdent;
    comp.instance.utmCampaignIdent = utmCampaignIdent;
    comp.instance.shareText = shareText;
    comp.instance.additionalQueryParams = additionalQueryParams;

    this.appRef.attachView(comp.hostView);

    const dom = (comp.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(dom)
  }

  destroyOverlay(comp: any) {
    comp.instance.initialized = false;
    setTimeout(() => {
      this.appRef.detachView(comp.hostView);
      comp.destroy()
    },300)

  }
}
