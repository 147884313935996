import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {OfferPagePanelData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.OfferPanelsController
 */

@Injectable()
@ResourceParams({})
export class OfferPanelsResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/offers/panels/{pageId}',
        endpoint: 'cms'
    })
    private _createOrUpdatePanelForPage: IResourceMethodObservableStrict<OfferPagePanelData, {}, {pageId: number}, void>;
    createOrUpdatePanelForPage(requestBody: OfferPagePanelData, pageId: number): Promise<void> {
        return this._createOrUpdatePanelForPage(requestBody, null, {pageId: pageId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/offers/panels/{pageId}',
        endpoint: 'cms'
    })
    private _getOfferPanelForPage: IResourceMethodObservableStrict<void, {}, {pageId: number}, OfferPagePanelData>;
    getOfferPanelForPage(pageId: number): Promise<OfferPagePanelData> {
        return this._getOfferPanelForPage(null, null, {pageId: pageId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/offers/panels/{pageId}/bbCodeIdentifier',
        endpoint: 'cms'
    })
    private _setBBCodeIdentifier: IResourceMethodObservableStrict<string, {}, {pageId: number}, void>;
    setBBCodeIdentifier(requestBody: string, pageId: number): Promise<void> {
        return this._setBBCodeIdentifier(requestBody, null, {pageId: pageId}).toPromise()
    }

}