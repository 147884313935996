import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PromotionApiResourceV2, SeminarProcessAPIResourceV2} from "../../../../generated/cronos/resources";
import { RegistrationInfoData
} from "../../../../generated/cronos/data";
import {ContactSupportModalComponent} from "../../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LocalStorageService} from "../../../../local-storage.service";
import {PublicOfferPageResource} from "../../../../generated/cms/resources";
import {MatDialog} from "@angular/material/dialog";
import {UtilsService} from "../../../../utils.service";
import {ShareService} from "../../../../share/share.service";
import {RegistrationInfoService} from "../../registration-info.service";

@Component({
  selector: 'app-check-in-for-seminar',
  templateUrl: './check-in-for-seminar.component.html',
  styleUrls: ['./check-in-for-seminar.component.scss']
})
export class CheckInForSeminarComponent implements OnInit {

  checkInLoading: boolean = false;
  checkInDataLoaded: boolean = false;
  checkInFinished: boolean = false;

  token: string = '';
  registrationInfo: RegistrationInfoData = <RegistrationInfoData>{};
  seminarFeedbackPageDataLoading: boolean = true;
now = new Date();
  constructor(
    private route: ActivatedRoute,
    private seminarProcessAPIResourceV2: SeminarProcessAPIResourceV2,
    private router: Router,
    private modal: NgbModal,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private publicOfferPageResource: PublicOfferPageResource,
    private utilsService: UtilsService,
    private registrationInfoService: RegistrationInfoService,
  ) {
  }

  ngOnInit() {
    this.getTokenInformation();
  }

  getTokenInformation() {
    this.seminarFeedbackPageDataLoading = true;
    this.token = this.route.snapshot.queryParamMap.get("code");
    if(this.token) {
      this.registrationInfoService.getRegistrationInfoForToken(this.token).then(result => {
        this.registrationInfo = result;
        this.checkInDataLoaded = true

        if (this.registrationInfo.promotionData.promotionType == 'OfflineSeminarDate') {
          this.registrationInfo.promotionData.address = this.utilsService.deleteCityNameOutOfAddress(
          this.registrationInfo.promotionData.location, this.registrationInfo.promotionData.address)
        }
        if (this.registrationInfo.checkedIn == true) this.checkInFinished = true;
        this.seminarFeedbackPageDataLoading = false;
      })
    }

  }

  checkIn() {
    this.checkInLoading = true;
    this.seminarProcessAPIResourceV2.checkInContactToSeminarByCheckInToken(this.token).then(result => {
      this.checkInFinished = true;
      this.checkInLoading = false;
    })
  }

  goHome() {
    this.router.navigate(["home"], {queryParamsHandling: 'preserve'})
  }



  openSupportModal() {
    this.modal.open(ContactSupportModalComponent);
  }

  routeToCancellation() {
   this.router.navigate(['actions/promotion-state/cancel'],{queryParams:{code: this.token}})
  }

}
