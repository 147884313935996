import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ContactSupportModalComponent} from "../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {ActionLinkAPIResourceV2, WaitingListAPIResourceV2} from "../../../generated/cronos/resources";
import {TokenFeedbackData} from "../../../generated/cronos/data";

@Component({
  selector: 'app-unsub-waiting-list-action-link',
  templateUrl: './unsub-waiting-list-action-link.component.html',
  styleUrls: ['./unsub-waiting-list-action-link.component.scss']
})
export class UnsubWaitingListActionLinkComponent implements OnInit {


  token: string;

  iteminfo: TokenFeedbackData;

  loading = true;

  submitted = false;
  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private cronosApi:WaitingListAPIResourceV2
  ) {
    route.snapshot.queryParamMap.get("code") ? this.token = route.snapshot.queryParamMap.get("code") : this.token = route.snapshot.paramMap.get("token");
  }

  ngOnInit() {
  }


  submit() {
    this.loading = true;
    this.cronosApi.unsubFromWaitingList(this.token).then( res =>{
      this.iteminfo = res;
      this.submitted = true;
      this.loading = false;
    })
  }

  openSupportModal() {
   this.modal.open(ContactSupportModalComponent);
  }
}
