import {Component, Inject, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {HomePageResource, PublicBundleResource, PublicOfferPageResource} from "../../generated/cms/resources";
import {MediaService} from "../../media.service";

@Component({
  selector: 'app-offerpage-deactivated',
  templateUrl: './offerpage-deactivated.component.html',
  styleUrls: ['./offerpage-deactivated.component.scss']
})
export class OfferpageDeactivatedComponent implements OnInit {

  recommendedUpsellPageIds: number[] = [];
  pageId: number;

  constructor(private homeResource: HomePageResource,
              private offerPageResource: PublicOfferPageResource,
              private bundleResource: PublicBundleResource,
              @Inject("env") private env,
              private mediaService: MediaService,
              private modal: NgbModal,
              private router: Router,
              private route: ActivatedRoute,
              private snackBar: HidSnackbarService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.pageId = params['id'];
      this.offerPageResource.getOfferPageUpsellRecommendations(this.pageId).then(res => {
        this.recommendedUpsellPageIds = res;
      })


    })
  }

  goHome() {
    this.router.navigate(['/home'],{queryParamsHandling: 'preserve'})
  }
}
