import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-select-study-end-year',
  templateUrl: './select-study-end-year.component.html',
  styleUrls: ['./select-study-end-year.component.scss']
})
export class SelectStudyEndYearComponent implements OnInit {

  @Input() control = new FormControl()

  studyEndOptions = []

  constructor() { }

  ngOnInit(): void {
    this.initStudyEndOptions()
  }

  initStudyEndOptions() {
    let currentYear = new Date().getFullYear()
    this.studyEndOptions.push(currentYear, currentYear + 1, currentYear + 2, currentYear + 3, currentYear + 4, currentYear + 5)

  }

}
