import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PromotionApiResourceV2} from "../../generated/cronos/resources";
import {InternalCookieService} from "../../internal-cookie.service";

@Component({
  selector: 'app-webinar-reminder-popup',
  templateUrl: './webinar-reminder-popup.component.html',
  styleUrls: ['./webinar-reminder-popup.component.scss']
})
export class WebinarReminderPopupComponent implements OnInit {

  webinarLink: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<WebinarReminderPopupComponent>) { }

  ngOnInit(): void {
    this.webinarLink = "webinar/" + this.data.imminentWebinarDate.formatId + "/validate/" + this.data.promRegStateCode;

  }

  close() {
    this.dialogRef.close()
  }
}
