<div class="login-menu-wrapper" fxLayout="column" fxLayoutAlign="center center" (keydown.enter)="register()" [formGroup]="form">

  <h2 class="login-header">Jetzt Registrieren</h2>


  <form [formGroup]="form">
    <input class="login-input" placeholder="Vorname" formControlName="firstName">
    <input class="login-input" type="email" placeholder="Email" formControlName="email">
    <input class="login-input" placeholder="Passwort" formControlName="password" type="password">

    <div fxLayout="column" fxLayoutAlign="start start" id="password-hints" *ngIf="form.controls.password.value != '' &&
      form.controls.password.value != null
    ">
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" [ngClass]="{'valid': form.controls.password.value && form.controls.password.value.length > 5}">
        <i class="fas fa-check"></i>
        <div class="text-pw">Passwort muss mindestens <b>6 Zeichen lang</b> sein</div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" [ngClass]="{'valid': containsSmallLetter()}"><i class="fas fa-check">

      </i>
        <div class="text-pw">Passwort muss <b>einen Kleinbuchstaben</b> enthalten</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" [ngClass]="{'valid': containsBigLetterOrNumber()}">
        <i class="fas fa-check"></i>
        <div class="text-pw">Passwort muss <b>einen Großbuchstaben oder eine Zahl</b> enthalten</div>
      </div>
    </div>
  </form>

  <hid-checkbox inputClass="ita_check_privacy" (click)="$event.stopPropagation();$event.preventDefault();"
                style="font-size: 14px; margin-top:20px;"
                [noPadding]="true"
                [control]="form.controls['termsAndConditionsConsent']"
  >
    Ich willige in die Verarbeitung gemäß der Erklärung
    auf der Datenschutzseite ein.
  </hid-checkbox>
  <a style="z-index: 10; font-size: 12px" routerLink="/datenschutz"  class="ita_view_privacy" >Zur Datenschutzseite</a>
  <div fxLayout="row" style="margin-top: 10px;" class="w-100 center-content" fxLayoutAlign="center center">
    <button style="position:relative;" [disabled]="!validForRegistration || registrationInProgress" [ngClass]="{'ita_initiate-checkout': calledFromLp}" class="signup-button " (click)="register()" >
      <div *ngIf="registrationInProgress" class="lds-dual-ring"></div>
      Registrieren</button>
  </div>

</div>

