import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OnlineLectureService} from "../online-lecture.service";
import {LocalStorageService} from "../../local-storage.service";

@Component({
  selector: 'app-online-lecture-redirect',
  templateUrl: './online-lecture-redirect.component.html',
  styleUrls: ['./online-lecture-redirect.component.scss']
})
export class OnlineLectureRedirectComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public onlineLectureService: OnlineLectureService,
    public localStorageService: LocalStorageService,
  ) {
  }

  ngOnInit() {
    const token = this.route.snapshot.params.token;
    const cronosFormatId = this.route.snapshot.params.cronosFormatId;

    this.localStorageService.setLectureAuthToken(token);

    this.onlineLectureService.loadFormatInitially(cronosFormatId).then(format => {
      this.router.navigateByUrl('/onlinelecture/' + cronosFormatId + '/lesson/' + format.lessons[0].id);
    });
  }
}
