import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import { MlpServiceFormTriggerEmailPageComponent } from './mlp-service-form-trigger-email-page/mlp-service-form-trigger-email-page.component';
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";
import {FlexModule} from "@angular/flex-layout";
import {MatTooltipModule} from "@angular/material/tooltip";

const appRoutes: Routes = [
  {path: 'actions/service-form/consultant/trigger/token', component: MlpServiceFormTriggerEmailPageComponent}
]

@NgModule({
  declarations: [
    MlpServiceFormTriggerEmailPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    HidInteractionsModule,
    FlexModule,
    MatTooltipModule,
  ]
})
export class MlpServiceFormConsultantModule { }
