import {Component, Input, OnInit} from '@angular/core';
import {SupportResource} from "../../generated/cms/resources";
import {SupportMessageData} from "../../generated/cms/data";
import {MediaService} from "../../media.service";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
  selector: 'app-contact-support-modal',
  templateUrl: './contact-support-modal.component.html',
  styleUrls: ['./contact-support-modal.component.scss']
})
export class ContactSupportModalComponent implements OnInit {

  contactData: SupportMessageData;

  contactOptions: string[] = ['Allgemeine Frage', 'Frage zu einem Seminartermin']

  //implicit input via componentinstance: messagePlaceholder

  constructor(private supportResource: SupportResource, public mediaService: MediaService, private snackBar: HidSnackbarService) {
    this.contactData = {
      firstName: "",
      email: "",
      message: "",
      applicationId: "HID-Seite",
      userIdentifier: "anonymous",
      seminarDate: null,
      seminarLocation: null,
      supportMode: 'Allgemeine Frage'
    }
  }
  validationForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(32)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [Validators.required])
  })

  messagePlaceholder = 'Deine Nachricht*';



  ngOnInit() {

  }

  sendMessage() {
    this.contactData.firstName = this.validationForm.value.firstName;
    this.contactData.email = this.validationForm.value.email;
    this.contactData.message = this.validationForm.value.message;
    this.supportResource.sendSupportMessage(this.contactData).then(() => {
      this.close();
      this.snackBar.openSnackBar("Wir haben deine Nachricht erhalten", null, 5000, false)
    })
  }

  close() {
    this.mediaService.closeModals()
  }

}
