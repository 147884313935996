import {Component, Inject, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {delay, UtilsService} from "../../utils.service";
import {FacebookService} from "ngx-facebook";
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {PromotionApiResourceV2} from "../../generated/cronos/resources";
import {LocalStorageService} from "../../local-storage.service";
import {MediaService} from "../../media.service";
import {ClipboardService} from "ngx-clipboard";
import {ShareService} from "../../share/share.service";
import {PublicOfferPagePreviewData} from "../../generated/cms/data";
import {HttpClient} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../../environments/environment";
import {SeminarCheckoutService} from "../../checkout/seminar-checkout/seminar-checkout.service";
import {AngularIcsGeneratorService} from "angular-ics-generator";

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss']
})
//TODO this component is very request-heavy, check if you can simplify
//TODO this component has a lot of cases with different incoming data. you could structure it into subcomponents to improve maintainability. Eg one for the header, for the different success-sections(waitinglist, seminar)..
export class ThankYouPageComponent implements OnInit {

  showShareMenu = false;

  recommendedUpsellPageIds: number[] = [];

  sharelink: string;

  shareTextDescription = "Ich hab ein tolles Angebot auf HID für uns gefunden! Mach doch auch mit! "; //TODO check if needed dynamic

  seminarId: number; //if came from seminar
  seminarCookieCode: string;
  downloadingICSFile: boolean = false;

  // mandatory
  cronosFormatId: number;

  //the page from where the user came (where he initiated checkout)
  originalOfferPageId;
  originalOfferPage: PublicOfferPagePreviewData;

  mainTrackingClassString: string = '';

  waitingListSignup: boolean = false;
  //Includes info about the promotion the user actually sigend up for, no matter from what page he came from
  signUpInfo: any = {};

  loading = true;

  taPromotionRelevant: boolean = false;

  showTaPromotion: boolean = false;

  displayTaLogo: boolean = false;

  displayTaPromoText: boolean = false;

  promoFadingOut: boolean = false;

  mobile = true;

  constructor(
    @Inject("env") private env,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private offerPageResource: PublicOfferPageResource,
    private utilsService: UtilsService,
    private cronosApi: PromotionApiResourceV2,
    private fb: FacebookService,
    private router: Router,
    private clipboard: ClipboardService,
    private shareService: ShareService,
    private localStorageService: LocalStorageService,
    private snackBar: HidSnackbarService,
    private mediaService: MediaService,
    private httpClient: HttpClient,
    @Inject("env") private environment,
    private ngxCookieService: CookieService,
    private seminarCheckoutService: SeminarCheckoutService,
    private icsService: AngularIcsGeneratorService
  ) {
  }

  ngOnInit() {

    this.mobile = this.mediaService.isMobile();
    this.route.queryParams.subscribe(params => {
      this.originalOfferPageId = params['pid']
      this.seminarId = params['sid'];
      this.cronosFormatId = params['fid'];
      this.waitingListSignup = params['waitingListSignup'] == 'true';
      /*this.taPromotionRelevant = params['taPromotionRelevant'] == 'true'; TODO read from userdata from globalcheckoutservice*/
      this.loadPromotionInfos();
      this.displayTaPromotion(this.taPromotionRelevant);
    })
    if(this.seminarId) {
      this.seminarCookieCode = this.ngxCookieService.get("SeminarCookieCode-"+this.seminarId)
    }
  }

  loadPromotionInfos() {

    this.cronosApi.getPromotionFormatInfos(this.cronosFormatId).then(res => {
      this.signUpInfo.promotionType = res.type;
      this.signUpInfo.promotionTitle = res.title;
      this.signUpInfo.promotionFormatId = res.id;
      if (['OfflineSeminarFormat', 'WebinarFormat'].includes(this.signUpInfo.promotionType) && this.seminarId) {
        this.cronosApi.getPromotionInfos(this.seminarId).then(res => {
          this.signUpInfo.date = res.date;

          this.signUpInfo.promotionAddress = res.address;
          this.signUpInfo.cityName = res.cityName;
          this.loading = false;
        })
      } else {
        this.loading = false;
      }
      if (this.originalOfferPageId) {
        this.offerPageResource.getOfferPagePreviewData(this.originalOfferPageId).then(res => {
          this.originalOfferPage = res;
          this.finalizeInit()
        })
      }
    })

  }



  finalizeInit() {
    this.offerPageResource.getOfferPagePreviewData(this.originalOfferPage.offerPageId).then(page => {
      this.sharelink = window.location.origin + '/angebote/' + page.routingUrl;

    });
    this.getRecommendedOfferPageIds();

  }

  getRecommendedOfferPageIds() {
    try {
      this.offerPageResource.getOfferPageUpsellRecommendations(this.originalOfferPage.offerPageId).then(res => {
        this.recommendedUpsellPageIds = res;
      })
    } catch (e) {
    }

  }

  goHome() {
    this.router.navigate(["home"], {queryParamsHandling: 'preserve'})
  }

  openShareMenue() {
    let params = [];
    if (this.seminarId) {
      params.push({
        key: 'psid',
        value: this.seminarId
      })
    }

    this.shareService.showSharingButtons('/angebote/' + this.originalOfferPage.routingUrl, 'thankyoupage-share', 'Hey, ich habe ein cooles Angebot auf HID für dich: ', params)
  }


  displayTaPromotion(displayPromotion: boolean){

    //Delay for decommissioned overlay animation
    /*
    delay(2000).then(() => {
      if(displayPromotion){
        this.showTaPromotion = true
        delay(400).then(() => {
          this.displayTaLogo = true
        })
        delay(1000).then(() => {
          this.createHeading()
        })
        delay(1600).then(() => {
          this.createText()
        })
      }
    })
     */

    if(displayPromotion){
      this.showTaPromotion = true
    }
    /*
    if(displayPromotion){
      this.snackBar.openSnackBar("TA PROMO RELEVANT", "Schließen", 3000, false)
    }
    else{
      this.snackBar.openSnackBar("TA PROMO NOT RELEVANT", "Schließen", 3000, false)
    }
    */

  }

  closeTaPromotion(){
    this.promoFadingOut = true
    //Delay for fade out animation
    delay(400).then(() => {
      this.taPromotionRelevant = false
      this.showTaPromotion = false
      this.displayTaLogo = false
      this.displayTaPromoText = false
    })
  }

  intervalRunning : boolean = false
  text: string = ''
  _avatarText: string = 'Unsere Karriere-Plattform Talentagent hilft dir dabei den perfekten Job ohne langes suchen zu finden!'

  clearTextInterval(intervalId?) {
    if (intervalId) clearInterval(intervalId)
    this.intervalRunning = false
  }

  createText() {
    if (this.intervalRunning && this._avatarText.length[0]) return
    this.intervalRunning = true
    this.text = ""

    setTimeout(() => {
      let intervalId = setInterval(() => {
        if (this._avatarText[0]) {
          this.text = this.text + this._avatarText[0]
          this._avatarText = this._avatarText.substring(1)
        } else {
          this.clearTextInterval(intervalId)
        }
      }, 10)
    }, 100)
  }

  intervalRunningHeading : boolean = false
  promoHeader: string = ''
  _avatarHeader: string = 'Bereit für den Berufseinstieg?'

  createHeading() {
    if (this.intervalRunningHeading && this._avatarHeader.length[0]) return
    this.intervalRunningHeading = true
    this.promoHeader = ""

    setTimeout(() => {
      let intervalId = setInterval(() => {
        if (this._avatarHeader[0]) {
          this.promoHeader = this.promoHeader + this._avatarHeader[0]
          this._avatarHeader = this._avatarHeader.substring(1)
        } else {
          this.clearTextInterval(intervalId)
        }
      }, 10)
    }, 100)
  }

  downloadICSFile() {
      let seminar = this.seminarCheckoutService.getSeminar()

      this.icsService.downloadIcs(
        seminar.date,
        seminar.endTime,
        this.originalOfferPage.displayTitleForNav,
        "Du hast dich für diesen Workshop auf www.hochschulinitiative-deutschland.de angemeldet. Für Terminänderungen oder andere Fragen, wende dich gerne an unseren Support :)",
        "",
        "Der Termin findet Online statt, Zugangsdaten kommen rechtzeitig per Mail"
      )
  }

  navToTalentAgent() {
    window.open(environment.talentAgentPublicFrontendUrl + '?utm_source=hidpage&utm_medium=thankyoupage&utm_campaign=thankyoupage-banner&utm_content=formats-id-' + this.cronosFormatId)
  }
}
