<a fxLayout="column" fxLayoutAlign="start start" class="panel" [href]="generateHrefValue()" *ngIf="post">
  <div class="image-wrap">
    <!-- Cleaner solution for alt tags -->
    <!--
    <div #image class="image" [ngStyle]="{background: 'url(' + imageSrc + ')'}"></div>
    -->
    <img #image class="image" [src]="imageSrc" alt="{{altText}}"/>
    <div class="fake-image fake" *ngIf="loading"></div>
  </div>
  <div class="title-wrapper" fxLayout="column" *ngIf="!loading">
    <div class="titles isu-padding">
      <div class="main-title">{{post.title}}</div>
      <hr>
      <div class="sub-title">{{post.subtitle}}</div>
    </div>
  </div>

  <div fxLayout="column" class="loading-overlay" *ngIf="loading">
    <div class="titles isu-padding">
      <div class="main-fake fake"></div>
      <div class="sub-fake fake"></div>
    </div>
  </div>
</a>
