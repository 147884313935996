import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-user-offline-dialog',
  templateUrl: './user-offline-dialog.component.html',
  styleUrls: ['./user-offline-dialog.component.scss']
})
export class UserOfflineDialogComponent implements OnInit {



  constructor(private dialogRef: MatDialogRef<UserOfflineDialogComponent>) { }

  ngOnInit() {

    var counter = 5000;
    var myFunction = () => {
      counter *= 2;
      if(counter > 60000) { counter = 60000 };
      if (!this.retry()) {
        setTimeout(myFunction, counter);
      }
    }
    setTimeout(myFunction, counter);
  }

  retry() :boolean{
    if(!this.isOnline()) {
      return false
    } else {
      location.reload()
      return true
    }
  }


  isOnline() {
    return navigator.onLine
  }

  onClose() {
    this.dialogRef.close(false)
  }
}
