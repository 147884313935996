import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OnlineLectureComponent} from './online-lecture/online-lecture.component';
import {RouterModule, Routes} from "@angular/router";
import {OnlineLectureRedirectComponent} from "./online-lecture-redirect/online-lecture-redirect.component";
import {NoTokenRedirectDialogComponent} from "./no-token-redirect-dialog/no-token-redirect-dialog.component";
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {OfferPagePanelModule} from "../offer-page-panel/offer-page-panel.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {GlobalModule} from "../global/global.module";
import {OnlineLectureSummaryComponent} from './online-lecture-summary/online-lecture-summary.component';
import {EnterOnlineLectureComponent} from './enter-online-lecture/enter-online-lecture.component';
import {MatMenuModule} from "@angular/material/menu";
import {OnlineLectureAppointmentComponent} from './online-lecture-appointment/online-lecture-appointment.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MobileDatepickerDialogComponent} from './online-lecture-appointment/mobile-datepicker-dialog/mobile-datepicker-dialog.component';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {CreateAppointmentRequestDialogComponent} from './online-lecture/create-appointment-request-dialog/create-appointment-request-dialog.component';
import {OnlineLectureAPIResourceV2} from "../generated/cronos/resources";

const appRoutes: Routes = [
  {path: 'onlinelecture/:cronosFormatId/lesson/:lessonId', component: OnlineLectureComponent},
  {path: 'onlinelecture/:cronosFormatId/summary', component: OnlineLectureSummaryComponent},
  {path: 'onlinelecture/:cronosFormatId/appointment', component: OnlineLectureAppointmentComponent},
  {path: 'onlinelecture/:cronosFormatId/validate/:token', component: OnlineLectureRedirectComponent},
  {path: 'enter/onlineLecture/:cronosFormatId/validate/:token', component: EnterOnlineLectureComponent},
];

@NgModule({
  declarations: [
    OnlineLectureComponent,
    OnlineLectureRedirectComponent,
    NoTokenRedirectDialogComponent,
    OnlineLectureSummaryComponent,
    EnterOnlineLectureComponent,
    OnlineLectureAppointmentComponent,
    MobileDatepickerDialogComponent,
    CreateAppointmentRequestDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    HidInteractionsModule,
    FlexModule,
    OfferPagePanelModule,
    MatSnackBarModule,
    GlobalModule,
    FlexLayoutModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    FormsModule,
  ],
  entryComponents: [
    NoTokenRedirectDialogComponent,
    MobileDatepickerDialogComponent,
    CreateAppointmentRequestDialogComponent,
  ],
  providers: [
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: MatDialogRef, useValue: {}},
    OnlineLectureAPIResourceV2
  ]
})
export class OnlineLectureModule {
}
