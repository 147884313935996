<div class="schlittschuh-wrapper">
  <div class="card-wrapper">
    <div [ngClass]="{ 'extra-info-open': showSecondScreen }" class="card">
      <div class="header">
        <h1 class="card-title">Lohnt sich das Seminar?</h1>
        <h3 class="card-subtitle">Trifft das auf dich zu? Du hast..</h3>
      </div>
      <div class="content">
        <div class="schlittschuh-container">

          <div class="schlittschuh-item">
            <div class="schlittschuh-label">einen Laptop oder Tablet gekauft?</div>
            <div class="schlittschuh-toggle">
              <app-schlittschuh-slider (valueChanged)="onValueChanged($event, 1)"
                                       [isActivated]="showSecondScreen"></app-schlittschuh-slider>
            </div>
          </div>

          <div class="schlittschuh-item">
            <div class="schlittschuh-label">monatliche Fahrtkosten?</div>
            <div class="schlittschuh-toggle">
              <app-schlittschuh-slider (valueChanged)="onValueChanged($event, 2)"
                                       [isActivated]="showSecondScreen"></app-schlittschuh-slider>
            </div>
          </div>

          <div class="schlittschuh-item">
            <div class="schlittschuh-label">ein Auslandssemester gemacht?</div>
            <div class="schlittschuh-toggle">
              <app-schlittschuh-slider (valueChanged)="onValueChanged($event, 3)"
                                       [isActivated]="showSecondScreen"></app-schlittschuh-slider>
            </div>
          </div>

          <div class="schlittschuh-item">
            <div class="schlittschuh-label">Geld für Fachliteratur und Skripte ausgegeben?</div>
            <div class="schlittschuh-toggle">
              <app-schlittschuh-slider (valueChanged)="onValueChanged($event, 4)"
                                       [isActivated]="showSecondScreen"></app-schlittschuh-slider>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showSecondScreen" class="mobile-value-container isu-display-hide isu-display-sm-show">
        <i class="mdi mdi-check-circle"></i>

        <h2>
          <div> Es lohnt sich für dich! <img src='https://hidcms.syntacton.de/images/containers/27989'/></div>
        </h2>
        <a
          class="cta-button ita_initiate-checkout ita_format_2214 ita_format-split_29176 page-content-cta desktop-show">
          <div class="mobile-button">
            Online-Termine ansehen
          </div>
        </a>
      </div>

    </div>
  </div>

  <div class="info-card-container isu-display-sm-hide">
    <div [class.checked]="showSecondScreen" class="info-card">
      <div *ngIf="!showSecondScreen">
        <h4 class="card-header">Finanzamt</h4>
        <p class="card-tagline">Steuerrückerstattung 2021</p>
        <p class="thick-price">+ 1.072,00€</p>
      </div>
      <div *ngIf="showSecondScreen" class="second-screen">
        <i class="mdi mdi-check-circle"></i>
        <h2>Es lohnt sich für dich! <img src='https://hidcms.syntacton.de/images/containers/27989'/></h2>
      </div>

      <img alt="Bus, Auto, Bücher etc." class="funky-card-picture"
           src="https://hidcms.syntacton.de/images/containers/28756">
    </div>

    <a class="cta-button ita_initiate-checkout ita_format_2214 ita_format-split_29176 page-content-cta desktop-show"
       style="text-align: center; font-weight: bold;margin-top:40px; border: 4px solid #ff6400!important; border-radius: 10px!important;color:#fff; background-color: #ff6400; padding: 8px 25px 8px 25px; font-size: 16px;">Online-Termine
      ansehen</a>
  </div>

</div>
