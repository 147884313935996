import { Component, OnInit } from '@angular/core';
import {
  ContactSupportModalComponent
} from "../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-invalid-token-error-page',
  templateUrl: './invalid-token-error-page.component.html',
  styleUrls: ['./invalid-token-error-page.component.scss']
})
export class InvalidTokenErrorPageComponent implements OnInit {

  constructor(private modal: NgbModal) { }

  ngOnInit(): void {
  }

  openSupportModal() {
    this.modal.open(ContactSupportModalComponent);
  }
}
