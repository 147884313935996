<div class="actionlink-page-wrapper">
<div *ngIf="checkInDataLoaded" class="actionlink-main-box ita-format-{{registrationInfo.promotionData.formatId}}"
     fxLayout="column" fxLayoutAlign="start center">

  <div id="check-in-headline" fxLayout="column" fxLayoutAlign="center center">
    <i class="fas fa-map-marker-alt icon"></i>
    <div class="text">Seminar Check-In</div>
  </div>
  <div class="explanation" fxLayout="row" fxLayoutAlign="center center">
    <span *ngIf="registrationInfo.promotionData.promotionType === 'OfflineSeminarDate'">Check dich ein, damit wir für jeden Teilnehmer einen Platz vorbereiten können.</span>
    <span *ngIf="registrationInfo.promotionData.promotionType.indexOf('WebinarDate') > -1">Check dich ein, damit der Referent weiß, dass du kommst.</span>
  </div>

  <app-promotion-info-box [promotionData]="registrationInfo.promotionData"></app-promotion-info-box>

  <div id="check-in-button-box" fxLayout="column" fxLayoutAlign="center center">
    <button (click)="checkIn()" [ngClass]="{'display-none': (checkInFinished || checkInLoading)}"
            class="hid-button primary check-in-button ita-seminar-check-in-btn">
      Jetzt Einchecken
    </button>
    <div id="animation-object-loading"
         [ngClass]="{'checkInAnimation': checkInLoading, 'display-none':  !checkInLoading}"></div>

    <div id="animation-object-finished" [ngClass]="{'display-none':  !checkInFinished}"
         fxLayout="column" fxLayoutAlign="start center">
      <i class="fas fa-user-check" [ngClass]="{'finished-icon': checkInFinished}"></i>
      <!-- WEBINAR -->
      <div *ngIf="registrationInfo.promotionData.promotionType.indexOf('WebinarDate') > -1" fxLayout="column" fxLayoutAlign="start center">
      <div  class="text" style="text-align: center" [ngClass]="{'display-none':  !checkInFinished, 'finished-text': checkInFinished}">Super, du bist eingecheckt! <br>30 Min vor dem Seminar kannst du deinen Beitritt hier starten.
      </div>
      <button class="hid-button primary" [routerLink]="'/webinar/' +registrationInfo.promotionData.formatId + '/validate/'+ this.token" style="margin-top: 30px">Zum Beitritt</button>
      </div>

      <!-- Offline -->
      <div *ngIf="registrationInfo.promotionData.promotionType == 'OfflineSeminarDate'">
        <div  class="text" style="text-align: center" [ngClass]="{'display-none':  !checkInFinished, 'finished-text': checkInFinished}">Super, du bist eingecheckt! <br> Dein Platz wird für dich vorbereitet.
        </div>
      </div>
    </div>

    <button class="hid-button inverse warn ita-cannot-participate-link" style="margin-top: 20px"*ngIf="!(checkInFinished || checkInLoading)" (click)="routeToCancellation()">Ich kann nicht
      teilnehmen.
    </button>
  </div>

</div>


<div *ngIf="checkInDataLoaded && registrationInfo?.promotionData.date < now" fxLayout="column" fxLayoutAlign="start center"
     class="token-not-valid">
  <div id="logo">
    😕
    <!--img src="../../../../../assets/img/wrongSmiley.jpg"-->
  </div>
  <div class="headline">Dein Link ist leider abgelaufen!</div>
  <div class="explanation">Leider ist dein Check-In Link abgelaufen, da das Seminar bereits beendet ist. Wenn du Fragen hast,
    kontaktiere uns gerne.
  </div>

  <div class="contact-box" fxLayout="column" fxLayoutAlign="start center">
    <div>
      <i aria-hidden="true" class="fab fa-whatsapp fam"></i>
      <a href="whatsapp://send?text=Hey Nina, ich hab eine Frage:&amp;phone=+4915735980244">+49 157 359 80 244</a>
    </div>
    <button class="hid-button secondary" (click)="openSupportModal()">Support kontaktieren</button>
  </div>

  <div class="other">ODER</div>

  <button class="hid-button primary" (click)="goHome()">zu den Angeboten</button>
</div>

</div>
