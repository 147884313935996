import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {interval, Subject} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountResource} from '../generated/cronos/resources';
import {take} from 'rxjs/operators';
import {AccountInfoData, AccountRegisterData} from '../generated/cronos/data';
import {EmailConfirmDialogCloseData, EmailConfirmDialogOpenData} from './confirm-email-dialog/email-confirm.data';
import {ConfirmEmailDialogComponent} from './confirm-email-dialog/confirm-email-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {HidSnackbarService} from '../hid-interactions/hid-snackbar/hid-snackbar.service';

const ACCESS_TOKEN = 'access_token';
const ACCOUNT_INFO = 'account_info';

export type LoginState =
  'LoginSuccessful'
  | 'LoginFailedCredentialsIncorrect'
  | 'LoginExpired'
  | 'Logout'
  | 'TokenUpdated'
  | 'RegistrationFailed';

@Injectable()
export class AuthService {

  authenticatedSubject = new Subject<LoginState>();

  private tokenRefresher = interval(600 * 1000);

  constructor(
    @Inject('env') private env,
    private http: HttpClient,
    private accountResource: AccountResource,
    private route: ActivatedRoute,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private dialogService: MatDialog,
    private snackBar: HidSnackbarService,
  ) {
    this.refreshTokenIfNeeded();
    this.tokenRefresher.subscribe(() => {
      this.refreshTokenIfNeeded();
    });
  }

  private refreshTokenIfNeeded() {
    if (!this.isAuthenticated()) {
      return;
    }

    const token = localStorage.getItem(ACCESS_TOKEN);
    const now = new Date();
    const remainingTokenLifetimeSec = (this.jwtHelper.getTokenExpirationDate(token).getTime() - now.getTime()) / 1000;

    if (remainingTokenLifetimeSec < 60 * 60 * 24) {
      this.accountResource.refreshToken().then((result) => {
          this.setToken(result.value);
        }
      );
    }
  }

  onLoginStateChange(callback: (result) => any) {
    this.authenticatedSubject.subscribe(
      (result) => callback(result)
    );
  }

  onLoginSuccess(callback: () => any) {
    this.authenticatedSubject.subscribe(
      (result) => {
        if (result == 'TokenUpdated' || result == 'LoginSuccessful') {
          callback();
        }
      }
    );
  }

  getToken(): string {

    if (this.isExpired()) {
      this.clear();
    }

    return localStorage.getItem(ACCESS_TOKEN);
  }

  accountInfo: AccountInfoData;

  reloadAccountInfo() {
    return this.accountResource.getInfo().then((accountInfo) => {
      this.accountInfo = accountInfo;
      localStorage.setItem(ACCOUNT_INFO, JSON.stringify(accountInfo));
    });
  }

  async ensureEmailConfirmed(): Promise<boolean> {
    const confirmed = await this.accountResource.isEmailConfirmed();
    if (confirmed.value) {
      return true;
    }

    const openData: EmailConfirmDialogOpenData = {email: this.accountInfo.emailAddress};
    const ref = this.dialogService.open(ConfirmEmailDialogComponent, {
      maxWidth: '768px', data: openData
    });
    const closeData: EmailConfirmDialogCloseData = await ref.afterClosed().toPromise();

    if (closeData.resendEmail) {
      this.accountResource.resendConfirmMail().then((res) => {
        if (res.value) {
          this.snackBar.openSnackBar('Email erfolgreich versendet!', null, 2000, false);
        }
      });
    }

    return false;
  }


  logout() {
    if (!this.isAuthenticated()) {
      return;
    }
    this.clear();
    this.authenticatedSubject.next('Logout');
    this.router.navigateByUrl('/home');
  }

  setToken(newToken) {
    let prevToken = localStorage.getItem(ACCESS_TOKEN);
    localStorage.setItem(ACCESS_TOKEN, newToken);
    this.reloadAccountInfo().then(() => {
        if (prevToken == null) {
          this.authenticatedSubject.next('LoginSuccessful');
        } else {
          this.authenticatedSubject.next('TokenUpdated');
        }
      }
    );
    this.authenticatedSubject.next('LoginSuccessful')
    return this.authenticatedSubject.asObservable();
  }

  protected loadAccountInfoFromStorage() {
    return JSON.parse(localStorage.getItem(ACCOUNT_INFO));
  }

  private clear() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ACCOUNT_INFO);
    localStorage.removeItem('ngx_accountData');
  }

  private isExpired() {
    try {
      let isExpired = this.jwtHelper.isTokenExpired(localStorage.getItem(ACCESS_TOKEN));

      if (isExpired) {
        this.clear();
        this.authenticatedSubject.next('LoginExpired');
      }
      return isExpired;
    } catch (e) {
      this.clear();
    }

    return true;
  }

  isAuthenticated(): boolean {

    const token = localStorage.getItem(ACCESS_TOKEN);

    if (token == null) {
      return false;
    }

    return !this.isExpired();
  }

  public register(data: AccountRegisterData) {
    this.accountResource.register(data).then(
      result => {
        this.setToken(result.value);
      },
      error => {
        this.authenticatedSubject.next('RegistrationFailed');
      });
    return this.authenticatedSubject.asObservable().pipe(take(1));
  }

  public login(email: string, password: string) {
    let loginData = {
      'username': email,
      'password': password,
      'grant_type': 'password'
    };
    this.http.post<any>(this.env.cronosApiUrl + '/account/login', loginData).subscribe(
      (response) => {
        if (response.access_token != null) {
          this.setToken(response.access_token);
        }
      },
      (error) => {
        this.authenticatedSubject.next('LoginFailedCredentialsIncorrect');
      }
    );

    return this.authenticatedSubject.asObservable().pipe(take(1));
  }
}
