import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-handle-dynamic-path',
  templateUrl: './handle-dynamic-path.component.html',
  styleUrls: ['./handle-dynamic-path.component.sass']
})
export class HandleDynamicPathComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    // this serves as a fallback for when google optimize doesn't resolve the '/d/' url
    this.router.navigateByUrl(this.router.url.replace('/d/', '/'))
  }

}
