<div class="offline-dialog-backdrop"  fxLayout="column" fxLayoutAlign="center center"
     fxLayoutAlign.xs="end center">
  <div class="offline-dialog-wrapper-x">
    <div fxLayout="column" fxLayoutAlign="space-between start" class="height-100" >
      <h4>Du bist offline </h4>

      <div fxLayout="column" fxLayoutAlign="space-between start">
        <div class="offline-dialog-content">
          Leider bist du gerade offline. Wir werden in regelmäßigen Abständen checken, ob du wieder online bist.
          Du kannst dies gerne auch selbst über den Button unten versuchen oder abbrechen, wenn du dich in einem Funkloch befindest.
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" class="actions" >

          <button  class="hid-button inverse secondary" (click)="onClose()">
            Abbrechen
          </button>
          <button class="hid-button primary " (click)="retry()">
            Neu laden
          </button>
        </div>

      </div>

  </div>
  </div>
</div>
