<hid-loading-spinner [loadingBool]="loading">
  <div class="wrapper" *ngIf="mammothWebinar">
    <h2 style="margin-bottom: 20px">Servicebogen-Assistent</h2>
    <div fxLayout="column" fxLayoutAlign="center start" class="seminar-info">
      <div fxLayout="row" fxLayoutAlign="start center" class="w-100">
        <div fxFlex="20" fxFlex.xs="30">Seminar:</div>
        <div>{{mammothWebinar.webinarFormatTitle}}</div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="w-100">
        <div fxFlex="20" fxFlex.xs="30">Datum:</div>
        <div>{{mammothWebinar.date | date: 'dd.MM.yyyy, HH:mm'}}</div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="w-100">
        <div fxFlex="20" fxFlex.xs="30">Standorte:
        </div>
        <div fxLayout="row">
          <div *ngIf="locations.length <= 3">
            <div *ngIf="locations.length == 1">{{locations[0]}} </div>
            <div *ngIf="locations.length == 2">{{locations[0]}}, {{locations[1]}} </div>
            <div *ngIf="locations.length == 3">{{locations[0]}}, {{locations[1]}}, {{locations[2]}} </div>
          </div>
          <div *ngIf="locations.length > 3" [matTooltip]="generateLocationToolTip(locations)">
            {{locations[0]}}, {{locations[1]}}, {{locations[2]}} + {{locations.length -3}} weitere </div>


        </div>
      </div>


    </div>

    <div>
      Wenn du auf den Button drückst, wird jedem Teilnehmer per Email ein Link zum Ausfüllen des Servicebogens
      geschickt. <br>
      <b>Du kannst diese Funktion nur einmal pro Termin benutzen!</b> <br>
      <b>Du solltest die Emails erst nach Beginn des Webinars verschicken!</b>
    </div>
    <div class="w-100" fxLayout="row" fxLayoutAlign="center center">
      <hid-checkbox style="margin: 0 auto" *ngIf="!alreadySent" [(model)]="confirmed">Verstanden</hid-checkbox>

    </div>



    <div fxLayout="column" class="form-button" fxLayoutAlign="center center" *ngIf="!successfullySent && !alreadySent">


      <hid-loading-button [loadingBool]="triggeringEmails" color="primary" style="padding-right:10px"
                          [disabledCondition]="!confirmed"
                          [raised]="true" buttonClass="hid-button primary"
                          (click)="triggerEmailSendMechanism()">Emails jetzt verschicken
      </hid-loading-button>
    </div>
    <div class="send-result" *ngIf="successfullySent || alreadySent || errorOnSend" fxLayout="column" fxLayoutAlign="start center">
      <div *ngIf="successfullySent " fxLayout="row" class="text-green">
        <i class="fa fam fa-check"></i>Mailversand abgeschlossen
      </div>
      <div *ngIf="alreadySent" class="text-red">
        <i class="fa fam fa-times"></i>Die Mails wurden bereits verschickt!
      </div>
      <div *ngIf="errorOnSend" class="text-red">
        <i class="fa fam fa-times"></i>Etwas ist beim Versenden schiefgelaufen!
      </div>
      <span class="error-hint">Hat etwas nicht funktioniert? Gib deinen Teilnehmern diesen Link als Alternative:</span>
      <div class="alt-link-field" fxLayout="row" fxLayoutAlign="space-between center">
        <span>{{alternativeLink}}</span>
        <button class="hid-button clipboard-button secondary inverse" (click)="copyStringToClipboard(alternativeLink)">
          Kopieren
        </button>
      </div>
    </div>
  </div>


</hid-loading-spinner>
