<div class="modal-fix-wrapper">
  <div class="modal-header">
    <h5 class="modal-title" fxLayout="row" fxLayoutAlign="start center">
      Support kontaktieren
    </h5>
  </div>

  <div class="modal-body">

    <div fxLayout="column" *ngIf="contactData">
      <a href="whatsapp://send?text=Hey Lisa, ich hab ne Frage:&amp;phone=+4915735980244" fxLayout="row"
         *ngIf="mediaService.isMobile()"
         fxLayoutAlign="start center" id="wa">
        <i aria-hidden="true" class="fab fa-whatsapp fam" style="font-size: 60px;margin-right: 50px"></i>
        <div fxLayout="column">
          <span style="text-transform: uppercase">Einfach per Whatsapp</span>
          <span>0351 799 033 84</span>
        </div>
      </a>

      <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="!mediaService.isMobile()">

        <div fxLayout="column" fxLayoutAlign="center center">
          <i class="fab fa-whatsapp text-green" style="font-size: 36px"></i>
          <div class="text-green"> Einfach per WhatsApp</div>
          <div><a class="hid-link" target="_blank" href="https://wa.me/4915735980244">+49 157 359 80 244</a></div>
        </div>
        <div fxLayout="column">
          <img width="200" height="200" src="../../../assets/img/qrcode.png"/>
          <span style="color: #777;
    font-size: 11px; text-align: center"> QR-Code scannen & direkt aufs Handy </span>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" class="w-100"
           style="height: 2px; background: #777; margin: 30px auto;">
        <span style="background: #fff; padding: 0 8px">Oder direkt hier:</span>
      </div>


      <hid-dropdown [options]="contactOptions" [(model)]="contactData.supportMode"></hid-dropdown>

      <hid-input-field name="firstName" [control]="validationForm.controls['firstName']" label="Dein Vorname" maxLength="30"></hid-input-field>
      <hid-input-field name="email" type="email" [control]="validationForm.controls['email']" label="Deine Email" maxLength="60"></hid-input-field>
<!--      <hid-input-field name="seminarFormat"  [(model)]="contactData.email" label="Name des Seminares"></hid-input-field>-->
      <hid-input-field name="seminarDate"  [(model)]="contactData.seminarDate" label="Datum des Seminars" *ngIf="contactData.supportMode ==  'Frage zu einem Seminartermin'" maxLength="30"></hid-input-field>
      <hid-input-field name="seminarLocation"  [(model)]="contactData.seminarLocation" label="Ort des Seminars" *ngIf="contactData.supportMode ==  'Frage zu einem Seminartermin'" maxLength="30"></hid-input-field>
      <hid-textarea [control]="validationForm.controls['message']" [label]="messagePlaceholder"></hid-textarea>
      <p style="font-size: 10px;">Die Kontaktaufnahme dient für Anfragen zu uns und unseren Leistungen.
        Die von Ihnen übermittelten personenbezogenen Daten verwenden wir sodann zu keinem anderen Zweck als zur Bearbeitung Ihrer Anfrage.
        Näheres dazu finden Sie in unserer <a routerLink="/datenschutz" target="_blank">Datenschutzerklärung</a>.</p>
<!--      <br>-->
      <p style="font-size: 10px;">* = Pflichtangabe</p>
    </div>
  </div>
  <div class="modal-footer" fxLayout="row" fxLayoutAlign="end center">
    <button class="hid-button" (click)="close()">Abbrechen</button>
    <button class="hid-button primary" (click)="sendMessage()" [disabled]="validationForm.invalid">Nachricht abschicken</button>
  </div>
</div>
