import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-anonymize-feedback',
  templateUrl: './anonymize-feedback.component.html',
  styleUrls: ['./anonymize-feedback.component.scss']
})
export class AnonymizeFeedbackComponent implements OnInit {

  @Input() anonymizeControl: FormControl;
  anonymizeSelected: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  activateCheckBox() {
    this.anonymizeSelected = true;
  }

}
