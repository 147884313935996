import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {PromotionApiResourceV2, SeminarProcessAPIResourceV2} from "../../../../generated/cronos/resources";
import {RegistrationInfoData} from "../../../../generated/cronos/data";

@Component({
  selector: 'app-student-confirm-present-dialog',
  templateUrl: './student-confirm-present-dialog.component.html',
  styleUrls: ['./student-confirm-present-dialog.component.scss']
})
export class StudentConfirmPresentDialogComponent implements OnInit {

  buttonClicked: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { registrationInfo: RegistrationInfoData, token: string },
    private promotionApiResourceV2: SeminarProcessAPIResourceV2,
    public dialogRef: MatDialogRef<StudentConfirmPresentDialogComponent>,
  ) {
  }

  ngOnInit() {
  }

  confirmStudentWasPresent() {
    this.buttonClicked = true;
    this.promotionApiResourceV2.markStudentAsParticipated(this.data.token).then(() => {
      this.closeDialog(true);
    })
  }

  closeDialog(result: boolean = false) {
    this.dialogRef.close(result)
  }
}
