<div class="support-main-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="support-image"></div>
  <div class="support-info" fxLayout="column" fxLayoutAlign="center center">
    <h1>Hast du Fragen oder Probleme?</h1>
    <h2>Unser Support-Team kann dir weiterhelfen!</h2>
    <h4>Wir sind zu folgenden Zeiten für dich verfügbar:</h4>
    <h4>Mo-Do 09:00-12:00 und 14:00-16:00 Uhr</h4>
    <h4>Fr 09:00-14:00 Uhr</h4>

    <button mat-flat-button class="support-button" (click)="openSupportModal()">
      <i class="mdi mdi-phone"></i>
      Support kontaktieren</button>
  </div>
</div>
