import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {HomePageResource} from "../../generated/cms/resources";
import {HomePageConfigData, PublicOfferBundleData,} from "../../generated/cms/data";
import {Router} from "@angular/router";
import {MediaService} from "../../media.service";
import {openIcsFileFromSeminar} from "../../utils.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  bundles: PublicOfferBundleData[];
  bannerId: number;

  @ViewChild("banner", { static: true }) banner: ElementRef;
  @ViewChild("content", { static: true }) content: ElementRef;

  homePageConfig: HomePageConfigData

  bannerUrl = '';

  slideConfig = {"slidesToShow": 4, "slidesToScroll": 4};

  constructor(
    private homeResource: HomePageResource,
    private router: Router,
    @Inject("env") private env, private mediaService: MediaService) {
  }
  test(){
  return  openIcsFileFromSeminar("add", new Date())
  }
  ngOnInit() {

    this.homeResource.getHomePageConfig().then(result => {
      this.homePageConfig = result
    })

      this.homeResource.getBundlesForHome({mobileBanner: this.mediaService.isMobile()}).then(res => {
        this.bundles = res.orderedBundles;
        this.bannerId = res.bannerContainerId;
        this.setBannerUrl()
      })


  }

  scrollToContent(){
    this.content.nativeElement.scrollIntoView({alignToTop: true,behavior: 'smooth'})
  }

  navigateToLink() {
    this.router.navigateByUrl(this.homePageConfig.headerLink)
  }

  setBannerUrl() {
    const height = this.banner.nativeElement.clientHeight;
    const width = this.banner.nativeElement.clientWidth;

    this.bannerUrl = this.env.cmsApiUrl + '/images/containers/' + this.bannerId + '?height=' + height + '&width=' + width
  }


}
