import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {SupportMessageData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.SupportController
 */

@Injectable()
@ResourceParams({})
export class SupportResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/support/message',
        endpoint: 'cms'
    })
    private _sendSupportMessage: IResourceMethodObservableStrict<SupportMessageData, {}, {}, void>;
    sendSupportMessage(requestBody: SupportMessageData): Promise<void> {
        return this._sendSupportMessage(requestBody, null, null).toPromise()
    }

}