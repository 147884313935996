<div class="dialog-main-wrapper" fxLayout="column" fxLayoutAlign="start start">

  <h3>Anmeldung erforderlich</h3>

  <div id="seminar-data" fxLayout="column" fxLayoutAlign="start start">
    Für dieses Angebot musst du dich anmelden. Schau es dir direkt an!
  </div>

  <div class="action-box" fxLayout="row" fxLayoutAlign="space-between start">
    <button class="hid-button secondary inverse" (click)="close()">Abbrechen</button>
    <button class="hid-button primary" (click)="goToOfferPage()">Ansehen
    </button>
  </div>

</div>
