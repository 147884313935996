import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stringSearch'
})
export class StringSearchPipe implements PipeTransform {


  public transform(value, keys: string, term: string, matchBeginningOnly?: boolean) {

    let startWith: string = "";
    if (matchBeginningOnly) {
      startWith = "^";
    }
    if (!term) return value;
    return (value || []).filter(item => keys.split(',').some(
      key => item.hasOwnProperty(key) && new RegExp(startWith + term, 'gi').test(item[key])));

  }

}
