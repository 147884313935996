<div class="content-wrapper">
  <h1 style="margin-top:40px">Datenschutz</h1>
  <div [innerHTML]="privacyContent | safeHtml"></div>


  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 4px; font-size: 14px; border: 1px solid #777;margin-top:80px" fxLayout.xs="column">
    <div>Beim ersten Besuch unserer Seite fragten wir dich nach deiner Einwilligung zur Nutzung von Cookies und anderen Trackingtools gemäß der obenstehenden Erklärung.</div>
  <button id="accept_consent" class="hid-button warn revoke-button" (click)="openGDPRConsent()">
    Einwilligungen anzeigen/verändern
  </button>
  </div>
</div>
