<div class="loading-spinner-content-wrapper" [ngClass]="{'loading': loadingBool,'transparent': transparency}">
  <div class="loading-spinner-overlay" *ngIf="loadingBool" fxLayout="column" fxLayoutAlign="start center">
    <!--<div class="loading-spinner">Loading</div>-->
    <div class="loading-spinner-animation-wrapper" fxLayout="column" fxLayoutAlign="center center">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div id="entertainment-message" [ngClass]="{'show': showentertainmenttext}">
      {{entertainmenttext}}
    </div>
  </div>
  <ng-content></ng-content>
</div>
