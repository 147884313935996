import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'hid-star-rating',
  templateUrl: './hid-star-rating.component.html',
  styleUrls: ['./hid-star-rating.component.scss']
})
export class HidStarRatingComponent implements OnInit {

  @Input() size: string = '25px';
  @Input() model: number = 0;

  @Output() valueChange: EventEmitter<number> = new EventEmitter()

  constructor() {
  }

  ngOnInit() {
  }

  setModel(newValue: number) {
    this.model = 0;
    setTimeout(() => {
      this.model = newValue;
    this.valueChange.emit(this.model)
    }, 0)

  }

}
