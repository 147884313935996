import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {IsolatedPageData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicStaticPageController
 */

@Injectable()
@ResourceParams({})
export class PublicStaticPageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/static/{identifier}',
        endpoint: 'cms'
    })
    private _getStaticPage: IResourceMethodObservableStrict<void, {}, {identifier: string}, IsolatedPageData>;
    getStaticPage(identifier: string): Promise<IsolatedPageData> {
        return this._getStaticPage(null, null, {identifier: identifier}).toPromise()
    }

}