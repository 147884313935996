import {Injectable} from '@angular/core';
import {buildSessionTrackingData, LocalStorageService} from '../local-storage.service';
import {
  EnterPromotionContactData,
  EnterPromotionData,
  PublicContactData,
  PublicLocationData
} from '../generated/cronos/data';
import {PublicFormatPageData} from '../generated/cms/data';
import {environment} from '../../environments/environment';
import {GDPRConsentService} from '../gdprconsent/gdprconsent.service';
import {Router} from '@angular/router';
import {AnalyticsService} from '../analytics/analytics.service';
import {PublicOfferPageResource} from '../generated/cms/resources';
import {ContactApiResourceV2, PublicStructureResourceV2} from '../generated/cronos/resources';

@Injectable({
  providedIn: 'root'
})
export class GlobalCheckoutService {

  private storedUserData: EnterPromotionContactData;

  //Not part of EnterPromotionContactData, so storing these two seperately
  locationName: string;

  referralCode: string;

  checkoutSplitIdentifier: string | null = null;

  //This is used to store the contactDataAccessToken that was used to populate the contact data. Its avaible if the user was forwarded from a rebook maillink
  contactDataAccessToken = null;

  private currentCheckoutPage: PublicFormatPageData;

  currentStep: number
  totalSteps: number = 3


  //as long as this is enabled, steps are allowed to instantly push foreward if all necessary data is already available.
  //It gets disabled once the initial forewarding ends
  forewardingEnabled = true;

  //TODO contains global checkout functions, especially storing user data that is independent from offer
  constructor(private localStorageService: LocalStorageService,
              private consentService: GDPRConsentService,
              private router: Router,
              private analyticsService: AnalyticsService,
              private publicOfferPageResource: PublicOfferPageResource,
              private contactApiResourceV2: ContactApiResourceV2,
              private cronosStructureApi: PublicStructureResourceV2) {
    this.storedUserData = localStorageService.getstoredUserData()
    this.locationName = localStorageService.getlocationName()
    this.currentCheckoutPage = localStorageService.getcurrentCheckoutPage()

    this.referralCode = this.localStorageService.getReferralCode();

    if(!this.storedUserData){
      this.storedUserData = <EnterPromotionContactData>{}
    }
  }

  //leaves provided null-properties untouched and only replaces presetn values
  fillUserData(newData: EnterPromotionContactData){
    let existingUser = this.getCurrentUser()
    for(let key in newData){
      if(newData[key]!=null) {
        existingUser[key] = newData[key]
      }
    }
    this.updateUserData(existingUser)
  }

  updateUserData(user: EnterPromotionContactData){
    this.storedUserData = user;
    this.localStorageService.setstoredUserData(user)
  }


  getOrCreateUserData():EnterPromotionContactData{
    let userDataClone = JSON.parse(JSON.stringify(this.localStorageService.getstoredUserData()))
    if(!userDataClone){
      userDataClone = {}
    }
    return userDataClone
  }

  updateLocationOnUser(location: PublicLocationData) {
    this.locationName = location.name
    let user = this.getOrCreateUserData()

    user.locationId = location.id
    this.updateUserData(user)
    this.localStorageService.setlocationName(location.name)
  }

  updateZipCodeOnUser(zipCode: string){
    let user = this.getOrCreateUserData()
    user.zipCode = zipCode
    this.updateUserData(user)
    this.localStorageService.setstoredUserData(user)
  }

  updateCheckoutUserPublicContactData(data: PublicContactData){
    let enterPromotionContactData : EnterPromotionContactData = {
      age: data.age,
      birthDate: data.birthDate,
      email: data.email,
      firstName: data.firstName,
      gender: data.gender,
      lastName: data.lastName,
      locationId: data.location.id,
      phoneNumber: data.phoneNumber,
      privacyConsentGiven: data.privacyConsentGiven,
      semester: data.semester,
      studyAreaId: data.studyAreaId,
      studyEndYear: data.studyEndYear,
      studyField: data.studyField,
      subject: data.subject,
      talentagentContactId: data.talentagentContactId,
      universityName: data.universityName,
      zipCode: data.zipCode
    }
    this.localStorageService.updateCheckoutUserData(enterPromotionContactData)
  }

  clearLocation(){
   this.updateLocationOnUser(<PublicLocationData>{id: null, name:  null})
  }

  setLocationIdAndRequestInfo(locationId: number) {
    this.cronosStructureApi.getLocationById({value: locationId}).then(res => {
      this.updateLocationOnUser(res);
    });
  }

  setCurrentPage(page: PublicFormatPageData) {
    this.currentCheckoutPage = page
    this.localStorageService.setcurrentCheckoutPage(page)
  }

  getCurrentPage() {
    return this.currentCheckoutPage
  }

  getCurrentUser(){
    return this.storedUserData
  }

  returnToOfferPage(){
    this.router.navigate(["angebote/" + this.currentCheckoutPage.routingUrl])
  }

  setReferralCode(referralCode: string) {
    this.referralCode = referralCode;
    this.localStorageService.setReferralCode(referralCode)
  }



  buildEnterPromotionData():EnterPromotionData{
    let data:EnterPromotionData = {
      contact: this.storedUserData,
      sourceToken: environment.cronosSourceToken,
      cmsPageSplitId: this.currentCheckoutPage.selectedSplitId,
      googleOcConsent: this.consentService.consent.consent_google_oc,
      facebookOcConsent: this.consentService.consent.consent_facebook_oc,
      referralToken: this.referralCode,
      additionalParams: null, //deprecated feature,
      browserLanguage: navigator.language,
      checkoutSplitIdentifier: this.checkoutSplitIdentifier,
      sessionTrackingData: this.localStorageService.sessionTracking.map(e => buildSessionTrackingData(e))
    }

    return data
  }

  reportTrackingEvent(eventName: string, additionalParams: { [key: string]: string } = null){

    if(additionalParams == null){
      additionalParams = {}
    }

    //This would also add location info that pre-existed in the localstorage. if that makes any problems, add the location info explicitly in the steps where they are desired
    if(this.storedUserData.locationId){
      additionalParams["locationId"] = this.storedUserData.locationId.toString()
      additionalParams["locationName"] = this.locationName
    }

    if (this.contactDataAccessToken != null) {
      additionalParams["contactDataAccessToken"] = this.contactDataAccessToken
    }

    if (additionalParams["hidSplit"]) {
      this.checkoutSplitIdentifier = additionalParams["hidSplit"]
    }

    if (this.currentCheckoutPage) {
      this.analyticsService.reportCheckoutTrackingEvent(eventName, this.currentCheckoutPage, additionalParams);
    }

    this.currentStep = this.getCurrentStepForSuccessBar(eventName)

  }

  populateContactFromContactDataAccessToken(token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.contactDataAccessToken = token;

      this.contactApiResourceV2.getContactDataByAccessToken(token)
          .then(data => {

            let enterPromotionContactData: EnterPromotionContactData = {
              age: data.age,
              birthDate: data.birthDate,
              email: data.email,
              firstName: data.firstName,
              gender: data.gender,
              lastName: data.lastName,
              locationId: data.location.id,
              zipCode: data.zipCode,
              phoneNumber: data.phoneNumber,
              semester: data.semester,
              studyAreaId: data.studyAreaId,
              studyField: data.studyField,
              subject: data.subject,
              studyEndYear: data.studyEndYear,
              universityName: data.universityName,
              privacyConsentGiven: data.privacyConsentGiven,
              talentagentContactId: data.talentagentContactId
            }

            this.updateUserData(enterPromotionContactData);
            this.updateLocationOnUser(data.location);

            resolve();
          })
          .catch(err => {
            reject(err);
          });
    });
  }

  populateWithFormatId(formatId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.publicOfferPageResource.getFormatOfferPageByCronosId(formatId)
          .then(page => {
            this.setCurrentPage(page);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
    });
  }

  getCurrentStepForSuccessBar(step: string): number {
    switch (step) {
      case 'LocationReached':
        return 1;
      case 'DatesReached':
        return 2;
      case 'ContactDataReached':
        return 3;
      default:
        return this.currentStep;
    }
  }

}
