import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-steuer-schlittschuh',
  templateUrl: './steuer-schlittschuh.component.html',
  styleUrls: ['./steuer-schlittschuh.component.scss']
})
export class SteuerSchlittschuhComponent implements OnInit {

  toggle1 = false;
  toggle2 = false;
  toggle3 = false;
  toggle4 = false;
  showSecondScreen = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  checkToggles(): void {
    this.showSecondScreen = this.toggle1 || this.toggle2 || this.toggle3 || this.toggle4;
  }

  onValueChanged(value: boolean, toggleId: number) {
    switch (toggleId) {
      case 1:
        this.toggle1 = value;
        break;
      case 2:
        this.toggle2 = value;
        break;
      case 3:
        this.toggle3 = value;
        break;
      case 4:
        this.toggle4 = value;
        break;
    }
    this.checkToggles();
  }
}
