import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  CreateRatingBasedFeedbackData,
  CreateReasonBasedPerfectMatchFeedbackData, EventMissedReason,
  RegistrationInfoData
} from "../../../../generated/cronos/data";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PublicOfferPageResource} from "../../../../generated/cms/resources";
import {PromotionApiResourceV2, SeminarProcessAPIResourceV2} from "../../../../generated/cronos/resources";
import {UtilsService} from "../../../../utils.service";
import {ContactSupportModalComponent} from "../../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {environment} from "../../../../../environments/environment";
import {RegistrationInfoService} from "../../registration-info.service";
import {StudentConfirmPresentDialogComponent} from "../student-confirm-present-dialog/student-confirm-present-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {RadioOption} from "../../../../hid-interactions/hid-radio-group/hid-radio-group.component";

@Component({
  selector: 'app-non-seminar-student-feedback',
  templateUrl: './non-seminar-student-feedback.component.html',
  styleUrls: ['./non-seminar-student-feedback.component.scss']
})
export class NonSeminarStudentFeedbackComponent implements OnInit {

  feedbackSent: boolean = false;
  errorMessage: boolean = false;

  recommendedUpsellPageIds: number[] = [];

  perfectMatchAndNotCheckedIn: boolean = false;

  feedbackGroup: FormGroup;
  token: string = '';
  registrationInfo: RegistrationInfoData = <RegistrationInfoData>{};
  studentRatingFeedbackData: CreateRatingBasedFeedbackData = <CreateRatingBasedFeedbackData>{};
  studentReasonFeedbackData: CreateReasonBasedPerfectMatchFeedbackData = <CreateReasonBasedPerfectMatchFeedbackData>{};
  registrationInfoLoading: boolean = true;

  missedEventReasons: EventMissedReason[] = ['missedEvent', 'appointmentConflict', 'notInterested', 'unableToLocateLocation', 'miscellaneous'];

  radioOptionsMissedEvent: RadioOption[] = [
    {value: 'appointmentConflict', display: 'Ich war verhindert'},
    {value: 'unableToLocateLocation', display: 'Ich war da, habe aber den Raum nicht gefunden'},
    {value: 'notInterested', display: 'Ich hatte kein Interesse mehr'},
    {value: 'missedEvent', display: 'Termin vergessen'},
    {value: 'miscellaneous', display: 'Sonstiges'}];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private offerPageResource: PublicOfferPageResource,
    private promotionApiResource: PromotionApiResourceV2,
    private utilsService: UtilsService,
    private registrationInfoService: RegistrationInfoService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getTokenInformation();
  }
  initializeForm() {
    if(!this.perfectMatchAndNotCheckedIn){
      this.feedbackGroup = this.fb.group({
        rating: [null, Validators.required],
        reason: [null],
        comment: [null],
        anonymized: [false],
        enterGoogleReview: [true]
      })
    }
    else{
      this.feedbackGroup = this.fb.group({
        rating: [null],
        reason: [null, Validators.required],
        comment: [null],
        anonymized: [false],
        enterGoogleReview: [true]
      })
    }
  }
  sendFeedback() {

    if (this.feedbackGroup.valid) {
      this.errorMessage = false;
      this.feedbackSent = true;

      if(!this.perfectMatchAndNotCheckedIn && this.feedbackGroup.controls.rating.value < 6 && this.feedbackGroup.controls.rating.value > 0){
        this.studentRatingFeedbackData.comment = this.feedbackGroup.value.comment;
        this.studentRatingFeedbackData.rating = this.feedbackGroup.value.rating;
        this.studentRatingFeedbackData.anonymized = this.feedbackGroup.value.anonymized;
        this.promotionApiResource.enterRatingBasedFeedback(this.studentRatingFeedbackData, this.token).then(() => {
          let googleFeedback = false;
          if(this.feedbackGroup.controls.rating.value >= 4 && this.feedbackGroup.value.enterGoogleReview == true) {
            googleFeedback = true
          }
          this.router.navigateByUrl('actions/feedback/thank-you?code=' + this.token
            + '&fbp=' + JSON.stringify(googleFeedback));
        })
      }
      else{
        this.studentReasonFeedbackData.comment = this.feedbackGroup.value.comment;
        this.studentReasonFeedbackData.anonymized = this.feedbackGroup.value.anonymized;
        this.studentReasonFeedbackData.reason = this.feedbackGroup.value.reason.value;

        this.promotionApiResource.enterReasonBasedPerfectMatchFeedback(this.studentReasonFeedbackData, this.token).then(() => {
          let googleFeedback = false;
          this.router.navigateByUrl('actions/feedback/thank-you?code=' + this.token
            + '&fbp=' + JSON.stringify(googleFeedback));
        })
      }


    } else {
      this.errorMessage = true
    }
  }

  updateFormControlValue(event: number) {
    this.feedbackGroup.controls.rating.setValue(event);
  }

  getTokenInformation() {
    this.registrationInfoLoading = true;
    this.token = this.route.snapshot.queryParamMap.get("code");
    this.registrationInfoService.getRegistrationInfoForToken(this.token).then(result => {
      this.registrationInfo = result
      if(this.registrationInfo.promotionData.formatId == 2313 && !this.registrationInfo.checkedIn){
        this.perfectMatchAndNotCheckedIn = true
      }
      this.initializeForm()
      this.registrationInfoLoading = false;
      if (this.registrationInfo.promotionData) this.getRecommendedOfferPageIds();
    })
  }

  goHome() {
    this.router.navigate(["home"], {queryParamsHandling: 'preserve'})
  }

  openSupportModal() {
    this.modal.open(ContactSupportModalComponent);
  }

  getRecommendedOfferPageIds() {
    this.offerPageResource.getOfferPageUpsellRecommendationsBySyncedFormatId(this.registrationInfo.promotionData.formatId).then(res => {
      this.recommendedUpsellPageIds = res;
    })
  }

  openConfirmDialog() {
    let dialogRef = this.dialog.open(StudentConfirmPresentDialogComponent, {
      data: { registrationInfo: this.registrationInfo, token: this.token},
      maxWidth: '100%',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(value => {
      if (value) this.router.navigateByUrl('actions/feedback/student-present-feedback/token?code=' + this.token)
    })
  }

}
