import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThankYouPageComponent} from "./thank-you-page/thank-you-page.component";
import {RouterModule, Routes} from "@angular/router";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {OfferPagePanelModule} from "../offer-page-panel/offer-page-panel.module";
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";
import {ClipboardModule} from "ngx-clipboard";
import {ShareModule} from "../share/share.module";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";

const appRoutes: Routes = [
  {path: 'thank-you', component: ThankYouPageComponent},
];

@NgModule({
  declarations: [
    ThankYouPageComponent,
  ],
    imports: [
        CommonModule,
        ClipboardModule,
        ShareModule,
        RouterModule.forChild(appRoutes),
        FlexLayoutModule,
        FlexModule,
        OfferPagePanelModule,
        HidInteractionsModule,
        MatButtonModule,
        MatListModule,
        MatIconModule
    ],entryComponents:[
  ]
})
export class ThankYouModule { }
