import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'promotionType'
})
export class PromotionTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    switch(value) {
      case "Coupon":
        return "Gutschein";
      case "OfflineSeminarDate":
        return "Workshop";
      case "MammothWebinarDate":
        return "Webinar";
      case "SingleWebinarDate":
        return "Webinar";
      case "OnlineLecture":
        return "Online-Workshop";
      case "Competition":
        return "Gewinnspiel";
      case "UniqueEvent":
        return "Event";
      case "PerfectMatchEvent":
        return "Perfect Match Event";
      case "Giveaway":
        return "Giveaway";
      case "GeneralPromotion":
        return "Angebot";
      case "GeneralPromotionFormat":
        return "Angebot";
      case "WebinarFormat":
        return "Webinar";
    }
    return null;
  }

}
