<div class="actionlink-page-wrapper">
  <div class="actionlink-main-box">
    <hid-loading-spinner [loadingBool]="loading">
      <div *ngIf="!confirmed&& !loading">
        <h1>Bestätigung der Teilnahme</h1>
       Mit deiner Bestätigung hilfst du uns bei der Planung unserer Termine.
        <app-promotion-info-box [promotionData]="registrationInfo.promotionData"></app-promotion-info-box>

        <hid-loading-button style="text-align: center"[loadingBool]="confirming" buttonClass="hid-button primary" (clickEvent)="confirmRegistration()">Teilnahme bestätigen</hid-loading-button>
      </div>

      <!-- On Reg Cancelled + was not already cancelled -->
      <div class="itemInfo" fxLayout="column" fxLayoutAlign="start center" *ngIf="confirmed">
        <h1 class="text-green"><i class="fa fa-check fam"></i>Teilnahme bestätigt</h1>
        <p>Zusammen macht's mehr Spaß - Lade doch einen Freund ein! Dein Freund wird automatisch auf den selben Termin gebucht.</p>
        <button class="hid-button primary" (click)="openShare()">Angebot teilen</button>
      </div>

    </hid-loading-spinner>
  </div>
</div>
