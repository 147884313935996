<div  id="checkout-header" fxLayout="row" fxLayoutAlign="space-between center" style="font-size: 20px">
    <span>Wähle deine Region</span>
    <button  class="hid-icon-button inverse ita_close_checkout-modal" (click)="globalCheckoutService.returnToOfferPage()"><i
            class="fas fa-times"></i>
    </button>
</div>

<div class="checkout-body">


    <div #top fxLayout="row" fxLayoutAlign="center center" id="location-search">
        <hid-input-field label="Suche" class="w-100" [model]="searchQuery"
                         style="margin-bottom: 10px"
                         (modelChange)="searchQuery = $event;"></hid-input-field>
    </div>
    <div fxLayout="column" style="position: relative" id="location-list">
        <hid-loading-spinner [loadingBool]="loading">
            <div fxLayout="row" fxLayoutAlign="center center">
              <app-geolocation (result)="closeLocations = $event" (calculationStateEvent)="locationCalculationState = $event"></app-geolocation>
            </div>
            <div fxLayout="column" *ngIf="locationCalculationState === 'Finished'" class="ita_detected-locations">
                <div class="section-header" *ngIf="(closeLocations | stringSearch: 'name': searchQuery : true).length > 0">In deiner
                    Nähe
                </div>
                <div fxLayout="row" class="locationItem" fxLayoutAlign="space-between center"
                     *ngFor="let location of closeLocations | stringSearch: 'name': searchQuery : true">
                    <div>{{location.name}}</div>
                    <button class="hid-button secondary outline expand"
                            (click)="selectLocation(location)">Auswählen
                    </button>
                </div>

                <div *ngIf="closeLocations.length == 0" style="height: 55px; color: #777; padding: 8px;" fxLayout="row" fxLayoutAlign="center center">
                    <span>Leider haben wir in deinem näheren Umkreis keine passenden Orte gefunden</span>
                </div>


                <div class="section-header">Alle</div>
            </div>
            <div fxLayout="row" class="locationItem" fxLayoutAlign="space-between center"
                 *ngFor="let location of locations | stringSearch: 'name': searchQuery: true">
                <div>{{location.name}}</div>
                <button class="hid-button secondary outline expand" (click)="selectLocation(location)"
                >Auswählen
                </button>
            </div>

        </hid-loading-spinner>
    </div>
</div>
