import {Component, Input, OnInit} from '@angular/core';
import {PublicOfferPageResource} from "../generated/cms/resources";
import {BlogCategoryData, PublicBlogPostPreviewData} from "../generated/cms/data";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-blog-post-grid',
  templateUrl: './blog-post-grid.component.html',
  styleUrls: ['./blog-post-grid.component.scss']
})
export class BlogPostGridComponent implements OnInit {

  @Input()enableCategoryFilter = false;

  @Input() excludePostId: number;

  categories: BlogCategoryData[] = [];

  loadingPosts = false;
  currentPage = 0;
  pageSize = 6;
  totalPages = 0;

  @Input() categoryIds: number[];

  categoryFilter = new FormControl()

  posts: PublicBlogPostPreviewData[] =  [];

  constructor(private offerPageResource: PublicOfferPageResource) { }

  ngOnInit() {
    if(this.enableCategoryFilter) this.loadBlogCategories();
    this.loadPosts();
    this.categoryFilter.setValue(null)
    this.categoryFilter.valueChanges.subscribe(() => {
     // this.categoryIds = [this.categoryFilter.value]
     // this.resetAndLoad()

    })
  }

  loadBlogCategories(){
    this.offerPageResource.getBlogCategories().then(res => {
      this.categories = res;
    });
  }
  resetAndLoad(){
    this.posts = [];
    this.currentPage = 0;
    this.loadPosts()
  }

  loadPosts() {
    this.loadingPosts = true;
    this.offerPageResource.getBlogPostsPreview({
      q: '',
      categoryIds: this.categoryIds,
      page: this.currentPage,
      pageSize: this.pageSize
    }).then(res => {
      for(let post of res.content) {
        this.posts.push(post);
      }
      this.totalPages = res.totalPages;
      this.loadingPosts = false;
      if(this.excludePostId){
        for(let post of this.posts){
          if(post.id == this.excludePostId){
            this.posts.splice(this.posts.indexOf(post),1)
          }
        }
      }
    })
  }

  loadNextPage() {
    this.currentPage++
    this.loadPosts();
  }


  //Currently only supports singleselection
  setCatIdsAndReload(catId){
    this.categoryIds = [];
    this.categoryIds.push(catId);
    this.totalPages = 0;
    this.currentPage = 0;
    // remove prev loaded posts
    this.posts = []
    this.loadPosts();
  }

}
