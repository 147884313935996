<div class="hid-input-container" #wrapper *ngIf="show">
<textarea
  #textArea2
  *ngIf="maxLength"
  autosize
  [minRows]="minRows"
  [maxLength]="maxLength"
  class="hid-textarea {{inputFieldClass}}"
  [formControl]="control"
  id="{{dynamicId}}"
  [name]="name"
  [ngClass]="{'filled': control.value != null && control.value.toString().length > 0, 'padding-bottom': charCounterShow}"
></textarea>
  <textarea
    #textArea1
    *ngIf="!maxLength"
    autosize
    [minRows]="minRows"
    class="hid-textarea {{inputFieldClass}}"
    [formControl]="control"
    id="b{{dynamicId}}"
    [name]="name"
    [ngClass]="{'filled': control.value != null && control.value.toString().length > 0, 'padding-bottom': charCounterShow}"
  ></textarea>
  <div *ngIf="charCounterShow && !control.value" class="char-counter">0/{{maxLength}}</div>
  <div *ngIf="charCounterShow && control.value" class="char-counter">{{control.value.toString().length}}
    /{{maxLength}}</div>
  <label for="{{dynamicId}}" #labelContainer (click)="setFocus()"
         [ngClass]="{'wrapped-label': wrappedLabel}">{{label}}</label>
</div>
