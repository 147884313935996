import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appOnScroll]'
})
export class OnScrollDirective {

  @Output() onScroll: EventEmitter<any> = new EventEmitter()

  @HostListener('scroll', ['$event'])
  onWindowScroll($event) {
    this.onScroll.emit()
  }

  constructor() {
  }

}
