import {Component, Inject, OnInit} from '@angular/core';
import {LegalAPIResourceV2} from "../../generated/cronos/resources";
import {ActivatedRoute} from "@angular/router";
import {LocalStorageService} from "../../local-storage.service";
import {GDPRConsentDialogService} from "../../gdprconsent/gdprconsent-dialog.service";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  cronosFormatId: number;

  privacyContent: string =""

  constructor(
    private cronosPrivacyApi: LegalAPIResourceV2,
    private route: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public gdprConsentDialogService: GDPRConsentDialogService,
    @Inject("env") private env
  ) {

    this.route.params.subscribe(params => {
      this.cronosFormatId = params.cronosFormatId
    })
  }

  ngOnInit() {
    this.getPrivacyText();
  }

  getPrivacyText(){
    this.cronosPrivacyApi.getCurrentPrivacyText().then( res =>{
    // this.cronosPrivacyApi.getCurrentPrivacyText({formatId: this.cronosFormatId, token: this.env.cronosSourceToken}).then( res =>{
      this.privacyContent = res.value
    })
  }

  openGDPRConsent() {
    this.gdprConsentDialogService.openConsentDialog(true)
  }

}
