import {Component, Inject, OnInit} from '@angular/core';
import {PublicOfferPageResource} from "../../../../generated/cms/resources";
import {ActivatedRoute, Router} from "@angular/router";
import {PromotionApiResourceV2, SeminarProcessAPIResourceV2} from "../../../../generated/cronos/resources";
import {HttpClient} from "@angular/common/http";
import {ClipboardService} from "ngx-clipboard";
import {HidSnackbarService} from "../../../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {RegistrationInfoService} from "../../registration-info.service";
import {RegistrationInfoData} from "../../../../generated/cronos/data";
import {PublicOfferPagePreviewData} from "../../../../generated/cms/data";
import {ShareService} from "../../../../share/share.service";

@Component({
  selector: 'app-feedback-thank-you-page',
  templateUrl: './feedback-thank-you-page.component.html',
  styleUrls: ['./feedback-thank-you-page.component.scss']
})
export class FeedbackThankYouPageComponent implements OnInit {

  recommendedUpsellPageIds: number[] = [];
  offerPageId: number;
  token: string = '';
  permissionToDownloadCertificate: boolean = false;
  retreivingCertificate: boolean = false;

  registrationInfo:  RegistrationInfoData = <RegistrationInfoData>{};
  seminarFeedbackPageDataLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private offerPageResource: PublicOfferPageResource,
    private router: Router,
    private promotionApiResource: PromotionApiResourceV2,
    private http: HttpClient,
    @Inject("env") private environment,
    private clipboard: ClipboardService,
    private snackBar: HidSnackbarService,
    private shareService: ShareService,
    private registrationInfoService: RegistrationInfoService,
    private seminarProcessAPIResourceV2: SeminarProcessAPIResourceV2
  ) {
  }

  feedbackWasPositive = false;
  ngOnInit() {
    this.getTokenInformation();
    this.feedbackWasPositive = this.route.snapshot.queryParamMap.get("fbp") === 'true';
    this.checkPermissionToDownloadPromRegStateRelatedCertificate();
  }

  goToTrustPilotReview(){
    return 'https://www.trustpilot.com/review/hochschulinitiative-deutschland.de'
  }

  goToUniwunderGoogleReview() {
    return 'https://g.page/r/CT2LK1knnpk_EBM/review?rc'
  }

  getRecommendedOfferPageIds() {
    this.offerPageResource.getOfferPageUpsellRecommendationsBySyncedFormatId(this.registrationInfo.promotionData.formatId).then(res => {
      this.recommendedUpsellPageIds = res;
    })
  }

  goHome() {
    this.router.navigate(["home"], {queryParamsHandling: 'preserve'})
  }

  navigateToHandout(){
    this.router.navigateByUrl( 'actions/seminar/handout-download/token?code=' + this.token)
  }

  getTokenInformation() {
    this.seminarFeedbackPageDataLoading = true;
    this.token = this.route.snapshot.queryParamMap.get("code");
    this.registrationInfoService.getRegistrationInfoForToken(this.token).then(result => {
      this.registrationInfo = result
      this.seminarFeedbackPageDataLoading = false;

      this.getRecommendedOfferPageIds();
      this.getPromotionOfferpage()
    })
  }

  page:PublicOfferPagePreviewData
  getPromotionOfferpage(){
    this.offerPageResource.getFormatPageForSyncedFormat({syncedFormatId: this.registrationInfo.promotionData.formatId}).then(res => {
      this.page = res;
    })
  }
  checkPermissionToDownloadPromRegStateRelatedCertificate() {
    this.promotionApiResource.checkPermissionToDownloadPromRegStateRelatedCertificate(this.token).then(
      result => {
        this.permissionToDownloadCertificate = result.value;
      }
    )
  }

  copyUrlToClipboard() {
    let url = window.location.href
    this.clipboard.copyFromContent(url);
    this.snackBar.openSnackBar("Link in die Zwischenablage kopiert", null, 5000,false);
  }
}
