import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";

export class RadioOption {
  constructor(public value: any,
              public display: string) {
  }
}

@Component({
  selector: 'hid-radio-group',
  templateUrl: './hid-radio-group.component.html',
  styleUrls: ['./hid-radio-group.component.scss']
})
export class HidRadioGroupComponent implements OnInit {

  @Input() options: RadioOption[] = [];
  @Input() control: AbstractControl;


  //use subject if changes from outside needed
  @Input() selected: RadioOption = null;
  @Output() selectedChange: EventEmitter<RadioOption> = new EventEmitter()


  constructor() {
  }

  ngOnInit() {
    //if model driven
    if (!this.control) {
      this.control = new FormControl(this.selected);
      this.control.valueChanges.subscribe(newValue => {
        this.selectedChange.emit(newValue)
      })
    }
  }

  select(option: RadioOption) {
    this.control.setValue(option)
    this.selectedChange.emit(this.control.value)
  }

}
