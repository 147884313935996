import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch(value) {
      case "Male":
        return "Herr";
      case "Female":
        return "Frau";
      case "Diverse":
        return "Divers"
      case "Unknown":
        return "Keine Angabe";
      case null:
        return "Keine Angabe"
    }
  }

}
