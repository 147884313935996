import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OnlineLectureService} from "../../online-lecture.service";

export interface AppointmentData {
  date: Date,
  hour: number,
  minutes: number
}

@Component({
  selector: 'app-mobile-datepicker-dialog',
  templateUrl: './mobile-datepicker-dialog.component.html',
  styleUrls: ['./mobile-datepicker-dialog.component.scss']
})
export class MobileDatepickerDialogComponent implements OnInit {



  openedPicker = false;
  time: string = '14:30';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AppointmentData,
    public onlineLectureService: OnlineLectureService,
    public dialogRef: MatDialogRef<MobileDatepickerDialogComponent>,) {
  }


  ngOnInit() {
    this.time = this.data.hour + ':' + this.data.minutes;
  }

  cancel() {
    this.dialogRef.close()
  }

  requestAppointment() {
    this.data.hour = parseInt(this.time.substring(0, 2));
    this.data.minutes = parseInt(this.time.substring(3, 5));

    this.dialogRef.close(this.data)
  }

  getAppointmentRequestText() {
    if (this.onlineLectureService.format.appointmentRequestText) return this.onlineLectureService.format.appointmentRequestText;
    else return "Sichere dir jetzt deinen Wunschtermin für den zweiten Seminarteil!";
  }
}
