<div class="offer-panel">
  <a href="https://www.instagram.com/hochschulinitiativedeutschland/">
    <div class="image-wrap">
      <img id="offer-image" alt="instagram" #image src="../../../assets/img/carousell_instagram.png">
    </div>

    <div fxLayout="column" fxLayoutAlign="space-between start" class="description">
      <div fxLayout="row" fxLayoutAlign="start center">
      </div>
      <span class="instagram-link">
        <b> Folge uns für Studi-Tipps, Memes und Videos.</b>
      </span>

    </div>
  </a>
</div>
