import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../local-storage.service';
import {requirementsPasswordMatched} from '../../inno-utils/general-utils.service';
import {AnalyticsService} from '../../analytics/analytics.service';
import {HidSnackbarService} from '../../hid-interactions/hid-snackbar/hid-snackbar.service';
import {delay} from '../../utils.service';
import {MailjetRegistrationTemplate} from '../../generated/cronos/data';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  validForRegistration: boolean = false;
  form: FormGroup;
  referralCode: string | null = '';
  @Input() calledFromLp: boolean = false;
  registrationInProgress: boolean = false;
  /**
   * Retrieves the Mailjet registration template.
   */
  @Input()
  mailjetRegistrationTemplate: MailjetRegistrationTemplate = 'DEFAULT';
  /**
   * Specifies the relative path for redirection in the frontend after successful confirmation.
   * For example, `bafoeg/result/<ID>` or `home`.
   * If not provided, the base path from the [MailjetRegistrationTemplate.basePath] is used.
   */
  @Input()
  relativeRedirectPath: string | null = null;

  //TODO this needs to be updated once QA is not the only organic register path
  @Input() accountOrigin: string = 'QA first profile click';

  @Output() registerSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private snackBar: HidSnackbarService,
    private localStorageService: LocalStorageService) {
  }

  initializeForm() {
    this.form = this.fb.group({
      firstName: [null, Validators.required],
      termsAndConditionsConsent: [false, Validators.requiredTrue],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, (c) => this.requirementsMatched(c)]]
    });

    this.form.valueChanges.subscribe(() => {
      this.validForRegistration = this.form.controls['firstName'].valid && this.form.controls['termsAndConditionsConsent'].value &&
        this.form.controls['email'].valid;
    });
  }


  ngOnInit(): void {
    this.initializeForm();
  }

  requirementsMatched(c: AbstractControl) {
    return requirementsPasswordMatched(c.value, 2, 6);
  }

  register() {
    this.registrationInProgress = true;
    if (!this.validForRegistration) {
      this.registrationInProgress = false;
      return;
    }
    this.analyticsService.reportRegistration(this.accountOrigin);

    if (this.localStorageService.getAccountReferralCode()) {
      this.referralCode = this.localStorageService.getAccountReferralCode();
    }
    debugger
    this.authService.register({
      firstName: this.form.controls['firstName'].value,
      emailAddress: this.form.controls['email'].value,
      password: this.form.controls['password'].value,
      referralCode: this.referralCode,
      mailjetRegistrationTemplate: this.mailjetRegistrationTemplate,
      relativeRedirectPath: this.relativeRedirectPath ? encodeURIComponent(this.relativeRedirectPath) : null
    }).subscribe(result => {
        this.registrationInProgress = false;
        if (result == 'LoginSuccessful') {
          this.snackBar.openSnackBar('Du hast dich erfolgreich registriert. Willkommen!', null, 2000, false);
          this.registerSuccessful.emit(true);
          delay(500).then(() => {
            if (this.router.url.includes('register')) {
              this.router.navigateByUrl('/home');
            }
          });
        }
      }
    );
  }

  containsSmallLetter(): boolean {
    if (this.form.controls.password.value == null) {
      return false;
    }
    return (/[a-z]/.test(this.form.controls.password.value));
  }

  containsBigLetterOrNumber() {
    if (this.form.controls.password.value == null) {
      return false;
    }
    return (/[A-Z0-9]/.test(this.form.controls.password.value));
  }
}
