import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {InternalBlogPostData, PublicOfferPagePreviewData} from "../../generated/cms/data";
import {BehaviorSubject, Subject} from "rxjs";
import {delay} from "../../utils.service";
import {ClipboardService} from "ngx-clipboard";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {ShareService} from "../../share/share.service";

@Component({
  selector: 'app-blog-post-sidebar',
  templateUrl: './blog-post-sidebar.component.html',
  styleUrls: ['./blog-post-sidebar.component.scss']
})
export class BlogPostSidebarComponent implements OnInit {

  @Input() post: InternalBlogPostData;

  // @Input() utmShareSource: string;
  @Input() utmCampaignIdent: string;
  @Input() shareText: string;
  @Input() additionalQueryParams: any;

  shareLink;

  offers: BehaviorSubject<PublicOfferPagePreviewData>[] = [];

  constructor(private offerPageResource: PublicOfferPageResource,
              private clipboard: ClipboardService,
              private snackBar: HidSnackbarService,
              private shareService: ShareService
  ) {
  }

  navigate() {
  }

  ngOnInit() {
    this.offerPageResource.getBlogPageOffers(this.post.id).then(
      res => {
        for(let offer of res){
          const s = new BehaviorSubject<PublicOfferPagePreviewData>(offer)
          this.offers.push(s)

          /*//TODO dirty. if you do it right away panel is on init and subscriber not ready
          delay(200).then(
            () =>{          s.next(offer)
            }
          )*/
        }
      }
    )

    this.shareLink = '/blog/' + this.post.routingUrl + '?utm_source=hidpage' + '&utm_campaign=' + this.utmCampaignIdent + '&utm_medium=hid-share';
    if (this.additionalQueryParams) {
      for (let param of this.additionalQueryParams) {
        this.shareLink += '&' + param.key + '=' + param.value;
      }
    }
  }

  sharelinkToClipBoard() {
    this.clipboard.copyFromContent(this.shareLink);
    this.snackBar.openSnackBar("Link in der Zwischenablage", null, 10000, false);
  }

  initShare(){
    this.shareService.showSharingButtons(this.shareLink, 'blogpost-share', 'Hey, ich habe einen coolen Artikel auf HID für dich: ', null);
  }
}
