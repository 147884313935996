<h1 mat-dialog-title fxLayoutAlign="start start">
  <button class="hid-icon-button inverse" (click)="cancel()" fxHide fxShow.xs style="margin-right: 20px"><i
    class="fas fa-arrow-left"></i></button>
    <span>Termin vereinbaren</span>
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutAlign="space-evenly center" class="content-wrapper">

  <div class="small-text">{{getAppointmentRequestText()}}</div>

  <mat-form-field class="date-wrapper">
    <mat-label>Datum auswählen</mat-label>
    <input matInput readonly="true" [matDatepickerFilter]="onlineLectureService.dateFilter" [matDatepicker]="picker"
           [(ngModel)]="data.date" (click)="openedPicker=!openedPicker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [opened]="openedPicker" [touchUi]="true" #picker></mat-datepicker>
  </mat-form-field>

  <div class="clock-wrapper" fxLayout="row">
    <span class="hint">Uhrzeit</span>
    <input class="input-time" [ngxTimepicker]="toggleTimepicker" min="9:00" max="17:59" [format]="24" [(ngModel)]="time"
           readonly>
    <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>

  </div>
  <div style="font-size: 12px" class="small-text">Werktags zwischen 9:00 und 18:00 möglich</div>
  <!--<mdb-date-picker name="mydate" [(ngModel)]="time" required></mdb-date-picker>-->

</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
  <button class="hid-button primary ita_request-appointment"
          [disabled]="!data.date || time == null || time ==''"
          (click)="requestAppointment()">Termin anfordern
  </button>
</div>

<ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker>
