<div *ngIf="imminentWebinarDate != null && daysTillSeminar != null && opened" fxLayout="row" fxLayoutAlign="space-between center" class="promotion-reminder-wrapper">
<!--  pseudodiv-->
  <div style="width: 20px"></div>
  <div *ngIf="daysTillSeminar >= 0 && minutesTillSeminar > 30" style="padding: 8px">
    <div *ngIf="daysTillSeminar == 0">
      Heute um {{imminentWebinarDate.date | date: 'HH:mm'}} ist dein Webinartermin!
    </div>
    <div *ngIf="daysTillSeminar == 1">
       Morgen um {{imminentWebinarDate.date | date: 'HH:mm'}} ist dein Webinartermin!
    </div>
    <div *ngIf="daysTillSeminar >= 2">
      In {{daysTillSeminar}} Tagen am {{imminentWebinarDate.date | date: 'dd.MM.yyyy'}} um {{imminentWebinarDate.date | date: 'HH:mm'}} ist dein Webinartermin!
    </div>
  </div>
  <button mat-flat-button (click)="close()" class="cancel-button">Alles klar</button>
<!--  <i style="cursor: pointer" class="fas fa-times" (click)="close.emit(true)"></i>-->
</div>
