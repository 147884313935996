import {ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {HidSnackbarComponent} from "./hid-snackbar.component";

@Injectable({
  providedIn: 'root'
})
export class HidSnackbarService {

  constructor(
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }


  openSnackBar(text: string, closeButtonText: string, automaticClosingTime: number,error: boolean) {
    const comp = this.resolver
      .resolveComponentFactory(HidSnackbarComponent)
      .create(this.injector);


    comp.instance.selfDestroy.subscribe(() => {
      this.destroySnackbar(comp)
    });
    comp.instance.text = text;
    comp.instance.closeText = closeButtonText;
    comp.instance.error = error;

    this.appRef.attachView(comp.hostView);

    const dom = (comp.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    document.body.appendChild(dom);

    if (automaticClosingTime) {
      setTimeout(() => {
        this.destroySnackbar(comp)
      }, automaticClosingTime)
    }
  }

  destroySnackbar(comp: any) {
    comp.instance.initialized = false;
    setTimeout(() => {
      this.appRef.detachView(comp.hostView);
      comp.destroy()
    }, 300)

  }
}

