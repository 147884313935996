import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {OgTagData, InternalAbstractFormatPageData, PagedData, InternalOfferPageData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.InternalOfferPageController
 */

@Injectable()
@ResourceParams({})
export class InternalOfferPageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/{id}/ogtag',
        endpoint: 'cms'
    })
    private _createOrUpdateOgTagForPage: IResourceMethodObservableStrict<OgTagData, {}, {id: number}, void>;
    createOrUpdateOgTagForPage(requestBody: OgTagData, id: number): Promise<void> {
        return this._createOrUpdateOgTagForPage(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/formatPages',
        endpoint: 'cms'
    })
    private _getAbstractFormatPages: IResourceMethodObservableStrict<void, {q?: string, page?: number, pageSize?: number}, {}, PagedData<InternalAbstractFormatPageData>>;
    getAbstractFormatPages(requestParams?: {q?: string, page?: number, pageSize?: number}): Promise<PagedData<InternalAbstractFormatPageData>> {
        return this._getAbstractFormatPages(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages',
        endpoint: 'cms'
    })
    private _getOfferPages: IResourceMethodObservableStrict<void, {q?: string, page?: number, pageSize?: number}, {}, PagedData<InternalOfferPageData>>;
    getOfferPages(requestParams?: {q?: string, page?: number, pageSize?: number}): Promise<PagedData<InternalOfferPageData>> {
        return this._getOfferPages(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/{id}/upsells',
        endpoint: 'cms'
    })
    private _getUpsellsForOfferPage: IResourceMethodObservableStrict<void, {}, {id: number}, InternalOfferPageData[]>;
    getUpsellsForOfferPage(id: number): Promise<InternalOfferPageData[]> {
        return this._getUpsellsForOfferPage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/offerPages/{id}/upsells',
        endpoint: 'cms',
        keepEmptyBody: true
    })
    private _updateUpsellsForOfferPage: IResourceMethodObservableStrict<number[], {}, {id: number}, void>;
    updateUpsellsForOfferPage(requestBody: number[], id: number): Promise<void> {
        return this._updateUpsellsForOfferPage(requestBody, null, {id: id}).toPromise()
    }

}