<div fxLayout="row-reverse" fxLayoutAlign="center center" class="rating">
  <div [ngStyle]="{'font-size': size}" [ngClass]="{'active': model >  4}" class="star" (click)="setModel(5)">&#9733;
  </div>
  <div [ngStyle]="{'font-size': size}" [ngClass]="{'active': model >  3}" class="star" (click)="setModel(4)">&#9733;
  </div>
  <div [ngStyle]="{'font-size': size}" [ngClass]="{'active': model >  2}" class="star" (click)="setModel(3)">&#9733;
  </div>
  <div [ngStyle]="{'font-size': size}" [ngClass]="{'active': model >  1}" class="star" (click)="setModel(2)">&#9733;
  </div>
  <div [ngStyle]="{'font-size': size}" [ngClass]="{'active': model >  0}" class="star" (click)="setModel(1)">&#9733;
  </div>
</div>
