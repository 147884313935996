import {Injectable} from '@angular/core';
import {PublicSeminarData} from '../../generated/cronos/data';
import {LocalStorageService} from '../../local-storage.service';
import {PromotionApiResourceV2} from '../../generated/cronos/resources';
import {GlobalCheckoutService} from '../global-checkout.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StandardCheckoutService {


  constructor(private localStorageService: LocalStorageService, private promotionApiV2Resource: PromotionApiResourceV2, private globalCheckoutService: GlobalCheckoutService, private router: Router) {
  }


  //If the differences between onlinelecture and default get too big, implement a new checkout process for onlinelectures instead of spamming if/else
  completeCheckout(hidSplit: string) {
    if (this.globalCheckoutService.getCurrentPage().formatType == 'OnlineLectureFormat') {
      this.completeCheckoutOnlineLecture();
    } else {
      this.completeCheckoutDefault(hidSplit);
    }

  }

  private completeCheckoutDefault(hidSplit: string) {
    let data = this.globalCheckoutService.buildEnterPromotionData();

    const cronosFormatId = this.globalCheckoutService.getCurrentPage().cronosFormatId;

    let thankYouQueryParams = {
      pid: this.globalCheckoutService.getCurrentPage().pageId,
      fid: cronosFormatId,
    };

    return this.promotionApiV2Resource.enterPromotionByFormatId(
      data, cronosFormatId
    ).then(() => {
      this.globalCheckoutService.reportTrackingEvent('CheckoutCompleted', {hidSplit: hidSplit});
      this.router.navigate(['thank-you'], {queryParams: thankYouQueryParams});
    });
  }

  private completeCheckoutOnlineLecture(inputSplit?: string) {
    let data = this.globalCheckoutService.buildEnterPromotionData();
    const cronosFormatId = this.globalCheckoutService.getCurrentPage().cronosFormatId;
    let thankYouQueryParams = {
      pid: this.globalCheckoutService.getCurrentPage().pageId,
      fid: cronosFormatId,
    };
    let hidSplit = 'OnlineLecture';
    if (inputSplit) {
      hidSplit = inputSplit;
    }
    this.promotionApiV2Resource.enterOnlineLectureByFormatId(data, cronosFormatId).then(access => {
      this.globalCheckoutService.reportTrackingEvent('CheckoutCompleted', {hidSplit: hidSplit});
      this.router.navigate(['/enter/onlineLecture/' + cronosFormatId + '/validate/' + access.token], {queryParams: thankYouQueryParams});
    })
  }
}
