import { Component, OnInit } from '@angular/core';
import {ActionLinkAPIResourceV2} from "../../../generated/cronos/resources";
import {ActivatedRoute, Router} from "@angular/router";
import {ContactSupportModalComponent} from "../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {PublicOfferPageResource} from "../../../generated/cms/resources";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RegistrationInfoData, UpdateRegistrationStateFeedbackData} from "../../../generated/cronos/data";
import {RegistrationInfoService} from "../registration-info.service";

@Component({
  selector: 'app-promotion-state-cancel-action-link',
  templateUrl: './promotion-state-cancel-action-link.component.html',
  styleUrls: ['./promotion-state-cancel-action-link.component.scss']
})
export class PromotionStateCancelActionLinkComponent implements OnInit {

  token: string;
  loading: boolean = true;
  registrationInfo: RegistrationInfoData;

  cancellationComplete = false;

  constructor(private actionLinkAPIResourceV2: ActionLinkAPIResourceV2,
              private route: ActivatedRoute,
              private router: Router,
              private registrationInfoService: RegistrationInfoService,
              private offerPageResource: PublicOfferPageResource,
              private modal: NgbModal) {
    route.snapshot.queryParamMap.get("code") ? this.token = route.snapshot.queryParamMap.get("code") : this.token = route.snapshot.paramMap.get("token");
  }

  ngOnInit() {
    this.registrationInfoService.getRegistrationInfoForToken(this.token).then(res => {
      this.registrationInfo =res;
      this.loading = false;
    })
        }

  cancelling = false;
  cancelRegistration() {
    this.cancelling = true;
    this.actionLinkAPIResourceV2.cancelRegistration({value: this.token}).then( () => {
        this.cancelling = false;
this.cancellationComplete = true;
      })
  }
  navigateToOfferPage() {
    this.offerPageResource.getFormatPageForSyncedFormat({syncedFormatId: this.registrationInfo.promotionData.formatId}).then(offerPage => {
      this.router.navigate(['/angebote/', offerPage.routingUrl], {queryParams: {instantCheckout: true}} )
    })
  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }


}
