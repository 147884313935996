import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-blog-overview',
  templateUrl: './blog-overview.component.html',
  styleUrls: ['./blog-overview.component.scss']
})
export class BlogOverviewComponent implements OnInit {

  constructor(
  ) {
  }


  ngOnInit() {

  }





}
