import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PromotionApiResourceV2, SeminarProcessAPIResourceV2} from '../../../../generated/cronos/resources';
import {BadFeedbackReason, CreateRatingBasedFeedbackData, RegistrationInfoData} from '../../../../generated/cronos/data';
import {ContactSupportModalComponent} from '../../../../contact-support-modal/contact-support-modal/contact-support-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PublicOfferPageResource} from '../../../../generated/cms/resources';
import {UtilsService} from '../../../../utils.service';
import {RegistrationInfoService} from '../../registration-info.service';

@Component({
  selector: 'seminar-student-present-feedback',
  templateUrl: './seminar-student-present-feedback.component.html',
  styleUrls: ['./seminar-student-present-feedback.component.scss']
})
export class SeminarStudentPresentFeedbackComponent implements OnInit {

  feedbackSent: boolean = false;
  errorMessage: boolean = false;

  recommendedUpsellPageIds: number[] = [];

  feedbackGroup: FormGroup;
  token: string = '';
  registrationInfo: RegistrationInfoData = <RegistrationInfoData>{};
  seminarParticipatedFeedbackData: CreateRatingBasedFeedbackData = <CreateRatingBasedFeedbackData>{};
  registrationInfoLoading: boolean = true;

  badFeedbackReasons: BadFeedbackReason[] = ["LinkNotWorking", "CanNotAccess", "BadContent", "DidNotTakePlace", "Kicked", "Other"];
  contentBasedBadFeedbackReasons: BadFeedbackReason[] = ["ContentTooSimple", "ContentTooHard", "LinkNotWorking", "DidNotTakePlace", "Kicked", "CanNotAccess"]
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private offerPageResource: PublicOfferPageResource,
    private seminarApiResourceV2: SeminarProcessAPIResourceV2,
    private utilsService: UtilsService,
    private registrationInfoService: RegistrationInfoService,
    private promotionResource: PromotionApiResourceV2
  ) {
  }

  ngOnInit() {
    this.initializeForm()
    this.getTokenInformation();
  }

  navigateToStudentWasNotPresent() {
    this.router.navigateByUrl('actions/feedback/student-not-present-feedback/token?code=' + this.token)
  }

  initializeForm() {
    this.feedbackGroup = this.fb.group({
      rating: [null, Validators.required],
      reasonText: [null],
      anonymized: [false],
      badFeedbackReason: [null]
    })
  }

  sendFeedback() {
    if (this.feedbackGroup.valid && this.feedbackGroup.controls.rating.value < 6 && this.feedbackGroup.controls.rating.value > 0) {
      this.errorMessage = false;
      this.feedbackSent = true;
      this.seminarParticipatedFeedbackData.comment = this.feedbackGroup.value.reasonText;
      this.seminarParticipatedFeedbackData.rating = this.feedbackGroup.value.rating;
      this.seminarParticipatedFeedbackData.anonymized = this.feedbackGroup.value.anonymized;
      this.seminarParticipatedFeedbackData.badFeedbackReason = this.feedbackGroup.value.badFeedbackReason;
      this.promotionResource.enterRatingBasedFeedback(this.seminarParticipatedFeedbackData, this.token).then(result => {
        let feedbackPositive = false;
        if(this.feedbackGroup.controls.rating.value >= 4) {
          feedbackPositive = true
          this.seminarParticipatedFeedbackData.badFeedbackReason = null;
        }
         this.router.navigateByUrl('actions/feedback/thank-you?code=' + this.token
          + '&fbp=' + JSON.stringify(feedbackPositive));
      })
    } else {
      this.errorMessage = true
    }
  }

  updateFormControlValue(event: number) {
    this.feedbackGroup.controls.rating.setValue(event);
  }

  isSelectedReason(reason: string): boolean {
    return this.feedbackGroup.get('badFeedbackReason').value === reason;
  }

  onReasonChipClick(reason: string): void {
    this.feedbackGroup.get('badFeedbackReason').setValue(reason);
  }

  getTokenInformation() {
    this.registrationInfoLoading = true;
    this.token = this.route.snapshot.queryParamMap.get("code");
    this.registrationInfoService.getRegistrationInfoForToken(this.token).then(result => {
      this.registrationInfo = result
      if (this.registrationInfo.promotionData.promotionType == 'OfflineSeminarDate') {
        this.registrationInfo.promotionData.address = this.utilsService.deleteCityNameOutOfAddress(
          this.registrationInfo.promotionData.location, this.registrationInfo.promotionData.address)
      }

      if(this.registrationInfo.promotionData.formatId == 2216){ //Excel Live Webniar
        this.badFeedbackReasons = this.contentBasedBadFeedbackReasons
      }

      this.registrationInfoLoading = false;
      if (this.registrationInfo.promotionData) this.getRecommendedOfferPageIds();
    })
  }

  goHome() {
    this.router.navigate(["home"], {queryParamsHandling: 'preserve'})
  }

  openSupportModal() {
    this.modal.open(ContactSupportModalComponent);
  }

  getRecommendedOfferPageIds() {
    this.offerPageResource.getOfferPageUpsellRecommendationsBySyncedFormatId(this.registrationInfo.promotionData.formatId).then(res => {
      this.recommendedUpsellPageIds = res;
    })
  }

  protected readonly FormControl = FormControl;
}
