import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactSupportModalComponent} from './contact-support-modal/contact-support-modal.component';
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {SupportResource} from "../generated/cms/resources";
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [ContactSupportModalComponent],
    imports: [
        CommonModule,
        HidInteractionsModule,
        FlexLayoutModule,
        FlexModule,
        FormsModule,
        RouterModule
    ],
  exports:[
    ContactSupportModalComponent
  ],
  entryComponents:[
    ContactSupportModalComponent
  ],
  providers: [
    SupportResource
  ]
})
export class ContactSupportModalModule { }
