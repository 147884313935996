import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hid-loading-button',
  templateUrl: './hid-loading-button.component.html',
  styleUrls: ['./hid-loading-button.component.scss']
})
export class HidLoadingButtonComponent implements OnInit {
  @Input() displayCondition: boolean;
  @Input() tooltip: string;
  @Input() color: string;
  @Input() buttonClass: string;
  @Input() disabledCondition: boolean;
  @Input() loadingBool: boolean;
  @Input() raised: boolean;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    if (this.raised == null || typeof this.raised == 'undefined') {
      this.raised = true
    }
  }

  clicked() {
    this.clickEvent.emit();
  }
}
