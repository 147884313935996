import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralUtilsService {

  constructor() { }
}

export async function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function requirementsPasswordMatched(password: string, minScore: number = 3, minlength: number = 8) {
  if (password == null) {
    return {passwordEmpty: true};
  }
  if (password.length < minlength) {
    return {passwordTooShort: true};
  }

  let securityScore = 0;

  if (/[a-z]/.test(password)) securityScore++; // at least one lowercase letter
  if (/[A-Z]/.test(password)) securityScore++; // at least one uppercase letter
  if (/[0-9]/.test(password)) securityScore++; // at least one digit
  if (/[^A-Za-z0-9]/.test(password)) securityScore++; // at least one special char

  if (securityScore < minScore) {
    return {securityRequirementsNotMatched: true};
  }

  return null;
}
