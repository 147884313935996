<div fxLayout="column" fxLayoutAlign="center center" *ngIf="enableCategoryFilter">
  <hid-dropdown [control]="categoryFilter"
                nullValueDisplayText="Alle"
                *ngIf="categories.length > 0 "
                style="width: 600px; margin: 0 5px;max-width: 100%; display: block; position: relative;"
                (modelChange)="setCatIdsAndReload($event)"
                [nullable]="true"
                [identifyingProperty]="'id'"
                [mode]="'Object'"
                displayProperty="title"
                [options]="categories" label="Kategorie">
  </hid-dropdown>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start flex-start" class="posts-wrapper" fxLayoutAlign.xs="center flex-start">
  <app-blog-post-overview-panel [post]="post" *ngFor="let post of posts"></app-blog-post-overview-panel>

  <div *ngFor="let lul of [1,2,3,4,5,6]">
    <app-blog-post-overview-panel
      *ngIf="loadingPosts"
      [loading]="true">
    </app-blog-post-overview-panel>
  </div>

</div>

<div fxLayout="row" fxLayoutAlign="center center">
  <button class="hid-button" (click)="loadNextPage()" *ngIf="currentPage < totalPages - 1">
    Mehr Artikel
  </button>
</div>
