import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, FileContainerData, ImageCompressionContainerInfoData, PagedData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.UploadedFilesController
 */

@Injectable()
@ResourceParams({})
export class UploadedFilesResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/files/images/compressed',
        endpoint: 'cms'
    })
    private _createCompressionContainer: IResourceMethodObservableStrict<void, {file: File, fileName: string}, {}, JsonWrappedValue<number>>;
    createCompressionContainer(requestParams: {file: File, fileName: string}): Promise<JsonWrappedValue<number>> {
        return this._createCompressionContainer(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/files/images/compressed/{id}',
        endpoint: 'cms'
    })
    private _deleteCompressionContainer: IResourceMethodObservableStrict<void, {}, {id: number}, void>;
    deleteCompressionContainer(id: number): Promise<void> {
        return this._deleteCompressionContainer(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/files/images/{id}',
        endpoint: 'cms'
    })
    private _deleteSingleImage: IResourceMethodObservableStrict<void, {}, {id: number}, void>;
    deleteSingleImage(id: number): Promise<void> {
        return this._deleteSingleImage(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/files/images/compressed',
        endpoint: 'cms'
    })
    private _getAllCompressionContainers: IResourceMethodObservableStrict<void, {query?: string, page?: number, pageSize?: number}, {}, PagedData<FileContainerData>>;
    getAllCompressionContainers(requestParams?: {query?: string, page?: number, pageSize?: number}): Promise<PagedData<FileContainerData>> {
        return this._getAllCompressionContainers(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/files/images/compressed/{id}',
        endpoint: 'cms'
    })
    private _getInfoForCompressionContainer: IResourceMethodObservableStrict<void, {}, {id: number}, ImageCompressionContainerInfoData>;
    getInfoForCompressionContainer(id: number): Promise<ImageCompressionContainerInfoData> {
        return this._getInfoForCompressionContainer(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/files/images',
        endpoint: 'cms'
    })
    private _saveNewImage: IResourceMethodObservableStrict<void, {file: File}, {}, JsonWrappedValue<number>>;
    saveNewImage(requestParams: {file: File}): Promise<JsonWrappedValue<number>> {
        return this._saveNewImage(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/files/images/compressed/{id}',
        endpoint: 'cms'
    })
    private _updateCompressionContainer: IResourceMethodObservableStrict<void, {file: File}, {id: number}, void>;
    updateCompressionContainer(id: number, requestParams: {file: File}): Promise<void> {
        return this._updateCompressionContainer(null, requestParams, {id: id}).toPromise()
    }

}