import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {delay} from "../../../utils.service";
import {PublicLocationData} from "../../../generated/cronos/data";
import {GeoLocation, LocalStorageService} from "../../../local-storage.service";
import {PublicStructureResourceV2} from "../../../generated/cronos/resources";

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.sass']
})
export class GeolocationComponent implements OnInit {

  locations: PublicLocationData[] = [];
  closeLocations: PublicLocationData[] = [];
  locationCalculationState: "Uninitialized" | "Initiated" | "Finished" | "PermissionDenied"|"UnknownError" = "Uninitialized";
  frownAnimationFinished = false;
  geoLocation: GeoLocation;

  @Input()
  searchImmediately:boolean = false

  @Output()
  result: EventEmitter<PublicLocationData[]> = new EventEmitter<PublicLocationData[]>()
  @Output()
  calculationStateEvent: EventEmitter<"Uninitialized" | "Initiated" | "Finished" | "PermissionDenied" | "UnknownError"> = new EventEmitter<"Uninitialized" | "Initiated" | "Finished" | "PermissionDenied"|"UnknownError">()
  loading: boolean = false;
  constructor(private localStorageService: LocalStorageService,
    private cronosStructureApi: PublicStructureResourceV2 ) { }

  ngOnInit(): void {
    if(this.searchImmediately){
      this.getLocationAndCalculate()
    }
  }

  getLocationAndCalculate(){
    if (!navigator.geolocation)  return
    this.loading = true
    this.locationCalculationState = "Initiated"
    this.calculationStateEvent.emit("Initiated");
    this.cronosStructureApi.getLocations().then(res => {
      this.locations = res
      navigator.geolocation.getCurrentPosition(this.setGeoLocation.bind(this),this.reactToGeoPermissionError.bind(this))
    })
  }
  reactToGeoPermissionError(error) {
    console.log(error);
    if(error.code === 1) {
      this.locationCalculationState = "PermissionDenied"
      this.calculationStateEvent.emit("PermissionDenied")
    }else{
      this.locationCalculationState = "UnknownError"
      this.calculationStateEvent.emit("UnknownError")
    }
    this.loading = false
    this.localStorageService.setGeoLocationGranted(false);
    this.result.emit([])
    delay(5000).then(() => {
      this.frownAnimationFinished = true;
    })
  }


  setGeoLocation(position) {
    this.geoLocation = {longitude: position.coords.longitude, latitude: position.coords.latitude};
    this.locationCalculationState = "Finished"
    this.calculationStateEvent.emit("Finished");
    this.localStorageService.setGeoLocationGranted(true);
    this.bringCloseLocationsToTop()
  }
  bringCloseLocationsToTop() {
    for (let loc of this.locations) {
      if (loc.longitude && loc.latitude) {
        const dist = this.calculateDistance(loc.latitude, loc.longitude, this.geoLocation.latitude, this.geoLocation.longitude)
        if (dist < 60) {
          this.closeLocations.push(loc)
        }
      }
    }
    this.loading = false
    this.result.emit(this.closeLocations)
  }

  calculateDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km (change this constant to get miles)
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d);
  }
}
