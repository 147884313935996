<div fxLayout="column" fxLayoutAlign="start center" style="position: relative;">
  <div class="mat-elevation-z1 circle" fxLayout="row" fxLayoutAlign="center center">
    <i class="fa fa-hourglass-start circle-item text-orange"></i>
  </div>

  <div fxLayout="column" fxLayoutAlign="start center" style="max-width: 500px; margin: 0 auto; padding-top: 50px">
    <p style="font-size: 25px;" class="promotion-header">Webinar beginnt gleich</p>
  </div>
  <div mat-dialog-content>
    <div *ngIf="data.minutesTillSeminar >= 0">Dein {{data.imminentWebinarDate.formatTitle}} findet in
      <b>{{data.minutesTillSeminar}}</b> Minuten statt!
    </div>
    <div *ngIf="data.minutesTillSeminar > -15 && data.minutesTillSeminar <0">
      Dein {{data.imminentWebinarDate.formatTitle}} findet seit <b>{{data.minutesTillSeminar * -1}}</b> Minuten statt!
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-top: 20px">
  <button mat-flat-button (click)="close()" style="margin-right: 15px" class="cancel-button">
    SCHLIEßEN
  </button>
  <button mat-flat-button *ngIf="data.minutesTillSeminar >= 0" [routerLink]="webinarLink" (click)="close()"
          class="checkin-button">
    EINCHECKEN
  </button>
  <button mat-flat-button *ngIf="data.minutesTillSeminar > -15 && data.minutesTillSeminar <0"
    [routerLink]="webinarLink" (click)="close()" class="checkin-button">
    BEITRETEN
  </button>
</div>
