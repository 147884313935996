import {Component, Inject, OnInit} from '@angular/core';
import {OnlineLectureInfoData, OnlineLectureLessonData} from "../../generated/cronos/data";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {MediaService} from "../../media.service";
import {OnlineLectureAPIResourceV2} from "../../generated/cronos/resources";
import {ShareService} from "../../share/share.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MatDialog} from "@angular/material/dialog";
import {LocalStorageService} from "../../local-storage.service";
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {delay, UtilsService} from "../../utils.service";
import {HttpClient} from "@angular/common/http";
import {OnlineLectureService} from "../online-lecture.service";
import {MobileDatepickerDialogComponent} from "../online-lecture-appointment/mobile-datepicker-dialog/mobile-datepicker-dialog.component";
import {CreateAppointmentRequestDialogComponent} from "./create-appointment-request-dialog/create-appointment-request-dialog.component";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-online-lecture',
  templateUrl: './online-lecture.component.html',
  styleUrls: ['./online-lecture.component.scss']
})
export class OnlineLectureComponent implements OnInit {

  format: OnlineLectureInfoData;
  recommendedUpsellPageIds;
  offerPageId;
  offerPageUrl;
  currentLesson;
  videoUrl;

  numberOfSteps: number = 0;
  currentStep: number = 0;

  loading: Boolean = false;
  downloading: Boolean = false;
  loadingLesson: Boolean = false;
  menuOpen: Boolean = false;

  showAppointmentStep: Boolean = false;
  showSummaryStep: Boolean = false;

  token: string;
  cronosFormatId;
  lessonId: number;

  date;
  form: FormGroup;
  dialogOpen: Boolean = false;

  constructor(
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private onlineLectureAPIResourceV2: OnlineLectureAPIResourceV2,
    private shareService: ShareService,
    private router: Router,
    private modal: NgbModal,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private publicOfferPageResource: PublicOfferPageResource,
    private utilsService: UtilsService,
    @Inject("env") private env,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    public onlineLectureService: OnlineLectureService,
  ) {
  }

  ngOnInit() {
    this.cronosFormatId = (this.route.snapshot.paramMap.get("cronosFormatId"));
    this.lessonId = parseInt(this.route.snapshot.paramMap.get("lessonId"));
    this.load();
  }

  load() {
    this.loading = true;
    this.loadingLesson = true;
    this.token = this.onlineLectureService.getToken(this.cronosFormatId);

    this.onlineLectureService.loadFormat(this.cronosFormatId).then(format => {
      this.format = format;
      this.showAppointmentStep = this.onlineLectureService.showAppointmentStep;
      this.showSummaryStep = this.onlineLectureService.showSummaryStep;
      if (format.lessons.filter(lesson => lesson.id == this.lessonId) == null) this.lessonId = format.lessons[0].id;
      this.getLesson();
      this.numberOfSteps = this.onlineLectureService.numberOfSteps;
    });

    this.onlineLectureService.loadOfferPage(this.cronosFormatId).then(offerPage => {
      this.offerPageUrl = offerPage.offerPageUrl;
      this.offerPageId = offerPage.offerPageId;
      this.recommendedUpsellPageIds = offerPage.upSellIds;
      this.loading = false;
    });
  }

  getLesson() {
    this.loadingLesson = true;
    this.token = this.onlineLectureService.getToken(this.cronosFormatId);
    this.date = this.onlineLectureService.date;
    this.form = this.onlineLectureService.initializeAppointmentDate(this.form);
    if (this.token != null) {
      this.onlineLectureAPIResourceV2.getOnlineLectureLessonByTokenAndId({
        code: this.token,
        id: this.lessonId
      }).then(result => {
        this.loadingLesson = false;
        this.currentLesson = result;
        this.currentStep = this.getIndexOfCurrentLesson();
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentLesson.videoUrl);
      });
    } else {
      this.onlineLectureService.redirectToOfferPage()
    }
  }

  getVideoResolutionWidth() {
    if (this.mediaService.isMobile()) return 370;
    if (this.mediaService.isTablet()) return 600;
    else return 960;
  }

  getVideoResolutionHeight() {
    if (this.mediaService.isMobile()) return 208;
    if (this.mediaService.isTablet()) return 338;
    else return 540;
  }

  menuLocked = false;

  toggleMenu() {
    if (this.menuLocked) return;
    this.menuLocked = true;
    this.menuOpen = !this.menuOpen;
    delay(200).then(() => {
      this.menuLocked = false
    })
  }

  getIndexOfCurrentLesson() {
    return this.format.lessons.indexOf(this.format.lessons.find(lesson => lesson.id == this.currentLesson.id))
  }

  getArray(length) {
    return new Array(length)
  }

  nextLesson() {
    this.currentStep++;
    let nextLessonId = this.format.lessons[this.getIndexOfCurrentLesson() + 1].id;
    this.lessonId = nextLessonId;
    this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/lesson/' + nextLessonId);
    this.load();
  }

  previousLesson() {
    this.currentStep--;
    let previousLessonId = this.format.lessons[this.getIndexOfCurrentLesson() - 1].id;
    this.lessonId = previousLessonId;
    this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/lesson/' + previousLessonId);
    this.load();
  }

  goToStep(i) {
    this.currentStep = i;
    let lessonIdToGoTo = this.format.lessons[i].id;
    if (i == this.numberOfSteps) {
      this.goToSummary()
    } else {
      this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/lesson/' + lessonIdToGoTo);
      this.lessonId = lessonIdToGoTo;
      this.load();
    }
    this.menuOpen = false;
  }

  initShare() {
    //offerpage.routingUrl
    this.shareService.showSharingButtons('/angebote/' + this.offerPageUrl, 'offerpage-share', 'Hey, ich habe ein cooles Angebot auf HID für dich: ', null);
  }

  goToAppointment() {
    this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/appointment');
  }

  goToSummary() {
    this.onlineLectureAPIResourceV2.finishOnlineLecture(this.token).then(() => {
      this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/summary');
    });
  }

  getAppointmentButtonText() {
    if (!this.onlineLectureService.date) {
      if (this.currentLesson.appointmentContentConfig && this.currentLesson.appointmentContentConfig.buttonText) return this.currentLesson.appointmentContentConfig.buttonText;
      else return "Termin anfordern";
    } else return "Termin ansehen";
  }

  getAppointmentDialogHeight() {
    if (!this.onlineLectureService.date) return '340px';
    else return '480';
  }

  openAppointmentDialog() {
    let dialogRef = this.dialog.open(CreateAppointmentRequestDialogComponent, {
      data: {
        date: this.onlineLectureService.date,
        currentLesson: this.currentLesson,
        isDateSet: this.onlineLectureService.date != null
      },
      maxWidth: '100%',
      width: '500px',
      minHeight: this.getAppointmentDialogHeight()
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.form.controls['date'].setValue(result.controls['date'].value);
        this.form.controls['hour'].setValue(result.controls['hour'].value);
        this.form.controls['minute'].setValue(result.controls['minute'].value);
        this.onlineLectureService.setAppointment(this.form, false);
      }
    })
  }


  openDatePicker() {
    this.dialogOpen = true;
    //this.onlineLectureService.openMobileDatePicker(this.form).then( result => {
    //       if (result) this.dialogOpen = false;
    //     })
    let dialogRef = this.dialog.open(MobileDatepickerDialogComponent, {
      data: {
        date: this.form.controls['date'].value,
        hour: this.form.controls['hour'].value,
        minutes: this.form.controls['minute'].value,
      },
      panelClass: 'fullscreen-dialog',
      width: '100%',
      height: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogOpen = false;
      if (result) {
        this.form.controls['date'].setValue(result.date);
        this.form.controls['hour'].setValue(result.hour);
        this.form.controls['minute'].setValue(result.minutes);
        this.onlineLectureService.setAppointment(this.form, false);
      }
    })
  }
}
