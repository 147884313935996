<div fxLayout="row" fxLayoutAlign=" center">
  <hid-input-field [forceValidation]="checkoutAttempted" [showValidatorIcons]="showValidatorIcons"
                   inputFieldClass={{inputFieldClass}}
                   class="hid-input-field-position"
                   name={{name}} type={{type}} [control]="subControl" [isTelephonySplit]="true"
                   label={{label}} [disabled]="disabled"></hid-input-field>

  <div  style="position:absolute;">
    <button
      (click)="handleCountryCodeDropdown()"
      mat-button
      class="button-country-code">
      {{this.selectedEUCountryCode.toUpperCase() + " +" + this.phoneUtil.getCountryCodeForRegion(this.selectedEUCountryCode)}}
      <i class="mdi mdi-chevron-down"></i>
    </button>

    <div class="vertical-divider"></div>

    <div *ngIf="showCountryCodeDropdown"
         (clickOutside)="onClickOutside($event)"
         fxLayout="column" fxLayoutAlign="start start"
         class="phone-number-pre-select">
      <div  *ngFor="let country of euCountries"
            (click)="onClickCode(country.code)"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="phone-number-option-wrapper">
        <div fxLayout="row" fxLayoutAlign="start center">
          <span class="fi fi-{{country.code}} isu-margin-right"></span>
          {{country.name}}
        </div>
        <p style="margin: 0">{{" +" + this.phoneUtil.getCountryCodeForRegion(country.code)}}</p></div>
    </div>
  </div>
</div>

