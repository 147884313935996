import {Injectable} from '@angular/core';
import Bugsnag from "@bugsnag/js";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BugsnagHelperService {

  releaseStage;

  constructor() {
    if (!environment.production) {
      this.releaseStage = 'development'
    }else{
      this.releaseStage = 'production'
    }
  }

  //prototypish for interception only
  notify(error: string, errorClass: string = 'Default', context: string){
    if (this.releaseStage != 'production') return;
    if(context) {
      Bugsnag.setContext(context)
    }
    Bugsnag.notify({errorClass:errorClass, errorMessage: error})
  }


}
