import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeminarCheckoutS5EntryComponent } from './seminar-checkout-s5-entry/seminar-checkout-s5-entry.component';
import { SeminarCheckoutS5MainComponent } from './seminar-checkout-s5-main/seminar-checkout-s5-main.component';
import { SeminarCheckoutS5DatesComponent } from './seminar-checkout-s5-dates/seminar-checkout-s5-dates.component';
import { SeminarCheckoutS5LocationComponent } from './seminar-checkout-s5-location/seminar-checkout-s5-location.component';
import { SeminarCheckoutS5UserComponent } from './seminar-checkout-s5-user/seminar-checkout-s5-user.component';
import {RouterModule, Routes} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {HidInteractionsModule} from "../../../hid-interactions/hid-interactions.module";
import {MatPaginatorModule} from "@angular/material/paginator";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ShareModule} from "../../../share/share.module";
import {GlobalModule} from "../../../global/global.module";
import {NgxJsonLdModule} from "ngx-json-ld";
import {ActionLinkModule} from "../../../actionLinks/action-link/action-link.module";
import {FeedbackStarsModule} from "../../../feedback-stars/feedback-stars.module";
import {AccountModule} from "../../../account/account.module";
import {DiscountsModule} from "../../../discounts/discounts.module";
import {MatButtonModule} from "@angular/material/button";
import {
  GlobalCheckoutComponentsModule
} from "../../components/global-checkout-components/global-checkout-components.module";

const appRoutes: Routes = [
  {path: 'angebote/:routingUrl/checkout/seminars/s5/entry', component: SeminarCheckoutS5EntryComponent},
  {
    path: 'angebote/:routingUrl/checkout/seminars/s5', component: SeminarCheckoutS5MainComponent,
    children: [
      {path: 'location', component: SeminarCheckoutS5LocationComponent},
      {path: 'date', component: SeminarCheckoutS5DatesComponent},
      {path: 'user', component: SeminarCheckoutS5UserComponent}
    ]
  }
]


@NgModule({
  declarations: [
    SeminarCheckoutS5EntryComponent,
    SeminarCheckoutS5MainComponent,
    SeminarCheckoutS5DatesComponent,
    SeminarCheckoutS5LocationComponent,
    SeminarCheckoutS5UserComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    BrowserModule,
    FlexModule,
    HidInteractionsModule,
    MatPaginatorModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    GlobalModule,
    NgxJsonLdModule,
    ActionLinkModule,
    FeedbackStarsModule,
    AccountModule,
    DiscountsModule,
    MatButtonModule,
    GlobalCheckoutComponentsModule,
  ]
})
export class SeminarCheckoutS5Module { }
