import {ElementRef, Injectable} from '@angular/core';
import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {PhoneNumberUtil} from 'google-libphonenumber';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  dialogChange$: Observable<boolean>;
  private dialogStateChanged: Subject<boolean>;

  constructor(
    private router: Router
  ) {
    this.dialogStateChanged = new Subject<boolean>();
    this.dialogChange$ = this.dialogStateChanged.asObservable();
  }

  /**
   * generate readable recital from stringlist
   * @param list
   * @param lastSeperator
   */
  generateRecital(list: string[], lastSeperator: string = 'und'): string {
    if (list.length == 0) return "";
    if (list.length == 1) return list[0];

    return `${list.slice(0, list.length - 1).join(', ')} ${lastSeperator} ${list[list.length - 1]}`;
  }

  /**
   * shrink text and add ...
   * @param text
   * @param length
   */
  shrinkText(text: string, length: number = 200) {
    if (text.length <= length) {
      return text;
    }

    return `${text.substring(0, length)}...`;
  }

  /**
   * Check password for security
   * @param password
   */
  requirementsPasswordMatched(password: string, minScore: number = 3, minlength: number = 8) {
    if (password == null) {
      return {passwordEmpty: true};
    }
    if (password.length < minlength) {
      return {passwordTooShort: true};
    }

    let securityScore = 0;

    if (/[a-z]/.test(password)) securityScore++;
    if (/[A-Z]/.test(password)) securityScore++;
    if (/[0-9]/.test(password)) securityScore++;
    if (/[^A-Za-z0-9]/.test(password)) securityScore++;

    if (securityScore < minScore) {
      return {securityRequirementsNotMatched: true};
    }
    return null;
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  copyStringToClipboard(string: string) {
    let fakeInput = document.createElement('textarea')

    fakeInput.contentEditable = "true"
    fakeInput.style.opacity = '0';
    fakeInput.style.position = 'fixed';
    fakeInput.style.left = '0';
    fakeInput.style.top = '0';
    document.body.appendChild(fakeInput);
    fakeInput.value = string
    this.copyInputMessage(fakeInput)
    document.body.removeChild(fakeInput);
  }

  deleteCityNameOutOfAddress(cityName: string, address: string): string {
    let newAddress = ''

    if (address.includes(cityName)) {
      return address.replace(cityName, '')
    }
    return address
  }

  validateName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let regExp = RegExp('[a,e,i,o,u,ä,ö,ü,é,á,í,ó,ú,ë,ï,y,è,ê,ô,â,ù,û,ý,æ,ø,å,A,E,I,O,U,Y,Ä,Ö,Ü,É,Á,Í,Ó,Ú,Ë,Ï,Y,È,Ê,Ô,Â,Ù,Û,Ý,Æ,Ø,Å]+')
      if (!control.value) {
        return {isNull: true};
      }
      if (control.value.length < 2) {
        return {tooShort: true}
      }
      if (regExp.test(control.value) == false) {
        return {isNotName: true};
      }
      return null
    }
  }
  // this is the old version for split1 test. Delete after the split test is complete.
  validatePhoneNumber_split1(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      let regExp = RegExp('^(\\(?([\\d \\-\\)\\–\\+\\/\\(]+){6,}\\)?([ .\\-–\\/]?)([\\d]+))$');
      if (!control.value) {
        return {isNull: true};
      }
      if(control.value.length > 15){
        return {tooLong: true};
      }
      if (regExp.test(control.value) == false) {
        return {isNotPhoneNumber: true};
      }
      return null;
    }

  }

  validatePhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return {isNull: true};
      }
      return this.checkPhoneNumberReturnErrors(control.value)
    }
  }

  checkPhoneNumberReturnErrors(phoneNumber:string):any{
    let noLettersRegExp = RegExp('^[^a-zA-Z]*$')
    let phoneUtil = PhoneNumberUtil.getInstance();
    if(phoneNumber.trim().length > 15){
      return {tooLong: true};
    }
    if (noLettersRegExp.test(phoneNumber) == false) {
      return {isNotPhoneNumber: true}
    }
    try {
      let number = phoneUtil.parseAndKeepRawInput(phoneNumber)
      let selectedEUCountryCode = phoneUtil.getRegionCodeForNumber(number)
      if(!phoneUtil.isValidNumberForRegion(number,selectedEUCountryCode)) {
        return {isNotPhoneNumber: true}
      }
    } catch (e) {
      return {isNotPhoneNumber: true}
    }
    return null;
  }

  findInvalidControls(formGroup: FormGroup) {
    const invalid = [];
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }


  configureStylingPathForProfileAvatar(avatarId: any): string {
    if(avatarId){
      return 'url("../assets/img/profileAvatars/' + avatarId.toString() + '.png");'
    }
    else {
      return 'linear-gradient(to bottom, #fff, #fff);'
    }
  }

  safeRouteToUrl(route: string){
    // the commented out code resulted in this issue: https://app.asana.com/0/home/1203284016370920/1204822927795391
    // (one PageView too much; not only the new Page is fired but also a PageViewEvent for the old page.
/*    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([route]))*/
    this.router.navigateByUrl(route);
  }


  //This functions are there to handle iPhone scroll bugs without a big overhead - like a MutationObserver
  registerDialogChange(): Observable<boolean>{
    return this.dialogChange$;
  }
  reportDialogChange(dialogIsOpen: boolean){
    this.dialogStateChanged.next(dialogIsOpen)
  }
}

export function openIcsFileFromSeminar(address: string, date: Date) {
  const m = "BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Our Company//NONSGML v1.0//EN\nBEGIN:VEVENT\nUID:me@google.com\nDTSTAMP:20120315T170000Z\nATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com\nORGANIZER;CN=Me:MAILTO::me@gmail.com\nDTSTART:" + date + "\nDTEND:" + date + "\nLOCATION:" + address + "\nSUMMARY:Our Meeting Office\nEND:VEVENT\nEND:VCALENDAR";
  window.location.assign("data:text/calendar;charset=utf8," + escape(m));
}

export function buildContainerImageUrl(ref: ElementRef, containerId: number) {
  let height = 270
  let width = 250
  if(ref) {
    height =  ref.nativeElement.clientHeight
    width =ref.nativeElement.clientWidth
  }
  return environment.cmsApiUrl + '/images/containers/' + containerId + '?height=' + height + '&width=' + width
  // return environment.cmsApiUrl + '/images/containers/' + containerId + '?height=' + ref.nativeElement.clientHeight + '&width=' + ref.nativeElement.clientWidth
}

export function buildImageUrl( containerId: number) {
  return environment.cmsApiUrl + '/images/containers/' + containerId
}


export async function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function deepclone(object: any): any {
  return JSON.parse(JSON.stringify(object));
}

export function daysBetween(earlierDate: Date, laterDate: Date): number {
  return Math.floor(Math.abs(laterDate.getTime() - earlierDate.getTime()) / (1000 * 3600 * 24));
}

export function dateFromISO8601(isoDateString) {
  var parts = isoDateString.match(/\d+/g);
  var isoDate = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
  return isoDate;
}
