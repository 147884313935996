import { Injectable } from '@angular/core';
import {
  EnterPromotionContactData,
  EnterPromotionData,
  JsonWrappedValue,
  PublicSeminarData
} from "../../generated/cronos/data";
import {PromotionApiResourceV2} from "../../generated/cronos/resources";
import {GlobalCheckoutService} from "../global-checkout.service";
import {environment} from "../../../environments/environment";
import {GDPRConsentService} from "../../gdprconsent/gdprconsent.service";
import {Router} from "@angular/router";
import {LocalStorageService, ngxLocalStorage} from "../../local-storage.service";
import {InternalCookieService, SeminarRegistrationCookieData} from "../../internal-cookie.service";

@Injectable({
  providedIn: 'root'
})
export class SeminarCheckoutService {

  private selectedSeminar: PublicSeminarData;
  waitingList = false;

  constructor(private localStorageService: LocalStorageService, private promotionApiV2Resource: PromotionApiResourceV2, private globalCheckoutService: GlobalCheckoutService, private router: Router, private internalCookieService: InternalCookieService) {
    this.selectedSeminar = localStorageService.getSelectedSeminar()
  }

  getSeminar(){
    return this.selectedSeminar
  }

  setSeminar(seminar: PublicSeminarData){
    this.selectedSeminar = seminar;
    this.waitingList = false;
    this.localStorageService.setSelectedSeminar(seminar)
  }

  clearSelectedSeminar() {
    this.setSeminar(<PublicSeminarData>{})
  }

  completeCheckout(hidSplit: string) {
   let data = this.globalCheckoutService.buildEnterPromotionData()

    const currentPage = this.globalCheckoutService.getCurrentPage()

    let thankYouQueryParams = {
      pid: currentPage.pageId,
      sid: this.selectedSeminar.id,
      fid: currentPage.cronosFormatId,
      waitingListSignup: this.waitingList.toString()
    }

    return this.promotionApiV2Resource.enterSeminarWithNewContactData(
      data, this.selectedSeminar.id
    ).then(cookieIdentifier=> {
      this.globalCheckoutService.reportTrackingEvent('CheckoutCompleted', {waitingList: this.waitingList.toString(), hidSplit: hidSplit});
      this.internalCookieService.storeSeminarCookieIdentifier(this.selectedSeminar.id, cookieIdentifier.value)
      this.router.navigate(['thank-you'],{queryParams: thankYouQueryParams})
    })
  }

}
