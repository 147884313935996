import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {PublicOfferPagePreviewData} from "../../generated/cms/data";
import {MediaService} from "../../inno-utils/media.service";

@Component({
  selector: 'app-offer-page-panel-bafoeg-calculator',
  templateUrl: './offer-page-panel-bafoeg-calculator.component.html',
  styleUrls: ['./offer-page-panel-bafoeg-calculator.component.scss']
})
export class OfferPagePanelBafoegCalculatorComponent implements OnInit {

  @ViewChild("image", { static: true }) image: ElementRef;

  page: PublicOfferPagePreviewData = <PublicOfferPagePreviewData>{averageFeedbackRating: 5.0};
  loading: boolean;
  altText ='Titelbild für Angebotsseite';
  amountOfRatings = 0;
  rating = 0.0;


  constructor(
    public mediaService: MediaService,
    @Inject("env") private env
  ) {
  }

  ngOnInit() {
  }
}
