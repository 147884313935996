import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EmailConfirmDialogCloseData, EmailConfirmDialogOpenData} from './email-confirm.data';

@Component({
  selector: 'app-confirm-email-dialog',
  templateUrl: './confirm-email-dialog.component.html',
  styleUrls: ['./confirm-email-dialog.component.scss']
})
export class ConfirmEmailDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ConfirmEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmailConfirmDialogOpenData
  ) {
  }

  ngOnInit(): void {
  }

  close(resendEmail: boolean) {
    let emailConfirmData: EmailConfirmDialogCloseData = {resendEmail: resendEmail};
    this.dialogRef.close(emailConfirmData);
  }

}
