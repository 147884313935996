import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-mlp-service-form-redirect-page',
  templateUrl: './mlp-service-form-redirect-page.component.html',
  styleUrls: ['./mlp-service-form-redirect-page.component.scss']
})
export class MlpServiceFormRedirectPageComponent implements OnInit {

  constructor() {
  }

  counter: number = 15;

  ngOnInit(): void {
    this.countdown()
  }

  countdown() {
    // this.counter = 30;
// Update the count down every 1 second
    setInterval(() => {
      if (this.counter > 0) {
        this.counter--
      } else {
        this.redirectToMLP()
      }
    }, 1000)


  }

  redirectToMLP() {
    window.location.href = "https://mlp.de/bewertungsformulare/studenten/"
  }
}
