import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.StudentSessionController
 */

@Injectable()
@ResourceParams({})
export class StudentSessionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/handshake',
        endpoint: 'cms'
    })
    private _performSessionHandshake: IResourceMethodObservableStrict<void, {}, {}, void>;
    performSessionHandshake(): Promise<void> {
        return this._performSessionHandshake(null, null, null).toPromise()
    }

}