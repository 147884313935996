import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {PublicBlogPostData, PublicBlogPostPreviewData, PublicOfferPagePreviewData} from "../../generated/cms/data";
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {ActivatedRoute, Router} from "@angular/router";
import {buildContainerImageUrl} from "../../utils.service";

@Component({
  selector: 'app-blog-post-overview-panel',
  templateUrl: './blog-post-overview-panel.component.html',
  styleUrls: ['./blog-post-overview-panel.component.scss']
})
export class BlogPostOverviewPanelComponent implements OnInit {

  @Input() post: PublicBlogPostPreviewData;

  //can use via input or also internally in theory
  @Input() loading;

  @ViewChild("image", { static: true }) image: ElementRef;

  imageSrc = '';

  altText = '';

  constructor(
    private offerPageResource: PublicOfferPageResource,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject("env") private env
  ) {
  }

  ngOnInit() {
    if(this.loading == null) this.loading = false;
    if(!this.post || !this.post.thumbnailId) return;
    this.imageSrc = buildContainerImageUrl(this.image, this.post.thumbnailId)
    this.altText = this.post.altText
  }

  generateHrefValue() :string{
    //only redirect if valid url is provided
    if(this.post.redirectUrl && this.post.redirectUrl.match('(https?://)?([\\da-z.-]+)\\.([a-z.]+)[/\\w .-]*/?') != null) {
     return this.post.redirectUrl
    } else {
      return window.location.origin + '/blog/' + this.post.routingUrl
    }
  }



}
