<div class="wrapper">
  <div fxLayout="column" fxLayoutAlign="end center" style="max-width: 500px;">
    <h2>Servicebogen abgeschickt!</h2>
    <div id="header" >
    Danke, dass du dir die Zeit genommen hast.<br>
      Unser Partner MLP würde gerne noch dein <strong>anonymes Feedback</strong> zum Webinar erfahren.<br>
      Dazu leiten wir dich in Kürze auf die Homepage von MLP weiter.
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="counter w-100 isu-padding-4x" style="margin-top:20px">
      <span>Weiterleitung in</span>
      <span>{{counter}}</span>
      <div fxLayout="row" fxLayoutAlign="space-around start" class="w-100">  <a routerLink="home" class="text-red">Abbrechen</a><a href="https://mlp.de/bewertungsformulare/studenten/" target="_self">Sofort weiterleiten</a>

      </div>
    </div>
  </div>
</div>
