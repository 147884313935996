<div class="content-wrapper">
  <!-- cleaner solution with img tag for alt tag -->
  <!--
  <h1 #image [ngStyle]="{'background-image': 'url(' + imageSrc + ')'}" id="blog-post-header" fxLayout="row"
       fxLayoutAlign="center center">
    {{post.title}}
  </h1>
  -->
  <div style="display: flex; justify-content: center; align-items: center">
    <img #image [src]="imageSrc" alt="{{altText}}"/>
    <h1 class="blog-post-header">{{post.title}}</h1>
  </div>

  <div class="mobile-only">
    <div class="sharebar w-100" fxLayout="row" fxLayoutAlign="center center">

      <div fxLayout="row">
        <button class="hid-button secondary inverse" (click)="initShare()">Angebot teilen <i class="fa fa-share-alt"></i></button>
      </div>


      <!-- Separate Share Icons
      <a href="https://wa.me/?text={{shareText}}{{encodedShareLink}}" data-action="share/whatsapp/share"
         class="icon" id="wa"></a>
      <a href="https://telegram.me/share/url?url= {{shareText}}&text={{encodedShareLink}}" class="icon"
         id="te" ></a>
      <a href="https://www.facebook.com/sharer/sharer.php?u={{shareLink}}" class="icon"
         id="fb" ></a>
      <a
        href="https://www.linkedin.com/sharing/share-offsite/?url={{shareLink}}"
        target="_blank" rel="noopener" class="icon"
        id="li" ></a>

        -->


      <!-- Decommissioned Linkedin Share
            <a
          href="https://www.linkedin.com/shareArticle?mini=true&amp;
          url=[{{shareLink}}]&amp;
          title=[Cooler Artikel]&amp;
          summary=[{{shareText}}]&amp;
          source=[uniwunder]" target="_blank" rel="noopener" class="icon"
          id="li" ></a>

          <a href="https://www.linkedin.com/shareArticle?mini=true&url=http%3A//{{shareLink}}&title={{'Cooler Artikel'}}&summary={{shareText}}&source=hochschul-initiative-deutschland.com"><img src="https://yourdomain.com/images/linkedin_icon.png" border="0"
          /></a>
      -->

      <!--
      <a href="https://twitter.com/intent/tweet?text={{'Cooler Artikel von Hochschulinitiative Deutschland: '}}&url={{shareLink}}" class="icon"
         id="twitter" ></a>
      <a id="mail" class="icon" href="mailto:?subject=Cooles Angebot für dich?&body={{shareText}} {{shareLink}}"></a>
      <a (click)="sharelinkToClipBoard()" class="icon" id="clipboard"></a>
      -->

    </div>
  </div>

  <div fxLayout="row" *ngIf="!loading">

    <div id="blog-page-content" [innerHTML]="post.content | safeHtml"></div>


    <div fxLayout="column" class="sidebar">
      <app-blog-post-sidebar [post]="post"></app-blog-post-sidebar>
    </div>
  </div>

  <div *ngIf="post.categoryIds &&  post.categoryIds.length > 0">
    <h3 class="w-100">Mehr Stories</h3>
    <hid-loading-spinner [loadingBool]="loading">
      <app-blog-post-grid [excludePostId]="post.id" *ngIf="!loading"
                          [categoryIds]="post.categoryIds"></app-blog-post-grid>
    </hid-loading-spinner>
  </div>
</div>
