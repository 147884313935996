<mat-dialog-content id="email-confirm-content">
  <button class="close-icon" (click)="close(false)">
    <i class="mdi mdi-close"></i>
  </button>

  <h2 id="email-confirm">Prüfe deine Email!</h2>
  <p class="text-grey">{{data.email}}</p>

  <p style="margin: 1rem 0 1.5rem 0;" class="text-black">
    Wir haben dir eine E-Mail geschickt, wie du dein Konto einrichten kannst. Schau mal in deinen Posteingang und leg
    los mit deinem Konto.
  </p>


  <p class="text-grey">Keine Email bekommen?</p>
  <button id="email-resend" (click)="close(false)">Schickt mir eine weitere E-Mail</button>
</mat-dialog-content>
