import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RouterModule, Routes} from '@angular/router';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PromotionApiResourceV2, PublicStructureResourceV2} from '../generated/cronos/resources';
import {GlobalModule} from '../global/global.module';
import {ShareModule} from '../share/share.module';
import {ShareOverlayComponent} from '../share/share-overlay/share-overlay.component';
import {HidInteractionsModule} from '../hid-interactions/hid-interactions.module';
import {OfferpageDeactivatedComponent} from './offerpage-deactivated/offerpage-deactivated.component';
import {OfferPagePanelModule} from '../offer-page-panel/offer-page-panel.module';


const appRoutes: Routes = [
  {path: 'angebote/weitere/:id', component: OfferpageDeactivatedComponent},
];

@NgModule({
  declarations: [OfferpageDeactivatedComponent],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    FlexModule,
    HidInteractionsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    GlobalModule,
    OfferPagePanelModule
  ], exports: [
    RouterModule
  ],
  entryComponents: [
    ShareOverlayComponent
  ],
  providers: [
    PromotionApiResourceV2,
    PublicStructureResourceV2
  ]
})
export class OfferpageDeactivatedModule {
}
