import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StaCheckoutS1EntryComponent} from './sta-checkout-s1-entry/sta-checkout-s1-entry.component';
import {StaCheckoutS1UserComponent} from './sta-checkout-s1-user/sta-checkout-s1-user.component';
import {StaCheckoutS1LocationComponent} from './sta-checkout-s1-location/sta-checkout-s1-location.component';
import {StaCheckoutS1MainComponent} from './sta-checkout-s1-main/sta-checkout-s1-main.component';
import {RouterModule, Routes} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {HidInteractionsModule} from '../../../hid-interactions/hid-interactions.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ShareModule} from '../../../share/share.module';
import {GlobalModule} from '../../../global/global.module';
import {NgxJsonLdModule} from 'ngx-json-ld';
import {ActionLinkModule} from '../../../actionLinks/action-link/action-link.module';
import {FeedbackStarsModule} from '../../../feedback-stars/feedback-stars.module';
import {AccountModule} from '../../../account/account.module';
import {DiscountsModule} from '../../../discounts/discounts.module';
import {MatButtonModule} from '@angular/material/button';
import {GlobalCheckoutComponentsModule} from '../../components/global-checkout-components/global-checkout-components.module';


const appRoutes: Routes = [
  {path: 'angebote/:routingUrl/checkout/standard/s1/entry', component: StaCheckoutS1EntryComponent},
  {
    path: 'angebote/:routingUrl/checkout/standard/s1', component: StaCheckoutS1MainComponent,
    children: [
      {path: 'location', component: StaCheckoutS1LocationComponent},
      {path: 'user', component: StaCheckoutS1UserComponent},
    ]
  }];

@NgModule({
  declarations: [StaCheckoutS1EntryComponent, StaCheckoutS1UserComponent, StaCheckoutS1LocationComponent, StaCheckoutS1MainComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    BrowserModule,
    FlexModule,
    HidInteractionsModule,
    MatPaginatorModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    GlobalModule,
    NgxJsonLdModule,
    ActionLinkModule,
    FeedbackStarsModule,
    AccountModule,
    DiscountsModule,
    MatButtonModule,
    GlobalCheckoutComponentsModule,
  ]
})
export class StandardCheckoutS1Module { }
