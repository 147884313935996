import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {HomePageConfigData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.HomePageConfigController
 */

@Injectable()
@ResourceParams({})
export class HomePageConfigResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/pageconfigs/home',
        endpoint: 'cms'
    })
    private _getHomePageConfig: IResourceMethodObservableStrict<void, {}, {}, HomePageConfigData>;
    getHomePageConfig(): Promise<HomePageConfigData> {
        return this._getHomePageConfig(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/pageconfigs/home',
        endpoint: 'cms'
    })
    private _updateHomePageConfig: IResourceMethodObservableStrict<HomePageConfigData, {}, {}, void>;
    updateHomePageConfig(requestBody: HomePageConfigData): Promise<void> {
        return this._updateHomePageConfig(requestBody, null, null).toPromise()
    }

}