<div class="avatar-dialog-main-wrapper" fxLayout="column" fxLayoutAlign="start center">
  <h1>Wähle einen Avatar für dein Profil</h1>
  <i class="close-icon mdi mdi-close" (click)="close()"></i>
  <div class="avatar-container" fxLayout="row no-wrap" fxLayoutAlign="center start">
    <div *ngFor="let avatar of avatarList"
         class="avatar-icon"
         [ngStyle]="{'background-image': utilService.configureStylingPathForProfileAvatar(avatar)}"
         (click)="changeAvatarId(avatar)"
         [ngClass]="{'chosen-avatar': (avatar == this.profileInfo.avatarId && !this.avatarChanged) || avatar == this.chosenAvatar}"
    >
    </div>
  </div>
  <button mat-flat-button class="primary-qa-button" (click)="saveAndExit()">Avatar speichern</button>
</div>
