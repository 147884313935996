
<div *ngIf="!initialized && mediaService.getMaxWidthQuery(800).matches"
     fxLayout="row" fxLayoutAlign="start center" class="scroll">
  <div fxLayout="row" fxLayoutAlign="start center" class="filter">
  <ng-container *ngFor="let i of loadingSkeletonArray" class="bubble">
    <div class="loading-container">
      <div class="loading-content"></div>
    </div>
  </ng-container>
  </div>
</div>

<div *ngIf="initialized && mediaService.getMaxWidthQuery(800).matches" class="scroll">
  <div fxLayout="row" fxLayoutAlign="start center" class="filter" >
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.All ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks">
    <div fxHide.lt-sm class="img allImg" role="img" alt="Logo Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Alle - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.All).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.All).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Steuer ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks/{{SeminarUrlIdentifier.Steuer}}">
    <div fxHide.lt-sm class="img steuerImg" role="img" alt="Steuerseminar Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Steuer - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Steuer).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Steuer).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Thesis ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks/{{SeminarUrlIdentifier.Thesis}}">
    <div fxHide.lt-sm class="img thesisImg" role="img" alt="Thesis-Seminar Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Thesis - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Thesis).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Thesis).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Excel ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks/{{SeminarUrlIdentifier.Excel}}">
    <div fxHide.lt-sm class="img excelImg" role="img" alt="Excel-Kurs Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Excel - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Excel).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Excel).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>

  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Finance ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks/{{SeminarUrlIdentifier.Finance}}">
    <div fxHide.lt-sm class="img financeImg" role="img" alt="Finanz-Kurs Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Finanzen - <i
        class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Finance).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Finance).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Fin4Women ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks/{{SeminarUrlIdentifier.Fin4Women}}">
    <div fxHide.lt-sm class="img fin4womenImg" role="img" alt="Finanz-Kurs für Frauen Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Finanzen für Studentinnen - <i
        class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Fin4Women).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Fin4Women).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
    </div>
</div>
<!-- DESKTOP View-->
<!-- Loading Skeleton -->
<div *ngIf="!initialized && !mediaService.getMaxWidthQuery(800).matches" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="15px" style="row-gap: 15px">
  <ng-container *ngFor="let i of loadingSkeletonArray">
    <div class="loading-container">
      <div class="loading-content"></div>
    </div>
  </ng-container>
  <div class="loading-container" style="width: 350px">
    <div class="loading-content"></div>
  </div>
</div>
<!-- loaded seminar selectors -->
<div *ngIf="initialized && !mediaService.getMaxWidthQuery(800).matches"
     fxLayout="row wrap" fxLayoutAlign="start center" class="filter" fxLayoutGap="15px">
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.All ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks">
    <div fxHide.lt-sm class="img allImg" role="img" alt="Logo Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Alle - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.All).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.All).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Steuer ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks/{{SeminarUrlIdentifier.Steuer}}">
    <div fxHide.lt-sm class="img steuerImg" role="img" alt="Steuerseminar Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Steuer - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Steuer).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Steuer).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
       [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Thesis ? 'lightgrey' : 'white' }"
  routerLink="/feedbacks/{{SeminarUrlIdentifier.Thesis}}">
    <div fxHide.lt-sm class="img thesisImg" role="img" alt="Thesis-Seminar Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Thesis - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Thesis).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Thesis).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
       [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Excel ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks/{{SeminarUrlIdentifier.Excel}}">
    <div fxHide.lt-sm class="img excelImg" role="img" alt="Excel-Kurs Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Excel - <i class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Excel).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Excel).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>

  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Finance ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks/{{SeminarUrlIdentifier.Finance}}">
    <div fxHide.lt-sm class="img financeImg" role="img" alt="Finanz-Kurs Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Finanzen - <i
        class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Finance).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Finance).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
  <a fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="bubble"
     [ngStyle]="{'background-color': this.selectedSeminar == SeminarIdentifiers.Fin4Women ? 'lightgrey' : 'white' }"
     routerLink="/feedbacks/{{SeminarUrlIdentifier.Fin4Women}}">
    <div fxHide.lt-sm class="img fin4womenImg" role="img" alt="Finanz-Kurs für Frauen Hochschulinitiative Deutschland"></div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <span>Finanzen für Studentinnen - <i
        class="fa fa-star star"></i> {{getGroup(SeminarIdentifiers.Fin4Women).getAverageRating()}}</span>
      <div class="text-grey feedbackcount">{{getGroup(SeminarIdentifiers.Fin4Women).getCompactCount()}} Erfahrungen</div>
    </div>
  </a>
</div>
