import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StaticPageComponent} from './static-page/static-page.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {RouterModule, Routes} from "@angular/router";
import {PublicStaticPageResource} from "../generated/cms/resources";
import {GlobalModule} from "../global/global.module";
import {ImprintComponent} from './imprint/imprint.component';
import {AboutComponent} from './about/about.component';
import {ConditionsOfParticipationComponent} from './conditions-of-participation/conditions-of-participation.component';
import {LegalAPIResourceV2} from "../generated/cronos/resources";
import {FlexLayoutModule} from "@angular/flex-layout";
import {FeedbackOverviewComponent} from "../feedback-overview/feedback-overview.component";
import { SupportInfoComponent } from './support-info/support-info.component';

const appRoutes: Routes = [
  {path: 'static/:identifier', component: StaticPageComponent},
  {path: 'teilnahmebedingungen/:identifier', component: ConditionsOfParticipationComponent},
  {path: 'feedbacks', component: FeedbackOverviewComponent},
  {path: 'ueber-uns', component: AboutComponent},
  {path: 'impressum', component: ImprintComponent},
  {path: 'datenschutz', component: PrivacyComponent},
  {path: 'support-info', component: SupportInfoComponent},
  {path: 'datenschutz/:cronosFormatId', component: PrivacyComponent}
];

@NgModule({
  declarations: [StaticPageComponent, PrivacyComponent, ImprintComponent, AboutComponent, ConditionsOfParticipationComponent, SupportInfoComponent],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    GlobalModule,
    FlexLayoutModule
  ], exports: [
    RouterModule,
    PrivacyComponent
  ],
  providers: [
    PublicStaticPageResource,
    LegalAPIResourceV2
  ]
})
export class StaticModule { }
