<div class="studyEndControl">
  <div class="label">Voraussichtliches Studienende</div>
  <div fxLayout="row" fxLayout.xs="column">
    <div fxLayout="row wrap" fxLayoutAlign="space-around center" fxFlex.xs="100" fxFlex.gt-xs="50">
      <button class="yearButton secondary inverse outline"
              [ngClass]="{'active': control.value == year}"
              (click)="control.setValue(year)"
              *ngFor="let year of studyEndOptions.slice(0,3)">{{year}}</button>

    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="second-buttonrow" fxFlex.xs="100"
         fxFlex.gt-xs="50">
      <button class="yearButton secondary inverse outline"
              [ngClass]="{'active': control.value == year}"
              (click)="control.setValue(year)"
              *ngFor="let year of studyEndOptions.slice(3,6)">{{year}}</button>
    </div>

  </div>
</div>
