<div id="dialog-main-wrapper" fxLayout="column" fxLayoutAlign="start center">

  <h1>Du warst beim Seminar?</h1>

  <div id="seminar-data" fxLayout="column" fxLayoutAlign="center center">
    <div>{{data.registrationInfo.promotionData.formatTitle}}</div>
    <div>{{data.registrationInfo.promotionData?.location}}, {{data.registrationInfo.promotionData?.address}}</div>
    <div>{{data.registrationInfo.promotionData?.date | date: 'dd.MM.yyyy, HH:mm'}}</div>
  </div>

  <div class="information">Bitte bestätige deine Teilnahme nur, wenn du wirklich anwesend warst.</div>

  <div id="action-box" fxLayout="row" fxLayoutAlign="center center">
    <button class="hid-button secondary inverse" (click)="closeDialog()">Abbrechen</button>
    <button [disabled]="buttonClicked" class="hid-button primary" (click)="confirmStudentWasPresent()">Teilnahme
      bestätigen
    </button>
  </div>

</div>
