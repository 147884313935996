import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-schlittschuh-slider',
  templateUrl: './schlittschuh-slider.component.html',
  styleUrls: ['./schlittschuh-slider.component.scss']
})
export class SchlittschuhSliderComponent implements OnInit {

  @Input() isActivated: boolean = false;
  @Output() valueChanged = new EventEmitter<boolean>();

  isChecked: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleSlider() {
    this.isChecked = !this.isChecked;
    this.valueChanged.emit(this.isChecked);
  }

}
