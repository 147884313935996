import { Component, OnInit } from '@angular/core';
import {ContactSupportModalComponent} from "../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-support-info',
  templateUrl: './support-info.component.html',
  styleUrls: ['./support-info.component.scss']
})
export class SupportInfoComponent implements OnInit {

  constructor(
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }
}
