import { Injectable } from '@angular/core';
import {UserOfflineDialogComponent} from "./inno-utils/dialogs/user-offline-dialog/user-offline-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class UserOfflineService {

  userOffline: boolean = false;

  //Checks if dialog is already open
  dialogCurrentlyOpen = false;

  constructor(private matDialog: MatDialog) { }

  notifyUserOffline() {
    if(this.userOffline == false) {
      let dialogRef
      //if statement avoids multiple instances of this dialog
      if(this.dialogCurrentlyOpen == false){
        this.dialogCurrentlyOpen = true
        dialogRef = this.matDialog.open(UserOfflineDialogComponent)
        dialogRef.afterClosed().subscribe(result => {
          this.dialogCurrentlyOpen = result;
        });
      }

    } else return

    // dialogRef.afterClosed().subscribe()
  }
}
