import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-feedback-stars',
  templateUrl: './feedback-stars.component.html',
  styleUrls: ['./feedback-stars.component.scss']
})
export class FeedbackStarsComponent implements OnInit {

  @Input()rating:number;

  @Input()scaleDownOnMobile: boolean = false;


  roundedRating;

  constructor() { }

  ngOnInit() {
    if(this.rating == 4.3) {
    }
    this.roundedRating =  Math.round(this.rating * 2) / 2
  }

}
