import {Component, Inject, OnInit} from '@angular/core';
import {OnlineLectureInfoData} from "../../generated/cronos/data";
import {ActivatedRoute, Router} from "@angular/router";
import {MediaService} from "../../media.service";
import {
  OnlineLectureAPIResourceV2,
  PromotionApiResourceV2,
  SeminarProcessAPIResourceV2
} from "../../generated/cronos/resources";
import {ShareService} from "../../share/share.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MatDialog} from "@angular/material/dialog";
import {LocalStorageService} from "../../local-storage.service";
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {UtilsService} from "../../utils.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {MobileDatepickerDialogComponent} from "./mobile-datepicker-dialog/mobile-datepicker-dialog.component";
import {OnlineLectureService} from "../online-lecture.service";

@Component({
  selector: 'app-online-lecture-appointment',
  templateUrl: './online-lecture-appointment.component.html',
  styleUrls: ['./online-lecture-appointment.component.scss']
})
export class OnlineLectureAppointmentComponent implements OnInit {


  format: OnlineLectureInfoData;
  recommendedUpsellPageIds;
  offerPageId;
  offerPageUrl;

  date = null;
  time;
  form: FormGroup;

  numberOfSteps: number = 0;
  currentStep: number = 0;

  loading: Boolean = false;

  showSummaryStep: Boolean = false;

  token: string;
  cronosFormatId;
  lessonId: string;

  dialogOpen = false;

  constructor(
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private onlineLectureAPIResourceV2: OnlineLectureAPIResourceV2,
    private shareService: ShareService,
    private router: Router,
    private modal: NgbModal,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private publicOfferPageResource: PublicOfferPageResource,
    private utilsService: UtilsService,
    @Inject("env") private env,
    private fb: FormBuilder,
    private snackBar: HidSnackbarService,
    public onlineLectureService: OnlineLectureService,
  ) {
  }

  ngOnInit() {
    this.cronosFormatId = (this.route.snapshot.paramMap.get("cronosFormatId"));
    this.lessonId = (this.route.snapshot.paramMap.get("lessonId"));
    this.token = this.onlineLectureService.getToken(this.cronosFormatId);

    this.form = this.onlineLectureService.initializeAppointmentDate(this.form);

    this.date = this.onlineLectureService.date;
    this.showSummaryStep = this.onlineLectureService.showSummaryStep;

    this.load();
  }

  load() {
    this.loading = true;
    this.token = this.localStorageService.getLectureAuthToken();

    this.onlineLectureService.loadFormat(this.cronosFormatId).then(format => {
      this.format = format;
      this.numberOfSteps = this.onlineLectureService.numberOfSteps;
    });

    this.onlineLectureService.loadOfferPage(this.cronosFormatId).then(offerPage => {
      this.offerPageUrl = offerPage.offerPageUrl;
      this.offerPageId = offerPage.offerPageId;
      this.recommendedUpsellPageIds = offerPage.upSellIds;
      this.loading = false;
    });
  }

  openDatePicker() {
    this.dialogOpen = true;
    //this.onlineLectureService.openMobileDatePicker(this.form).then( result => {
    //       if (result) this.dialogOpen = false;
    //     })
    let dialogRef = this.dialog.open(MobileDatepickerDialogComponent, {
      data: {
        date: this.form.controls['date'].value,
        hour: this.form.controls['hour'].value,
        minutes: this.form.controls['minute'].value,
      },
      panelClass: 'fullscreen-dialog',
      width: '100%',
      height: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogOpen = false;
      if (result) {
        this.form.controls['date'].setValue(result.date);
        this.form.controls['hour'].setValue(result.hour);
        this.form.controls['minute'].setValue(result.minutes);
        this.onlineLectureService.setAppointment(this.form, true);
      }
    })
  }

  getArray(length) {
    return new Array(length)
  }

  updateFormDate(value) {
    this.form.get('date').setValue(value)
  }

  backToLessons() {
    let previousLessonId = this.format.lessons[this.format.lessons.length - 1].id;
    this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/lesson/' + previousLessonId);
  }

  goToStep(i) {
    this.currentStep = i;
    let lessonIdToGoTo = this.format.lessons[i].id;
    if (i == this.numberOfSteps) {
      this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/summary');
    } else {
      this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/lesson/' + lessonIdToGoTo);
    }
  }

  goToAppointment() {
    this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/appointment');
  }

  goToSummaryAndShowSnackbar() {
    this.snackBar.openSnackBar("Wir fragen dich später nochmal", "Ok", 5000, false);
    this.goToSummary()
  }

  goToSummary() {
    this.onlineLectureAPIResourceV2.finishOnlineLecture(this.token).then(() => {
      this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/summary');
    });
  }

  initShare() {
    //offerpage.routingUrl
    this.shareService.showSharingButtons('/angebote/' + this.offerPageUrl, 'offerpage-share', 'Hey, ich habe ein cooles Angebot auf HID für dich: ', null);
  }
}
