import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {MediaService} from "../../inno-utils/media.service";

let nextid = 1;

@Component({
  selector: 'hid-input-field',
  templateUrl: './hid-input-field.component.html',
  styleUrls: ['./hid-input-field.component.scss']
})

export class HidInputFieldComponent implements OnInit, AfterViewInit {

  @Input() showValidatorIcons: boolean;
  @Input() label: string;
  @Input() model: any;
  @Input() type: string;
  @Input() control: AbstractControl;
  @Input() disabled: boolean;
  @Input() lock: boolean;
  @Input() name: string;
  @Input() inputFieldClass: string = '';
  @Input() forceValidation: BehaviorSubject<boolean>;
  @Input() mask: string;
  @Input() readonly : boolean = false;
  @Input() step: string;
  @Input() pattern: string;
  @Input() hideLabelWhenFilled: boolean = false;
  @Input() isTelephonySplit: boolean = false;

  @Input() autoFocus: boolean = false;

  @Input() validationPending: boolean = false;
  validationForced;

  show = true;

  dynamicId = "hid-input-" + nextid++;
  required: boolean = false;


  isPassword = false;

@ViewChild("scrollTarget")scrollTarget: ElementRef
  @ViewChild("input")inputElement: ElementRef

  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() inputFocused: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<null> = new EventEmitter();


  constructor(private el: ElementRef, private mediaService: MediaService) {
  }

  ngOnInit() {

    if (!this.mask) this.mask = '';
    if (!this.control) {
      this.control = new FormControl()
      this.control.setValue(this.model)
    }
    this.control.valueChanges.subscribe(n => {
      //if stepped number input, adjust , -> .
      if(this.step) {
        n = this.adjustValue(n)
        //Disadvantage here: adjusted value doesnt get emitted for outside components that observe the form field. in case thats a problem, emit the event end prevent loop with local prevent flag  in htis component
        this.control.setValue(n, {emitEvent: false})
      }
      this.modelChange.emit(n)
    })
    this.required = this.validator();

    if (this.forceValidation) {
      this.forceValidation.subscribe(forced => {
        this.validationForced = forced;
      })
    }

    this.isPassword = this.type === 'password';

  }

  ngAfterViewInit() {
    if(this.autoFocus){
        this.inputElement.nativeElement.focus();
    }
  }

  adjustValue(n: string){
    if(this.step && n && n.length > 0 && n.indexOf(',') > -1){
      n = n.replace(',', '.')
    }
    return n
  }

  validator() {
    if (!this.control.validator) return false
    const validator = this.control.validator({} as AbstractControl);
    if (validator != null && validator['required']) {
      return true;
    }
    return false
  }

  //This is required to react to autofill-functions of browsers. Otherwise formcontrol doesnt get it
  updateControl(ev) {
    this.control.setValue(ev.target.value)
  }

  touched = false;
  reportTouch() {
    if (this.el.nativeElement.className.indexOf(' touched') == -1 && this.model != null && this.model.length > 0) {
      this.el.nativeElement.className += ' touched';
    }
    this.blur.emit();
    this.touched = true;
  }


  focus() {
    this.inputFocused.emit()
  }

  togglePasswordVisibility(){
    if(!this.isPassword)return
    if(this.type === 'password'){
      this.type = 'text'
    }else{
      this.type = 'password';
    }
  }
}
