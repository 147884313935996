<div class="bundle-header" fxShow.gt-md>
  <img class="promotion-image" src="../../../assets/img/bundlebanner.jpg">
  <div class="overlay">
    <div fxLayout="column" fxLayoutAlign="center center">
      <span *ngIf="!loading">
      <h1 class="title" [innerHTML]="currentBundle.title | safeHtml"></h1>
        <span [innerHTML]="currentBundle.descriptionHtml | safeHtml"></span>
      </span>

    </div>
  </div>
</div>
<h1 class="bundle-header-mobile" *ngIf="!loading" [innerHTML]="currentBundle.title | safeHtml"></h1>
<div class="content-wrapper" *ngIf="!loading">
  <!--<loading-spinner [loadingBool]="loading">-->
  <div id="breadcrumb" fxLayout="row" fxLayoutAlign="start center">
    <a class="link-classic" routerLink="/angebote">Angebote</a>
    <span class="text-grey">&nbsp;/&nbsp;</span>
    <div class="bc-dropdown link-classic" (click)="dropdownVisible = !dropdownVisible"
         (clickOutside)="dropdownVisible = false">
      <span [innerHTML]="currentBundle.title | safeHtml"> <i class="fa fa-chevron-down"></i></span>
      <div class="options-wrapper" [ngClass]="{'visible': dropdownVisible}">
        <div class="option" (click)="switchBundle(otherBundle.routingUrl)"
             *ngFor="let otherBundle of bundles" [innerHTML]="otherBundle.title |safeHtml"></div>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="center start">
    <app-offer-page-panel [pageId]="pageId" *ngFor="let pageId of currentBundle.offerPageIds"></app-offer-page-panel>
    <app-offer-page-panel-bafoeg-calculator *ngIf="currentBundle.id == 118" style="margin: 10px"></app-offer-page-panel-bafoeg-calculator>
  </div>
  <!--</loading-spinner>-->
</div>

<span class="bundle-description-mobile" *ngIf="!loading && currentBundle.descriptionHtml?.length > 0"
      [innerHTML]="currentBundle.descriptionHtml | safeHtml"></span>
