import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-success-bar',
  templateUrl: './success-bar.component.html',
  styleUrls: ['./success-bar.component.scss']
})
export class SuccessBarComponent implements OnInit {

  @Input() totalSteps: number = 3
  @Input() currentStep: number = 0

  steps: any[] = Array(this.totalSteps)
  previusStep: number

  constructor() { }

  ngOnInit(): void {
    this.previusStep = this.currentStep
  }



}
