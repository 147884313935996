<div fxLayout="column" style="position: relative" id="location-list">
  <hid-loading-spinner [loadingBool]="loading">
    <div fxLayout="row" fxLayoutAlign="center center">
      <button class="hid-button secondary inverse "
              *ngIf="locationCalculationState == 'Uninitialized'"
              (click)="getLocationAndCalculate()"
              style="margin: 0 auto"><i class="fa fa-map-marker"></i> Städte in meiner Nähe finden
      </button>
      <div id="geo-not-working"
           *ngIf="locationCalculationState == 'UnknownError'"
           [ngClass]="{'hidden': frownAnimationFinished}">
        <i class="far fa-frown"></i>
        Wir konnten dich nicht orten
      </div>
      <div id="geo-blocked"
           *ngIf="locationCalculationState == 'PermissionDenied'"
           >
        <i class="far fa-frown"></i>
        Standortermittlung vom Gerät blockiert
      </div>
    </div>
  </hid-loading-spinner>
</div>
