<div class="print-dialog-wrapper">
  <h1>Ich möchte meine...</h1>
  <div class="print-options">
    <div class="option-card" (click)="routeToConfig('Thesis')">
      <h1>🎓</h1>
      <p>
        Abschlussarbeit <br *ngIf="!mediaService.isMobile()">
        drucken
      </p>
    </div>
    <div class="option-card" (click)="routeToConfig('Script')">
      <h1>📑</h1>
      <p>
        Skripte <br *ngIf="!mediaService.isMobile()">
        drucken
      </p>
    </div>
  </div>

  <button class="close-button" (click)="close()">
    <i class="mdi mdi-close"></i>
  </button>

</div>
