<div fxLayout="column" fxLayoutAlign="start center" >
  <div id="side-bar-header" class="w-100" fxLayout="row" fxLayoutAlign="center center">

    <div fxLayout="row">
      <button class="hid-button secondary inverse" (click)="initShare()">Angebot teilen <i class="fa fa-share-alt"></i></button>
    </div>

    <!-- Decommissioned Separate Share Icons
    <a href="https://wa.me/?text={{shareText}}{{encodedShareLink}}" data-action="share/whatsapp/share"
       class="icon" id="wa"></a>
    <a href="https://telegram.me/share/url?url= {{shareText}}&text={{encodedShareLink}}" class="icon"
       id="te" ></a>
    <a href="https://www.facebook.com/sharer/sharer.php?u={{shareLink}}" class="icon"
       id="fb" ></a>

    <a
    href="https://www.linkedin.com/sharing/share-offsite/?url={{shareLink}}"
     target="_blank" rel="noopener" class="icon"
    id="li" ></a>


    Outdated Linked In
      <a
    href="https://www.linkedin.com/shareArticle?mini=true&amp;
    url=[{{shareLink}}]&amp;
    title=[Cooler Artikel]&amp;
    summary=[{{shareText}}]&amp;
    source=[uniwunder]" target="_blank" rel="noopener" class="icon"
    id="li" ></a>

    <a href="https://www.linkedin.com/shareArticle?mini=true&url=http%3A//{{shareLink}}&title={{'Cooler Artikel'}}&summary={{shareText}}&source=hochschul-initiative-deutschland.com"><img src="https://yourdomain.com/images/linkedin_icon.png" border="0"
    /></a>


      <a href="https://twitter.com/intent/tweet?text={{'Cooler Artikel von Hochschulinitiative Deutschland: '}}&url={{shareLink}}" class="icon"
       id="twitter" ></a>
      <a id="mail" class="icon" href="mailto:?subject=Cooles Angebot für dich?&body={{shareText}} {{shareLink}}"></a>
      <a (click)="sharelinkToClipBoard()" class="icon" id="clipboard"></a>
      -->
  </div>
  <div style="font-size: 20px; margin-top: 10px;">Top-Angebote</div>
  <div class="divider"></div>
  <app-offer-page-panel fxLayout="column "
                        [queryParams]="{
                        utm_source: 'hidpage',
                        utm_medium: 'blogpost',
                        utm_campaign: 'blogbanner-'+offer.value.cronosFormatId,
                        utm_content: 'blog-post-'+post.title
                        }"
                        [externallyProvidedPage]="offer"
                        *ngFor="let offer of offers"></app-offer-page-panel>
</div>
