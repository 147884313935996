
<div class="login-menu-wrapper" fxLayout="column" fxLayoutAlign="center center" (keydown.enter)="login()">

  <h2 *ngIf="showHeader" class="login-header">Anmelden</h2>

  <form>
    <input class="login-input" type="email" placeholder="Email" [formControl]="email"/>
    <input class="login-input" placeholder="Passwort" [formControl]="password" type="password"/>

    <p class="error-message" *ngIf="showBadCredentialError">E-Mail-Adresse oder Passwort falsch</p>

    <div fxLayout="row" style="margin-top: 10px;" class="w-100 center-content" fxLayoutAlign="center center">
      <button style="position: relative" [disabled]="loginInProgress" class="signup-button" (keydown.enter)="login()" (click)="login()">
        <div  *ngIf="loginInProgress" class="lds-dual-ring"></div>
        Einloggen</button>
    </div>

    <div class="w-100 center-content" fxLayoutAlign="center center">
      <button routerLink="/account/register-page" (click)="closeDialog()" mat-flat-button class="secondary-button">
        Registrieren</button>
    </div>

    <h4 style="margin-top: 10px" class="login-hint" (click)="showPasswordHint = true" >Passwort vergessen?</h4>

    <app-password-reset *ngIf="showPasswordHint" ></app-password-reset>
  </form>

</div>
