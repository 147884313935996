<div class="linkhub-container" [ngClass]="'style-' + style">
  <div class="content">
    <!-- Profile Section -->
    <div class="profile-section">
      <img #image class="profile-image" mat-card-image [src]="imageSrc">
      <h1 class="profile-title">{{ linkHubData?.title }}</h1>
      <h2 class="profile-description">{{ linkHubData?.description }}</h2>
    </div>
    <!-- Item Section -->
    <div class="item-section" *ngFor="let item of linkHubData?.items">
      <!-- Link -->
      <div *ngIf="item.type === 'Link'">
        <button class="button" (click)="window.open(item.url, '_blank');">
          <i [class]="item.iconName"></i>
          <span class="text"[innerHTML]="transformLabel(item.label)"></span>
        </button>
      </div>
      <!-- Link Group -->
      <div *ngIf="item.type === 'LinkGroup'">
        <button class="button" (click)="item.isExpanded = !item.isExpanded">
          <i [class]="item.iconName"></i>
          <span class="text" [innerHTML]="transformLabel(item.label)"></span>
          <span class="arrow" [ngClass]="{'down': item.isExpanded, 'right': !item.isExpanded}"></span>
        </button>
        <!-- Nested Link -->
        <div *ngIf="item.isExpanded">
          <div *ngFor="let nestedItem of item.links" class="nested-link">
            <button class="button" (click)="window.open(nestedItem.url, '_blank');">
              <span class="text">{{nestedItem.label}}</span>
            </button>
          </div>
        </div>
      </div>
      <!-- Social Icon -->
      <div *ngIf="item.type === 'SocialIconGroup'">
        <div class="icon-group">
          <!-- Nested Social Icon -->
          <div *ngFor="let nestedItem of item.icons" class="nested-icon">
            <a class="icon" (click)="window.open(nestedItem.url, '_blank');">
              <i [class]="nestedItem.iconName" ></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
