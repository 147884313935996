import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {
  ContactSupportModalComponent
} from "../../contact-support-modal/contact-support-modal/contact-support-modal.component";

@Component({
  selector: 'app-error-page-invalid-link',
  templateUrl: './error-page-invalid-link.component.html',
  styleUrls: ['./error-page-invalid-link.component.sass']
})
export class ErrorPageInvalidLinkComponent implements OnInit {

  constructor(private modal: NgbModal, private router: Router) {
  }

  ngOnInit() {
  }

  supportModal() {
    const modalref = this.modal.open(ContactSupportModalComponent)
    modalref.componentInstance.messagePlaceholder = "Was ist passiert?"
  }

  goHome() {
    this.router.navigate(['/home'], {queryParamsHandling: 'preserve'})
  }
}
