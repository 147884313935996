<div fxLayout="row" class="layout-padding" fxLayoutGap="8px">
  <div class="hint" fxLayout="column">
    <div>Wir veröffentlichen dein Feedback unter Angabe deines Vornamens.
    Wenn du deinen Vornamen lieber verbergen möchtest, klicke <span class="link" (click)="activateCheckBox()">hier</span>.
    </div>
    <div fxLayout="row" *ngIf="anonymizeSelected" class="m-top10">
      <mat-checkbox  [formControl]="anonymizeControl"></mat-checkbox>
      <div style="padding-left: 10px">Vornamen verbergen</div>
    </div>
  </div>
</div>
