import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {MatDialogRef} from "@angular/material/dialog";
import {MediaService} from "../../media.service";
import {AnalyticsService} from "../../analytics/analytics.service";

@Component({
  selector: 'app-studyscript-print-option-dialog',
  templateUrl: './studyscript-print-option-dialog.component.html',
  styleUrls: ['./studyscript-print-option-dialog.component.scss']
})
export class StudyscriptPrintOptionDialogComponent implements OnInit {

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<StudyscriptPrintOptionDialogComponent>,
    public mediaService: MediaService,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
  }

  routeToConfig(type: string) {
    this.dialogRef.close()

    if (type == 'Thesis') this.analyticsService.reportRequestThesisPrint("Dialog")
    else this.analyticsService.reportRequestScriptPrint("Dialog")

    this.router.navigate(["/druckleistungen/konfigurator"], {fragment: type})
  }

  close() {
    this.dialogRef.close()
  }

}
