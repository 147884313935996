import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home/home.component';
import {RouterModule, Routes} from '@angular/router';
import {OfferPagePanelModule} from '../offer-page-panel/offer-page-panel.module';
import {HomePageResource} from '../generated/cms/resources';
import {GlobalModule} from '../global/global.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {OnScrollDirective} from './on-scroll.directive';
import {BundleCarousselComponent} from './bundle-caroussel/bundle-caroussel.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';

const appRoutes: Routes = [
  {path: 'home', component: HomeComponent}
];


@NgModule({
  declarations: [HomeComponent, OnScrollDirective,BundleCarousselComponent],
  imports: [
    RouterModule.forChild(appRoutes),
    CommonModule,
    OfferPagePanelModule,
    FlexModule,
    FlexLayoutModule,
    SlickCarouselModule,

    GlobalModule,
  ],  providers: [HomePageResource],
  exports:[
    RouterModule,
    BundleCarousselComponent
  ]
})
export class HomeModule { }
