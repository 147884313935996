import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, BafoegCalculationData, BafoegEinkommenStudentData, BafoegCompleteFormData, BafoegEinkommenElternData, BafoegEinkommenPartnerData, BafoegGrundbedarfData, BafoegMinimalData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.BafoegCalculationController
 */

@Injectable()
@ResourceParams({})
export class BafoegCalculationResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/bafoeg/documents/new',
        endpoint: 'cronos'
    })
    private _createEmptyDoc: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<string>>;
    createEmptyDoc(): Promise<JsonWrappedValue<string>> {
        return this._createEmptyDoc(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/bafoeg/documents/{id}/parents',
        endpoint: 'cronos'
    })
    private _editEinkommenEltern: IResourceMethodObservableStrict<BafoegEinkommenElternData, {}, {id: string}, void>;
    editEinkommenEltern(requestBody: BafoegEinkommenElternData, id: string): Promise<void> {
        return this._editEinkommenEltern(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/bafoeg/documents/{id}/student',
        endpoint: 'cronos'
    })
    private _editEinkommenStudent: IResourceMethodObservableStrict<BafoegEinkommenStudentData, {}, {id: string}, void>;
    editEinkommenStudent(requestBody: BafoegEinkommenStudentData, id: string): Promise<void> {
        return this._editEinkommenStudent(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/bafoeg/documents/{id}/general',
        endpoint: 'cronos'
    })
    private _editGrundbedarf: IResourceMethodObservableStrict<BafoegGrundbedarfData, {}, {id: string}, void>;
    editGrundbedarf(requestBody: BafoegGrundbedarfData, id: string): Promise<void> {
        return this._editGrundbedarf(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/bafoeg/documents/{id}/partner',
        endpoint: 'cronos'
    })
    private _editPartnerById: IResourceMethodObservableStrict<BafoegEinkommenPartnerData, {}, {id: string}, void>;
    editPartnerById(requestBody: BafoegEinkommenPartnerData, id: string): Promise<void> {
        return this._editPartnerById(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bafoeg/documents',
        endpoint: 'cronos'
    })
    private _findAllByAccount: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<BafoegMinimalData[]>>;
    findAllByAccount(): Promise<JsonWrappedValue<BafoegMinimalData[]>> {
        return this._findAllByAccount(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bafoeg/documents/{id}',
        endpoint: 'cronos'
    })
    private _findCompleteById: IResourceMethodObservableStrict<void, {}, {id: string}, BafoegCompleteFormData>;
    findCompleteById(id: string): Promise<BafoegCompleteFormData> {
        return this._findCompleteById(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bafoeg/documents/{id}/result',
        endpoint: 'cronos'
    })
    private _findResultById: IResourceMethodObservableStrict<void, {}, {id: string}, BafoegCalculationData>;
    findResultById(id: string): Promise<BafoegCalculationData> {
        return this._findResultById(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bafoeg/documents/{id}/download',
        endpoint: 'cronos'
    })
    private _getZipByIdAndAccount: IResourceMethodObservableStrict<void, {}, {id: string}, Blob>;
    getZipByIdAndAccount(id: string): Promise<Blob> {
        return this._getZipByIdAndAccount(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/bafoeg/documents/{id}/mdownload',
        endpoint: 'cronos'
    })
    private _getZipByIdAndConfirmMail: IResourceMethodObservableStrict<void, {}, {id: string}, Blob>;
    getZipByIdAndConfirmMail(id: string): Promise<Blob> {
        return this._getZipByIdAndConfirmMail(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/bafoeg/documents/{id}/mail',
        endpoint: 'cronos'
    })
    private _sendMailByIdAndAccount: IResourceMethodObservableStrict<void, {}, {id: string}, void>;
    sendMailByIdAndAccount(id: string): Promise<void> {
        return this._sendMailByIdAndAccount(null, null, {id: id}).toPromise()
    }

}