<div class="content-wrapper" fxLayout="column" fxLayoutAlign="center center" style="    min-height: 80vh;">
  <h2><b>Leider ist dieses Angebot der Hochschulinitiative Deutschland nicht mehr verfügbar!</b></h2>

  <p>Da wir euch immer <b>Top Angebote</b> anbieten wollen, kann das passieren.
    Bei Themen wie <b>Thesis, Steuererklärung für Studenten</b> oder <b>Excel Tutorials</b> sind wir der richtige
    Partner!
    Ganz sicher findest du in unseren <b>anderen Angeboten</b> das Richtige für dich:</p>
  <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
    <app-offer-page-panel [queryParams]="{utm_source:'hidpage',utm_medium:'offerpagedeactivatedpage', utm_campaign:'offerpage-deactivated-offer-caroussel'}"
                          [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds"></app-offer-page-panel>
    <button *ngIf="recommendedUpsellPageIds.length==0" class="hid-button secondary" (click)="goHome()">Zu den
      Angeboten
    </button>
  </div>
</div>
