<div id="banner" [ngStyle]="{'background-image':'url(' + bannerUrl +')'}" #banner (click)="scrollToContent()"
     fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.xs="center end">
  <div id="banner-panel" fxLayout="column">
    <div id="bp-background"></div>
    <div id="bp-content" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutAlign.xs="space-between center">
      <div *ngIf="homePageConfig" [innerHTML]="homePageConfig?.headerTextHtml | safeHtml">
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" class="w-100">
        <button *ngIf="homePageConfig && !homePageConfig.headerLinkActive" class="hid-button orange-button" (click)="scrollToContent()">Mehr erfahren</button>
        <a *ngIf="homePageConfig && homePageConfig.headerLinkActive" [href]="homePageConfig.headerLink" class="hid-button orange-button">Mehr erfahren</a>
      </div>

    </div>
  </div>

</div>
<div fxLayout="column" fxLayoutAlign="start center" class="content-wrapper" style="margin-top:40px;" #content>
  <app-bundle-caroussel style="max-width: 100%" [bundle]="bundle" *ngFor="let bundle of bundles"></app-bundle-caroussel>
</div>

