<div class="wrapper" fxLayout="column" fxLayoutAlign="center center" *ngIf="promotionData">
  <div style="font-weight: bold; margin-bottom: 10px">{{promotionData.formatTitle}}</div>

  <div style="text-align: start" fxLayout="row" fxLayoutAlign="start start"
       *ngIf="promotionData.promotionFormatType === 'OfflineSeminarFormat'">
    <div>{{promotionData?.location}}
      , {{promotionData?.address}}
      <span class="maps-link link">
        <a class="link-color"
           href="https://maps.google.com/?q={{promotionData?.address}}"
           target="_blank">
    <a class="link-color" href="https://maps.apple.com/maps?q={{promotionData?.address}}"
       target="_blank">
      <img id="google-maps-icon" src="../../../assets/img/googleMaps.png">
    </a></a>
  </span>
    </div>
  </div>
  <div *ngIf="promotionData.promotionFormatType === 'WebinarFormat'">
    Online-Termin
  </div>
  <span *ngIf="promotionData?.promotionType != 'Giveaway' &&
  promotionData?.promotionType != 'Competition' &&
  promotionData?.promotionType != 'Coupon'">
    {{promotionData?.date | date: 'dd.MM.yyyy, HH:mm'}}
  </span>
</div>


