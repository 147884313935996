<div class="content-wrapper">
  <hid-loading-spinner [loadingBool]="loading">
    <div fxLayout="column" fxLayoutAlign="start start" *ngIf="!loading">
      <div class="controls" fxLayout="row" fxLayoutAlign="start start">
        <button class="hid-button secondary inverse margin-0" *ngIf="showAppointmentStep"
                (click)="goToAppointment()"><i
          class="fa fa-chevron-left"></i> Zurück zum Terminwunsch
        </button>

        <button class="hid-button secondary inverse margin-0 ita_back-to-lessons" *ngIf="!showAppointmentStep"
                (click)="backToLessons()"><i
          class="fa fa-chevron-left"></i> Zurück zu den Lektionen
        </button>

      </div>
      <div class="header" fxLayout="column" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="title">{{format.title}}</div>
          <button fxHide class="hid-button secondary inverse" (click)="initShare()"><span
            fxHide.xs>Angebot teilen </span><i
            class="fa fa-share-alt"></i></button>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h3>Zusammenfassung</h3>

          <div class="wrapper-progressBar" [fxFlex]="numberOfSteps < 5 ? 30 : 45" fxHide.xs fxShow>
            <ul class="progressBar">
              <li *ngFor="let lesson of getArray(numberOfSteps); let stepperIndex = index"
                  class="active ita_stepper-go-to-lesson"
                  (click)="goToStep(stepperIndex)"
                  [ngStyle]="{'width':  100/(numberOfSteps+2)+'%'}">
                <span class="stepper-index">{{stepperIndex + 1}}</span>
              </li>
              <li *ngIf="showAppointmentStep"
                class="active ita_stepper-go-to-appointment"
                (click)="goToAppointment()"
                [ngStyle]="{'width':  100/(numberOfSteps+2)+'%'}">
                <span class="stepper-index">{{numberOfSteps + 1}}</span>
              </li>
              <li
                class="active ita_stepper-go-to-summary"
                (click)="goToSummary()"
                [ngStyle]="{'width':  100/(numberOfSteps+2)+'%'}">
                <span class="stepper-index" *ngIf="showAppointmentStep">{{numberOfSteps + 2}}</span>
                <span class="stepper-index" *ngIf="!showAppointmentStep">{{numberOfSteps + 1}}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="shadow-box marginTop-10" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between start">
          <div fxLayout="column" fxFlex="65" fxLayoutAlign="start start">
            <div class="next text-secondary">Super! Das hast du heute gelernt:</div>
            <div class="margin-bottom-20" [innerHTML]="format.summary | safeHtml"></div>
          </div>

          <div fxLayout="column" fxLayoutAlign="start center" class="side-content" fxFlex="30" *ngIf="format.fileName">
            <img fxHide.lt-sm fxShow src="../../../assets/img/online-lecture.jpg" class="image"/>
            <span class="e-book-text">Wir haben noch ein paar Zusatzmaterialien für dich vorbereitet:</span>
            <button class="hid-button secondary ita_download"
                    *ngIf="format.fileName"
                    [disabled]="downloading"
                    (click)="download()">Herunterladen
            </button>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" style="padding:8px;" class="shadow-box big-sharebox">
          <div >Unser Angebot hat dir gefallen? Lass deine Freunde teilhaben!</div>
        <button class="hid-button primary big-share-button ita_share"
                (click)="initShare()">Dieses Seminar teilen <i
          class="fa fa-share-alt"></i>
        </button>
        </div>
        <div class="shadow-box" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
             *ngIf="!date && format.displayAppointmentRequest">
          <div fxLayout="column" fxFlex="65">
            <div [innerHTML]="format.nextStepsText | safeHtml"></div>
          </div>
          <div fxLayout="column" class="side-content" fxFlex="30" *ngIf="format.fileName">
            <img fxHide.lt-sm fxShow src="../../../assets/img/appointment.jpg" class="image"/>
            <button class="hid-button primary ita_back-to-appointment"
                    (click)="goToAppointment()">Termin vereinbaren
            </button>
          </div>
        </div>
      </div>
    </div>
  </hid-loading-spinner>
</div>
<div *ngIf="!loading && recommendedUpsellPageIds">
  <div fxLayout="column" fxLayoutAlign="start center" *ngIf="recommendedUpsellPageIds.length > 0">

    <div class="upsell-headline">Das könnte dich auch interessieren:</div>
    <div fxLayout="row wrap" fxLayoutAlign="center start" class="margin-bottom-30">
      <app-offer-page-panel class="offer-page-panel"
                            [queryParams]="{utm_source:'hidpage', utm_campaign:'onlinelecture-offer-caroussel',utm_medium:'onlinelecture'}"
                            [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds"></app-offer-page-panel>
    </div>
  </div>
</div>
