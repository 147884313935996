import {Component, Input, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {delay} from "../../inno-utils/general-utils.service";

@Component({
  selector: 'hid-loading-spinner',
  templateUrl: './hid-loading-spinner.component.html',
  styleUrls: ['./hid-loading-spinner.component.scss']
})
//TODO rename to loading-overlay
export class HidLoadingSpinnerComponent implements OnInit {
  @Input() loadingBool: Boolean;
  @Input() loadingSubject: Subject<boolean>;
  @Input() transparency: Boolean = true;
  @Input() enableWaitingTimeEntertainment = true;

  entertainmenttext = '';
  showentertainmenttext = false;

  constructor() {
  }

  ngOnInit() {
    if (this.loadingSubject) {
      this.loadingSubject.subscribe(newValue => {
        this.loadingBool = newValue;
        if (this.enableWaitingTimeEntertainment) {
          delay(3000).then(() => {
              this.triggerEntertainment(0)
            }
          )
        }
      })
    }
  }

  //Does not support spinners that go on and off so far, would need to introduce ids for loading processes
  messages = ['Langsames Internet?', 'Dann warten wir zusammen :)', 'Ich erzähl dir ein paar Witze..']
  jokes = [{
    q: 'Wie nennt man einen Bieber ohne Fell?',
    a: 'Einen Bibber!'
  },
    {
      q: 'Was ist rot und schießt sich durch den Salat?',
      a: 'Ein Rambodieschen!'
    },
    {
      q: 'Was ist klein, grün und liegt im Sarg?',
      a: 'Eine Sterbse'
    }
  ]

  currentJoke = null;

  triggerEntertainment(i: number) {
    const animationLenght = 5000
    this.showentertainmenttext = false;
    delay(200).then(() => {
      //intro messages
      if (this.messages[i]) {
        this.entertainmenttext = this.messages[i];
      }
      //Jokes
      else {
        if (this.jokes.length == 0) {
          this.entertainmenttext = 'Jetzt hab ich leider nichts mehr :('
          delay(animationLenght).then(() => {
            this.showentertainmenttext = false
            return
          })
        } else {
          if (i % 2 == 1) {
            const i = Math.floor(Math.random() * Math.floor(this.jokes.length));
            this.entertainmenttext = this.jokes[i].q
            this.currentJoke = i
          } else {
            this.entertainmenttext = this.jokes[this.currentJoke].a
            this.jokes.splice(this.currentJoke, 1)
            this.currentJoke = null
          }
        }
        this.showentertainmenttext = true;
        delay(animationLenght).then(() => {
          this.triggerEntertainment(i + 1)
        })
      }
    })
  }

}
