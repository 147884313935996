import {Injectable} from '@angular/core';
import {JsonWrappedValue, OnlineLectureInfoData} from "../generated/cronos/data";
import {OnlineLectureAPIResourceV2} from "../generated/cronos/resources";
import {NoTokenRedirectDialogComponent} from "./no-token-redirect-dialog/no-token-redirect-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PublicOfferPageResource} from "../generated/cms/resources";
import {LocalStorageService} from "../local-storage.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {HidSnackbarService} from "../hid-interactions/hid-snackbar/hid-snackbar.service";

export interface OfferPageData {
  offerPageUrl: string;
  offerPageId: number,
  upSellIds: number[]
}

@Injectable({
  providedIn: 'root'
})
export class OnlineLectureService {

  constructor(
    private onlineLectureAPIResourceV2: OnlineLectureAPIResourceV2,
    private publicOfferPageResource: PublicOfferPageResource,
    private dialog: MatDialog,
    public localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: HidSnackbarService,
  ) {
  }

  private _numberOfSteps: number;
  private _showAppointmentStep: boolean;
  private _showSummaryStep: boolean;

  private _date: Date;

  private _format: OnlineLectureInfoData;

  private _offerPageId: number;
  private _offerPageUrl: string;
  private _recommendedUpsellPageIds: number[];

  private _cronosFormatId: number;
  private _token: string;

  dateFilter = (d: Date): boolean => {
    const day = d.getDay();
    const minDate = new Date();
    minDate.setHours(0);
    minDate.setDate(minDate.getDate() + 2);

    return day !== 0 && day !== 6 && d.getTime() > minDate.getTime();
  };


  get cronosFormatId(): number {
    return this._cronosFormatId;
  }

  set cronosFormatId(value: number) {
    this._cronosFormatId = value;
  }

  get showAppointmentStep(): boolean {
    return this._showAppointmentStep;
  }

  set showAppointmentStep(value: boolean) {
    this._showAppointmentStep = value;
  }

  get showSummaryStep(): boolean {
    return this._showSummaryStep;
  }

  set showSummaryStep(value: boolean) {
    this._showSummaryStep = value;
  }

  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
  }

  get numberOfSteps(): number {
    return this._numberOfSteps;
  }

  set numberOfSteps(value: number) {
    this._numberOfSteps = value;
  }

  get date(): Date {
    return this._date;
  }

  set date(value: Date) {
    this._date = value;
  }

  get format(): OnlineLectureInfoData {
    return this._format;
  }

  set format(value: OnlineLectureInfoData) {
    this._format = value;
  }

  get offerPageId(): number {
    return this._offerPageId;
  }

  set offerPageId(value: number) {
    this._offerPageId = value;
  }

  get offerPageUrl(): string {
    return this._offerPageUrl;
  }

  set offerPageUrl(value: string) {
    this._offerPageUrl = value;
  }

  get recommendedUpsellPageIds(): number[] {
    return this._recommendedUpsellPageIds;
  }

  set recommendedUpsellPageIds(value: number[]) {
    this._recommendedUpsellPageIds = value;
  }

  getToken(cronosIdInCaseForRedirect: number) {
    let token: string = this.localStorageService.getLectureAuthToken();
    if (token && token.length > 0) return token;
    else {
      this._cronosFormatId = cronosIdInCaseForRedirect;
      this.redirectToOfferPage();
    }
  }

  loadFormatInitially(cronosFormatId: number): Promise<OnlineLectureInfoData> {
    this._cronosFormatId = cronosFormatId;
    this._token = this.getToken(this._cronosFormatId);

    return new Promise<OnlineLectureInfoData>(async format => {
      if (this._token && this._token.length > 0) {
        await this.onlineLectureAPIResourceV2.getOnlineLectureInfo(cronosFormatId, {code: this._token}).then(result => {
          this._format = result;
          this._numberOfSteps = result.lessons.length;
          this._showAppointmentStep = result.nextStepsText != null && result.nextStepsText.length > 0;
          this._showSummaryStep = result.summary != null && result.summary.length > 0;
          format(result);
        })
      }
    });
  }

  loadFormat(cronosFormatId: number): Promise<OnlineLectureInfoData> {
    this._cronosFormatId = cronosFormatId;
    this._token = this.getToken(cronosFormatId);

    return new Promise<OnlineLectureInfoData>(async format => {
      if (this._token && this._token.length > 0) {
        if (this._format) {
          format(this._format);
        } else {
          await this.loadFormatInitially(cronosFormatId).then(initialFormat => {
            format(initialFormat)
          });
        }
      }
    });
  }

  loadOfferPage(cronosFormatId: number): Promise<OfferPageData> {
    this._cronosFormatId = cronosFormatId;

    return new Promise<OfferPageData>(async offerpage => {
      if (this._offerPageUrl && this._offerPageId && this._recommendedUpsellPageIds) {
        offerpage({
          offerPageUrl: this._offerPageUrl,
          offerPageId: this._offerPageId,
          upSellIds: this._recommendedUpsellPageIds
        });
      } else {
        await this.publicOfferPageResource.getFormatPageForSyncedFormat({syncedFormatId: this._cronosFormatId}).then(res => {
          this._offerPageId = res.offerPageId;
          this._offerPageUrl = res.routingUrl;

          this.loadUpsells().then(upSells => {
            this._recommendedUpsellPageIds = upSells;
            offerpage({offerPageUrl: res.routingUrl, offerPageId: res.offerPageId, upSellIds: upSells});
          });
        })
      }
    });
  }

  loadUpsells() {
    return new Promise<number[]>(async upSellIds => {
      if (this._token && this._token.length > 0) {
        if (this._recommendedUpsellPageIds) {
          upSellIds(this._recommendedUpsellPageIds)
        } else {
          if (this._token && this._token.length > 0) await this.publicOfferPageResource.getOfferPageUpsellRecommendations(this._offerPageId).then(upsells => {
            this._recommendedUpsellPageIds = upsells;
            upSellIds(upsells);
          });
        }
      } else upSellIds([])
    });
  }

  redirectToOfferPage() {
    if (this._offerPageUrl != null && this._offerPageUrl.length > 0) {
      let dialogRef = this.dialog.open(NoTokenRedirectDialogComponent, {
        data: this._offerPageUrl,
        maxWidth: '100%',
        width: '600px'
      });
    } else this.loadOfferPage(this._cronosFormatId).then(() => {
      this.redirectToOfferPage();
    });
  }

  initializeAppointmentDate(form: FormGroup): FormGroup {
    let initialDate = new Date();
    initialDate.setDate(initialDate.getDate() + 3);

    //Skip weekends
    if (initialDate.getDay() == 0) {
      initialDate.setDate(initialDate.getDate() + 1)
    }
    if (initialDate.getDay() == 6) {
      initialDate.setDate(initialDate.getDate() + 2)
    }

    form = this.formBuilder.group({
      date: [initialDate, [Validators.required]],
      hour: ['14', [Validators.required, Validators.min(9), Validators.max(17)]],
      minute: ['30', [Validators.required]]
    });

    form.controls['hour'].valueChanges.subscribe(value => {
      if (value < 0) form.controls['hour'].setValue('00');
      if (value > 23) form.controls['hour'].setValue('23')
    });

    return form
  }

  setAppointment(form: FormGroup, goToSummaryAfterwards: Boolean): Promise<FormGroup> {
    let date: Date = new Date(form.controls['date'].value);
    date.setHours(form.controls['hour'].value.toString());
    date.setMinutes(form.controls['minute'].value.toString());
    this._date = date;

    let month: number = date.getMonth() + 1;

    let jsonWrappedDate: JsonWrappedValue<string> = { //dont know what this has to be so that it works with matzes workaround, but the previous version didn't work for me
      value: date.getFullYear() + '-' + month + '-' + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    };

    return new Promise<FormGroup>(formGroup => {
      this.onlineLectureAPIResourceV2.requestAppointmentForOnlineLecture(jsonWrappedDate, this.token).then(() => {
        this.snackBar.openSnackBar("Terminwunsch abgeschickt. Du wirst kontaktiert!", "Ok", 5000, false);
        if (goToSummaryAfterwards) this.goToSummary();
      });
      formGroup(form);
    })
  }

  private goToSummary() {
    this.onlineLectureAPIResourceV2.finishOnlineLecture(this.token).then(() => {
      this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/summary');
    });
  }
}


