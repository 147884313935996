import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ServiceFormResource} from "../../generated/cronos/resources";
import {PublicMammothWebinarData} from "../../generated/cronos/data";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {UtilsService} from "../../utils.service";

@Component({
  selector: 'app-mlp-service-form-trigger-email-page',
  templateUrl: './mlp-service-form-trigger-email-page.component.html',
  styleUrls: ['./mlp-service-form-trigger-email-page.component.scss']
})
export class MlpServiceFormTriggerEmailPageComponent implements OnInit {

  loading: boolean = false;
  token: string;
  triggeringEmails: boolean = false;
  locations: string[] = [];
  mammothWebinar: PublicMammothWebinarData;

  //the below 2 are the same dude
  alreadySent: boolean = false;
  successfullySent: boolean = false;

  errorOnSend = false;

  alternativeLink = ''

  confirmed = false;

  constructor(private route: ActivatedRoute,
              private snackBar: HidSnackbarService,
              private serviceFormResource: ServiceFormResource,
              private utilsService: UtilsService) {
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get("code");
    if (this.token) {
      this.load();
    }
  }

  load() {
    this.loading = true;
    this.serviceFormResource.getMammothWebinarByToken(this.token).then(result => {
        this.mammothWebinar = result;
        let origin = window.location.origin;
        if(result.webinarFormatId == 2215) {
          this.alternativeLink = origin + '/actions/service-form/token?format=Thesis'
        } else {
          this.alternativeLink = origin + '/actions/service-form/token?format=Excel'
        };
        this.loading = false;
        this.mammothWebinar.mammothWebinars.map(x => {
          this.locations.push(x.cityName)
        })
        this.locations.pop();
        if(this.mammothWebinar.emailsSent) this.alreadySent = true
  })
  }

  triggerEmailSendMechanism() {
    this.triggeringEmails = true;
      this.serviceFormResource.triggerServiceFormEmails(this.token).then(response => {
          this.triggeringEmails = false;
          if(response.value == null) {
            this.errorOnSend = true;
          }
          if(response.value == true) {
            this.successfullySent = true;
          } else if(response.value == false) {
            this.alreadySent = true;
          }
        }
      )

  }

  generateLocationToolTip(locations: string[]) {
    return locations.join(", ")
  }


  copyStringToClipboard(string: string) {
    this.utilsService.copyStringToClipboard(string);
  }

}
