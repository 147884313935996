import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClipboardService} from "ngx-clipboard";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";

@Component({
  selector: 'app-share-overlay',
  templateUrl: './share-overlay.component.html',
  styleUrls: ['./share-overlay.component.scss']
})
export class ShareOverlayComponent implements OnInit {
  @Input() subUrl: string;
  // @Input() utmShareSource: string;
  @Input() utmCampaignIdent: string;
  @Input() shareText: string;
  @Input() additionalQueryParams: any;

  @Output() selfDestroy: EventEmitter<any> = new EventEmitter();

  shareLink;
  encodedShareLink;

  constructor(private clipboard: ClipboardService, private snackBar: HidSnackbarService) {
  }

  ngOnInit() {

    this.shareLink = window.location.origin + this.subUrl + '?utm_source=hidpage' + '&utm_campaign=' + this.utmCampaignIdent + '&utm_medium=hid-share';
    if (this.additionalQueryParams) {
      for (let param of this.additionalQueryParams) {
        this.shareLink += '&' + param.key + '=' + param.value;
      }
    }
    this.encodedShareLink = encodeURIComponent(this.shareLink)
  }

  destroySelf() {
    this.selfDestroy.emit()
  }


  sharelinkToClipBoard() {
    this.clipboard.copyFromContent(this.shareLink);
    this.snackBar.openSnackBar("Link in der Zwischenablage", null, 10000, false);
    this.destroySelf();
  }

}
