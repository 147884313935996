import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StandardCheckoutS1ProcessService} from '../standard-checkout-s1-process.service';

@Component({
  selector: 'app-sta-checkout-s1-entry',
  templateUrl: './sta-checkout-s1-entry.component.html',
  styleUrls: ['./sta-checkout-s1-entry.component.sass']
})
export class StaCheckoutS1EntryComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private standard1ProcessService: StandardCheckoutS1ProcessService) {
  }

  ngOnInit(): void {
    //route to first step
    this.router.navigate([this.standard1ProcessService.prepareSplitRoutingUrl(this.route, 'checkout/standard/s1/location')]);
  }

}
