import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { PublicOfferBundleData} from "../../generated/cms/data";
import {PublicBundleResource} from "../../generated/cms/resources/public-bundle.resource";

@Component({
  selector: 'app-bundle-overview',
  templateUrl: './bundle-overview.component.html',
  styleUrls: ['./bundle-overview.component.scss']
})
export class BundleOverviewComponent implements OnInit {

  currentBundleRoute: string;

  bundles: PublicOfferBundleData[];

  currentBundle: PublicOfferBundleData = <PublicOfferBundleData>{};

  loading = true;
  dropdownVisible = false;

  constructor(private bundleResource: PublicBundleResource, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.currentBundleRoute = params.identifier;
      this.loadBundles()
    })
  }

  ngOnInit() {

  }

  loadBundles() {
    this.loading = true;
    this.bundleResource.getBundles().then(res => {
      this.bundles = res;
      this.setBundle();
      this.loading = false;
    })
  }

  //You could load here if you wanna get additional bundle infos
  setBundle() {

    for (let b of this.bundles) {
      if (b.routingUrl == this.currentBundleRoute) {
        this.currentBundle = b
      }
    }
  }

  switchBundle(newRoute) {
    this.currentBundleRoute = newRoute;
    this.setBundle()
  }
}
