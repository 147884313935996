import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {PublicOfferPagePreviewData} from "../../generated/cms/data";
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {MediaService} from "../../inno-utils/media.service";

@Component({
  selector: 'app-offer-page-panel',
  templateUrl: './offer-page-panel.component.html',
  styleUrls: ['./offer-page-panel.component.scss']
})
export class OfferPagePanelComponent implements OnInit {

  @Input() pageId: number;

  @Input() queryParams: any = {};
  //Tells us if the click on the panel is tracking-relevant and therefore results in queryParams and a tracking entry
  @Input() trackedClick: boolean = true;

  //When loading is done externally, you can use loeading animation by providing a bool
  @Input() externallyProvidedPage: Subject<PublicOfferPagePreviewData>;

  @ViewChild("image", { static: true }) image: ElementRef;

  page: PublicOfferPagePreviewData = <PublicOfferPagePreviewData>{averageFeedbackRating: 5.0};
  loading: boolean;
  loadingImage: boolean = true;
  imageSrc = '';
  altText ='Titelbild für Angebotsseite';
  amountOfRatings = 0;
  rating = 0.0;

  longWordInPageTitle= false;

  constructor(
    private offerPageResource: PublicOfferPageResource,
    private router: Router,
    public mediaService: MediaService,
    @Inject("env") private env
  ) {
  }

  ngOnInit() {
    this.loading = true;
    //Sometimes you can load the page externally, so you can wait for the load and work with the page-input afterwards
    if (this.externallyProvidedPage) {
      this.externallyProvidedPage.subscribe(n => {
        this.page = n;
        this.proceedInitiation()
        this.altText = this.page.altText
      });
      return
    }
    this.offerPageResource.getOfferPagePreviewData(this.pageId).then(res => {
      this.page = res;
      this.proceedInitiation();
      this.loading = false;
      this.altText = this.page.altText
    })
  }

  proceedInitiation() {
    if(this.page.title.indexOf('Steuer-Seminar') > -1) {
    }

    this.longWordInPageTitle = this.page.title.split(" ").some(word => word.length > 20);

    if(this.page.averageFeedbackRating == null || this.page.averageFeedbackRating == 0) {
      this.calculateRating();
    } else {
      this.rating = this.page.averageFeedbackRating;
    }

    this.imageSrc = this.env.cmsApiUrl + '/images/containers/' + this.page.imageCountainerId + '?height=' + this.image.nativeElement.clientHeight + '&width=' + this.image.nativeElement.clientWidth;
    this.loading = false;
  }

  calculateRating() {
    var rating = 0.0;
    const mod = this.page.offerPageId % 3;
    switch (mod) {
      case 0:
        rating = 4.0;
        break;
      case 1:
        rating = 4.5;
        break;
      case 2:
        rating = 5.0;
        break;
    }
    this.rating = rating
  }
}
