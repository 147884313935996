import { Component, OnInit } from '@angular/core';
import {
  ValidatePhoneNumberInfoData,
  ValidatePhoneNumberProcessData
} from "../../../generated/cronos/data";
import {
  ActionLinkAPIResourceV2,
  ContactApiResourceV2,
  PromotionApiResourceV2
} from "../../../generated/cronos/resources";
import {ActivatedRoute, Router} from "@angular/router";
import {PublicOfferPageResource} from "../../../generated/cms/resources";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ContactSupportModalComponent} from "../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {FormControl, Validators} from "@angular/forms";
import {UtilsService} from "../../../utils.service";
import {HidSnackbarService} from "../../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {PhoneNumber, PhoneNumberFormat, PhoneNumberUtil} from "google-libphonenumber";

@Component({
  selector: 'app-phone-number-validation',
  templateUrl: './phone-number-validation.component.html',
  styleUrls: ['./phone-number-validation.component.scss']
})
export class PhoneNumberValidationComponent implements OnInit {

  token: string;
  loading: boolean = true;
  processOutcomeData: ValidatePhoneNumberProcessData = null;
  processInfoData: ValidatePhoneNumberInfoData = <ValidatePhoneNumberInfoData>{}
  parsedPhoneNumber: PhoneNumber
  validationCode: string;
  phoneNumberValidated: boolean = false;
  validating: boolean = true;

  phoneNumberControl = new FormControl(null, [this.utilsService.validatePhoneNumber(), Validators.required]);
  phoneUtil = PhoneNumberUtil.getInstance()
  checkingCode: boolean = false;
  codeChecked: boolean = false;
  codeInputFromUser: FormControl = new FormControl('', [Validators.maxLength(4), Validators.required]);

  constructor(private actionLinkAPIResourceV2: ActionLinkAPIResourceV2,
              private route: ActivatedRoute,
              private router: Router,
              private offerPageResource: PublicOfferPageResource,
              private modal: NgbModal,
              private contactAPIResourceV2: ContactApiResourceV2,
              private snackbar: HidSnackbarService,
              private cronosApi: PromotionApiResourceV2,
              private utilsService: UtilsService) {
    route.snapshot.queryParamMap.get("code") ? this.token = route.snapshot.queryParamMap.get("code") : this.token = route.snapshot.paramMap.get("token");
    this.getValidationInfo()
    this.codeInputFromUser.valueChanges
      .pipe(debounceTime(100))
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(() => {
        this.checkCode();
      })

  }

  ngOnInit(): void {
  }

  getValidationInfo() {
    this.loading = true;
      this.actionLinkAPIResourceV2.getValidationInfoByCode({code: this.token}).then(result => {
        this.processInfoData = result;
        if(this.processInfoData.alreadyValidated) {
          this.phoneNumberValidated = true;
          this.validating = false;
        }
        this.phoneNumberControl.setValue(this.processInfoData.phoneNumber)
        this.validationCode = result.validationCode;
        this.loading = false;
      });
  }


  validatePhoneNumber() {
    this.validating = true;
    this.actionLinkAPIResourceV2.validatePhoneNumber({code: this.token}).then( result => {
      this.processOutcomeData = result;
      this.validating = false;
      this.checkingCode = false;
      this.snackbar.openSnackBar("Verifizierung erfolgreich!", 'Ok', 3000, false)

    })
  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }


  /*Phone number validation*/

  requestingAuthCode = false;
  codeRequested = false;

  requestAuthCode() {
    if(!this.phoneNumberControl.valid)return

    this.parsedPhoneNumber = this.phoneUtil.parseAndKeepRawInput(this.phoneNumberControl.value)
    this.requestingAuthCode = true;
    this.contactAPIResourceV2.sendAuthenticationSmsToVerifyContactPhoneNumber({
      code: this.token,
      phoneNumber : this.phoneUtil.format(this.parsedPhoneNumber, PhoneNumberFormat.E164)
    }).then(() => {
      this.requestingAuthCode = false;
      this.codeRequested = true;
      this.snackbar.openSnackBar("Dein Code ist unterwegs!", 'Ok', 3000, false)
    });
  }

  checkCode() {
    this.checkingCode = true;
    if (!this.validationCode) return
    if (this.codeInputFromUser.value.toLowerCase() != this.validationCode.toLowerCase()) {
      this.checkingCode = false;
    } else {
      this.phoneNumberValidated = true;
      this.validatePhoneNumber()
    }
  }



}
