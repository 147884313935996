<div class="actionlink-page-wrapper">
<div class="actionlink-main-box">
  <hid-loading-spinner [loadingBool]="loading">
    <div *ngIf="!cancellationComplete && !loading">
    <h1>Abmeldung von deiner Veranstaltung</h1>
    Willst du dich von dieser Veranstaltung abmelden?
    <app-promotion-info-box [promotionData]="registrationInfo.promotionData"></app-promotion-info-box>

    <hid-loading-button style="text-align: center"[loadingBool]="cancelling" buttonClass="hid-button warn" (clickEvent)="cancelRegistration()">Abmeldung durchführen</hid-loading-button>
    </div>

      <!-- On Reg Cancelled + was not already cancelled -->
      <div class="itemInfo" fxLayout="column" fxLayoutAlign="start center" *ngIf="cancellationComplete">
        <h1 class="text-red"><i class="fa fa-check fam"></i>Abmeldung erfolgt</h1>
        <p>Schade, dass du doch nicht kannst! Hol dir schnell einen Ersatztermin, um die Chance nicht zu verpassen!</p>
        <button class="hid-button primary" (click)="navigateToOfferPage()">Ersatztermin aussuchen</button>
      </div>

  </hid-loading-spinner>
</div>
</div>
