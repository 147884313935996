/** Contains all custom gtm dimensions that can be pushed to data layer */
export enum DataLayerCustomDimensions {
  PageTitle = 'pageTitle',
  UrlParams = 'urlParams',
  UtmSource = 'utmSource',
  UtmContent = 'utmContent',
  UtmMedium = 'utmMedium',
  UtmCampaign = 'utmCampaign',
  ItmCampaign = 'itmCampaign',
  ItmAdset = 'itmAdset',
  ItmCreative = 'itmCreative'
}
