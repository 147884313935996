import {Injectable, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PublicOfferPageResource} from "../../../../generated/cms/resources";

@Injectable({
  providedIn: 'root'
})
export class OfferPageRedirectService {

  cronosFormatId: number
  promId: number
  offerRoutingUrl: string

  constructor(private offerPageResource: PublicOfferPageResource,
              private route: ActivatedRoute,
              private router: Router) {

    route.params.subscribe(params => {
      this.cronosFormatId = params['formatId']
    })

    route.queryParams.subscribe(params => {
      this.promId = params['promId']
    })

  }


}
