<div [ngClass]="{'no-padding': noPadding}" fxLayout="row" fxLayoutAlign="start center"  class="cb-container" (click)="control.setValue(!control.value)">
  <input type="checkbox"  [formControl]="control"  [className]="inputClass" >

  <div class="checkmark" fxLayout="row" fxLayoutAlign="center center"
       [ngClass]="{'checked':control.value,'error': control.errors && _highlightErrors}">
    <i class="fa fa-check"></i>
  </div>
  <div>

    <ng-content></ng-content>
  </div>
</div>
