<div class="content-wrapper " style="padding: 32px 32px;">
  <hid-loading-spinner [loadingBool]="loading">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!loading" >

      <!-- VALIDATE PHONE NUMBER STEP -->
      <div  class="itemInfoText height-join" id="phone-box" *ngIf="!processInfoData.missingPromRegState">
        <div>
          <h2>Telefonnummer bestätigen</h2>
          <p>Bitte bestätige deine Telefonnummer. Fordere dazu jetzt deinen Zugangscode an und gib ihn unten ein.</p>

          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
            <app-hid-input-phone-number id="phone-input" inputFieldClass="ita_input_phone"
                                        name="phoneNumber" type="tel" [control]="phoneNumberControl"
                                        label="Deine Telefonnummer" [disabled]="phoneNumberValidated || codeRequested"
            ></app-hid-input-phone-number>

            <button class="hid-button secondary" (click)="requestAuthCode()" id="request-button"
                    [disabled]="phoneNumberValidated || codeRequested">
              <span *ngIf="!codeRequested">Code senden</span>
              <span *ngIf="codeRequested">Erneut senden</span>
            </button>

          </div>

          <span *ngIf="codeRequested" id="code-box">
              Der Code wurde an die angegebene Telefonnummer gesandt!</span>

        </div>

        <div fxLayout="column" id="validation-input">
          <hid-input-field label="Gib deinen Code hier ein" [control]="codeInputFromUser" *ngIf="!phoneNumberValidated"></hid-input-field>
        </div>

        <div *ngIf="phoneNumberValidated" >
          <hid-loading-spinner [loadingBool]="validating">
            <div class="itemInfo" fxLayout="column" fxLayoutAlign="start center"
                 *ngIf="processOutcomeData && processOutcomeData.validated">
              <div fxLayout="column" fxLayoutAlign="start center">
                <!-- <i class="fa fa-check circle-item text-green" style="font-size: 50px"></i> -->

                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                <h2 class="text-green">Telefonnummer bestätigt</h2>
                  <span style="text-align:center">Danke für die Bestätigung deiner Telefonnummer!</span>

              </div>
            </div>
            <div class="itemInfo" fxLayout="column" fxLayoutAlign="start center"
                 *ngIf="!processOutcomeData && processInfoData.alreadyValidated">
              <div fxLayout="column" fxLayoutAlign="start center">
                 <!-- <i class="fa fa-check circle-item text-green" style="font-size: 50px"></i> -->

                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                <h2 class="text-green">Telefonnummer bestätigt</h2>
                  <span style="text-align:center">Du hast deine Telefonnummer bereits bestätigt!</span>

              </div>
            </div>
          </hid-loading-spinner>
        </div>
      </div>





      <!-- On non-existing promRegState -->
      <div class="itemInfo" fxLayout="column" fxLayoutAlign="start center" *ngIf="processInfoData.missingPromRegState" style="padding-top: 30px">
        <!-- <i class="fa fa-times text-red" style="font-size: 50px; margin-right: 30px; padding-bottom: 20px;"></i> -->

         <div class="sa">
           <div class="sa-error">
             <div class="sa-error-x">
               <div class="sa-error-left"></div>
               <div class="sa-error-right"></div>
             </div>
             <div class="sa-error-placeholder"></div>
             <div class="sa-error-fix"></div>
           </div>
         </div>

         <h3 style="text-align:center">Dein Link ist leider ungültig <i class="fa fa-frown"></i></h3>
         <button mat-flat-button class="support-button" (click)="openSupportModal()"><i class="fa fa-question-mark"></i>
          Support kontaktieren
         </button>
       </div>
     </div>
   </hid-loading-spinner>
 </div>
