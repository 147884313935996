import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DownloadGiveawayActionLinkComponent} from "./download-giveaway-action-link/download-giveaway-action-link.component";
import {PromotionSignupComponent} from "./promotion-signup/promotion-signup.component";
import {UnsubActionLinkComponent} from "./unsub-action-link/unsub-action-link.component";
import {UnsubWaitingListActionLinkComponent} from "./unsub-waiting-list-action-link/unsub-waiting-list-action-link.component";
import {RouterModule, Routes} from "@angular/router";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {OfferPagePanelModule} from "../../offer-page-panel/offer-page-panel.module";
import {ContactSupportModalModule} from "../../contact-support-modal/contact-support-modal.module";
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {
  ActionLinkAPIResourceV2,
  MailmarketingAPIResourceV2,
  PromotionApiResourceV2
} from "../../generated/cronos/resources";
import {HidInteractionsModule} from "../../hid-interactions/hid-interactions.module";
import {InnoUtilsModule} from "../../inno-utils/inno-utils.module";
import { PromotionStateCancelActionLinkComponent } from './promotion-state-cancel-action-link/promotion-state-cancel-action-link.component';
import {PromotionStateConfirmActionLinkComponent} from "./promotion-state-confirm-action-link/promotion-state-confirm-action-link.component";
import {FeedbackStarsModule} from "../../feedback-stars/feedback-stars.module";
import { PhoneNumberValidationComponent } from './phone-number-validation/phone-number-validation.component';
import {MatButtonModule} from "@angular/material/button";
import { InvalidTokenErrorPageComponent } from './invalid-token-error-page/invalid-token-error-page.component';
import { PromotionInfoBoxComponent } from './promotion-info-box/promotion-info-box.component';
import {
  SeminarStudentPresentFeedbackComponent
} from "./feedback/seminar-student-present-feedback/seminar-student-present-feedback.component";
import {FeedbackThankYouPageComponent} from "./feedback/feedback-thank-you-page/feedback-thank-you-page.component";
import {
  SeminarStudentNotPresentFeedbackComponent
} from "./feedback/seminar-student-not-present-feedback/seminar-student-not-present-feedback.component";
import {
  SeminarStudentNotPresentThankYouPageComponent
} from "./feedback/seminar-student-not-present-thank-you-page/seminar-student-not-present-thank-you-page.component";
import {
  NonSeminarStudentFeedbackComponent
} from "./feedback/non-seminar-student-feedback/non-seminar-student-feedback.component";
import {FeedbackDisplayComponent} from "../../offer-page/feedback-display/feedback-display.component";
import {MatRadioModule} from "@angular/material/radio";
import {MatDialogModule} from "@angular/material/dialog";
import {RatingModule} from "ng-starrating";
import {MatCardModule} from "@angular/material/card";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CheckInForSeminarComponent} from "./seminar/check-in-for-seminar/check-in-for-seminar.component";
import {DownloadSeminarHandoutComponent} from "./seminar/download-seminar-handout/download-seminar-handout.component";
import {EnterWebinarComponent} from "./seminar/enter-webinar/enter-webinar.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {GlobalModule} from "../../global/global.module";
import {
  StudentConfirmPresentDialogComponent
} from "./feedback/student-confirm-present-dialog/student-confirm-present-dialog.component";
import {AnonymizeFeedbackComponent} from "./feedback/anonymize-feedback/anonymize-feedback.component";
import {MatGridListModule} from "@angular/material/grid-list";

const appRoutes: Routes = [

  //Regstates
  {path: 'actions/promotion-state/cancel', component: PromotionStateCancelActionLinkComponent},
  {path: 'actions/promotion-state/confirm', component: PromotionStateConfirmActionLinkComponent},


  //Pre-Webinar process
  {path: 'actions/seminar/check-in', component: CheckInForSeminarComponent},
  {path: 'actions/seminar/handout-download', component: DownloadSeminarHandoutComponent},
  {path: 'webinar/:cronosFormatId/validate/:token', component: EnterWebinarComponent},
  {path: 'actions/phone-number-validation', component: PhoneNumberValidationComponent},

  //Other promotion actions
  {path: 'actions/download', component: DownloadGiveawayActionLinkComponent},
  {path: 'actions/sign-up', component: PromotionSignupComponent},

  //Unsubs
  {path: 'actions/unsub-newsletter', component: UnsubActionLinkComponent},
  {path: 'actions/unsub-waiting-list', component: UnsubWaitingListActionLinkComponent},

  {path: 'actions/token-error', component: InvalidTokenErrorPageComponent},

  //Seminar Present feedback
  {path: 'actions/feedback/student-present-feedback', component: SeminarStudentPresentFeedbackComponent},
  {path: 'actions/feedback/thank-you', component: FeedbackThankYouPageComponent},

  //Seminar non-present feedback
  {path: 'actions/feedback/student-not-present-feedback', component: SeminarStudentNotPresentFeedbackComponent},
  {path: 'actions/feedback/not-present-thank-you', component: SeminarStudentNotPresentThankYouPageComponent},

  //Non-Seminar feedback
  {path: 'actions/feedback/student', component: NonSeminarStudentFeedbackComponent},


  //Deprecated. Delete the following entries if you see them after 20.4.2022. Cronos only serves the ones above.
  {path: 'actions/unsub-newsletter/token', component: UnsubActionLinkComponent},
  {path: 'actions/unsub-waiting-list/token', component: UnsubWaitingListActionLinkComponent},
  {path: 'actions/download/token', component: DownloadGiveawayActionLinkComponent},
  {path: 'actions/feedback/student-present-feedback/token', component: SeminarStudentPresentFeedbackComponent},
  {path: 'actions/feedback/student-not-present-feedback/token', component: SeminarStudentNotPresentFeedbackComponent},
  {path: 'actions/feedback/student/token', component: NonSeminarStudentFeedbackComponent},
  {path: 'actions/seminar/check-in/token', component: CheckInForSeminarComponent},
  {path: 'actions/seminar/handout-download/token', component: DownloadSeminarHandoutComponent},
];

@NgModule({
  declarations: [
    DownloadGiveawayActionLinkComponent,
    PromotionSignupComponent,
    UnsubActionLinkComponent,
    UnsubWaitingListActionLinkComponent,
    SeminarStudentNotPresentFeedbackComponent,
    FeedbackThankYouPageComponent,
    PromotionStateCancelActionLinkComponent,
    PromotionStateConfirmActionLinkComponent,
    StudentConfirmPresentDialogComponent,
    NonSeminarStudentFeedbackComponent,
    PhoneNumberValidationComponent,
    SeminarStudentNotPresentThankYouPageComponent,
    SeminarStudentPresentFeedbackComponent,
    InvalidTokenErrorPageComponent,
    PromotionInfoBoxComponent,
    CheckInForSeminarComponent,
    DownloadSeminarHandoutComponent,
    EnterWebinarComponent,
    PhoneNumberValidationComponent,
    AnonymizeFeedbackComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(appRoutes),
        FlexLayoutModule,
        HidInteractionsModule,
        MatRadioModule,
        MatDialogModule,
        RatingModule,
        MatCardModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        FormsModule,
        FlexModule,
        OfferPagePanelModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        ContactSupportModalModule,
        InnoUtilsModule,
        FeedbackStarsModule,
        MatButtonModule,
        MatGridListModule,
        GlobalModule,
        NgOptimizedImage,
    ],
  exports: [
    PromotionInfoBoxComponent,
  ],
  providers: [
    PublicOfferPageResource,
    MailmarketingAPIResourceV2,
    PromotionApiResourceV2,
    ActionLinkAPIResourceV2
  ]
})
export class ActionLinkModule { }
