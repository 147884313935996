<div class="nav-wrapper">
  <div class="sidenav-wrapper"
       [class]="{'collapsed-sidebar': collapsed,
       'slide-and-fade-in': !collapsed,
       'scrollable': !collapsed
       }">
    <div fxLayout="column"
         *ngIf="!collapsed"
         class="brand-container"
         [ngClass]="{'small-margin': authService.isAuthenticated()}"
         fxLayoutAlign="center center">
      <div [ngClass]="{'small-logo': authService.isAuthenticated()}" class="hid-logo"></div>
      <!--div *ngIf="!authService.isAuthenticated()" class="studiexperts-slogan">
        Fragen und Antworten rund ums Studium
      </div-->
    </div>


    <div
      *ngIf="authService.isAuthenticated() || collapsed"
      (click)="expandMenu()"
      [ngClass]="{
        'maximized-icon-wrapper': !collapsed
        }"
      class="icon-wrapper"
    >
      <i style="height: fit-content !important;" *ngIf="!info?.avatarId || !authService.isAuthenticated()" [ngClass]="{'big-icon': !collapsed}" class="account-icon mdi mdi-account-circle">
      </i>
      <div *ngIf="info?.avatarId && authService.isAuthenticated()" [ngClass]="{'big-icon': !collapsed}"
           [ngStyle]="{'background-image': utilService.configureStylingPathForProfileAvatar(info.avatarId)}"
           class="account-icon">
      </div>
      <div  *ngIf="unseenNotifications.length > 0"
            [ngClass]="{
            'big-notification-orb': !collapsed,
            'avatar-orb-position-small': info?.avatarId && collapsed,
            'avatar-orb-position-big': info?.avatarId && !collapsed}"
            class="notification-orb">
      </div>
    </div>

    <div *ngIf="!collapsed && authService.isAuthenticated()" class="sidenav-backdrop">
      <div class="mdi mdi-close" (click)="collapseMenu()"></div>
      <div class="menu-items" fxLayout="column" fxLayoutAlign="start center">
        <h3 class="menu-item" (click)="collapseMenu()" routerLink="/account/profile">Dein Profil</h3>
        <!--h3 class="menu-item" routerLink="/studiexperts/deine-uebersicht">Deine Aktivitäten</h3-->
        <h3 class="menu-item" (click)="authService.logout(); collapseMenu()">Abmelden</h3>
<!--        <h3 class="menu-item" (click)="collapseMenu()"-->
<!--            routerLink="/studiexperts/deine-uebersicht"-->
<!--            [queryParams]="{tab: '1'}">-->
<!--          Benachrichtigungen-->
<!--          <span *ngIf="unseenNotifications.length > 0">({{unseenNotifications.length}})</span></h3>-->
<!--        <div fxLayout="column" fxLayoutAlign="start center">-->
<!--          <div routerLink="/studiexperts/deine-uebersicht"-->
<!--               [queryParams]="{tab: '1'}"-->
<!--               class="notification-preview"-->
<!--               fxLayout="row"-->
<!--               fxLayoutAlign="start center"-->
<!--               *ngFor="let notification of unseenNotifications" >-->
<!--            <i class="mdi mdi-information"></i>-->
<!--            <p>{{notification.text}}</p>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>


    <div *ngIf="!collapsed && !authService.isAuthenticated() " class="sidenav-backdrop">
      <div class="mdi mdi-close" (click)="collapseMenu()"></div>
      <div class="menu-items" fxLayout="column" fxLayoutAlign="start center">
        <!--h3 class="menu-item" (click)="showLoginMask = !showLoginMask">Einloggen
          <i *ngIf="!showLoginMask" class="mdi mdi-chevron-down"></i>
          <i *ngIf="showLoginMask" class="mdi mdi-chevron-up"></i>
        </h3-->
        <app-login [showRegisterButton]="true" [showHeader]="false"></app-login>
<!--    <div *ngIf="!router.url.includes('studiexperts')" class="divider"></div>-->
        <!--h3 routerLink="/account/register" (click)="collapsed = true" class="menu-item">Registrieren</h3-->
      </div>
    </div>


    <div class="brand-container"
         fxLayout="column"
         fxLayoutAlign="center center"
         *ngIf="!collapsed && !authService.isAuthenticated()">
      <!--div class="talentagent-logo"></div-->
      <div class="talentagent-slogan">Auf der Suche nach deinem Traumjob?</div>
      <a href="https://app.talentagent.de/">
        <button mat-flat-button class="ta-cta-button">Hier geht's zu Talentagent</button>
      </a>
      <!--a href="https://app.talentagent.de/onboarding/s2fax/welcome">
        <button mat-flat-button class="ta-secondary-button">Registrieren</button>
      </a-->
      <br>
    </div>


  </div>




</div>
