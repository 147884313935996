import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {ActivatedRoute} from "@angular/router";
import {PublicBlogPostData, PublicFormatPageData} from "../../generated/cms/data";
import {buildContainerImageUrl} from "../../utils.service";
import {Subject} from "rxjs";
import {MediaMatcher} from "@angular/cdk/layout";
import {Meta, Title} from "@angular/platform-browser";
import {MetaService} from "../../meta.service";
import {ClipboardService} from "ngx-clipboard";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";
import {ShareService} from "../../share/share.service";

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit, OnDestroy {

  identifier: string;
  imageSrc: string;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  categoryIdsChange = new Subject<number[]>();

  altText: string = ''

  loading = true;

  page: PublicFormatPageData = <PublicFormatPageData>{
    content: ''
  };

  // @Input() utmShareSource: string;
  @Input() utmCampaignIdent: string;
  @Input() shareText: string;
  @Input() additionalQueryParams: any;

  shareLink;



  constructor(private offerPageResource: PublicOfferPageResource,
              private route: ActivatedRoute,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private meta: Meta,
              private metaService: MetaService,
              private clipboard: ClipboardService,
              private snackBar: HidSnackbarService,
              private shareService: ShareService,
              private titleService: Title) {
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
  }

  post: PublicBlogPostData = <PublicBlogPostData>{}

  @ViewChild("image", { static: true }) image: ElementRef;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.identifier = params.id;
      var imageIdToRequest: number;

      this.offerPageResource.getBlogPage(this.identifier).then(
        result => {
          this.post = result;
          this.altText = result.offerImageAltText;
          // set html title tag
          if (this.post.titleTag !== null && this.post.titleTag !== '') {
            this.titleService.setTitle(this.post.titleTag);
          } else {
            this.metaService.resetTitleTag()
          }

          // set html meta tags
          this.meta.removeTag('name ="title"');
          this.meta.removeTag('name ="description"');
          this.metaService.resetRobotsTag();
          this.metaService.setRobotsTag(this.post.indexPage);
          if (this.post.metaDescription != null) {
            if (this.meta.getTag("description") == null) {
              this.meta.addTag({name: 'description', content: this.post.metaDescription});
            } else {
              this.meta.updateTag({name: 'description', content: this.post.metaDescription});
            }
          } else {
            this.meta.updateTag({
              name: 'description', content: 'Die Hochschulinitiative Deutschland ist dein Partner' +
                ' im Studium: Wir bieten dir interessante Seminare,' +
                ' Vorlagen & tolle Blogartikel für dein erfolgreiches Studium!'
            });
          }

          this.loading = false;
          this.categoryIdsChange.next(this.post.categoryIds);
          if (this.mobileQuery.matches) {
            imageIdToRequest = this.post.mobileImageId
          } else {
            imageIdToRequest = this.post.desktopImageId
          }
          this.imageSrc = buildContainerImageUrl(this.image, imageIdToRequest)
        }
      )
    });

    this.shareLink = '/blog/' + this.identifier + '?utm_source=hidpage' + '&utm_campaign=' + this.utmCampaignIdent + '&utm_medium=hid-share';
    if (this.additionalQueryParams) {
      for (let param of this.additionalQueryParams) {
        this.shareLink += '&' + param.key + '=' + param.value;
      }
    }

  }

  ngOnDestroy() {
    this.metaService.resetRobotsTag()
  }

  /*
  sharelinkToClipBoard() {
    this.clipboard.copyFromContent(this.shareLink);
    this.snackBar.openSnackBar("Link in der Zwischenablage", null, 10000, false);
  }
  */

  initShare(){
    this.shareService.showSharingButtons(this.shareLink, 'blogpost-share', 'Hey, ich habe einen coolen Artikel von der Hochschulinitiative für dich: ', null);
  }
}
