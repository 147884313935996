import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {InternalCookieService} from "../internal-cookie.service";
import {PromotionApiResourceV2} from "../generated/cronos/resources";
import {PublicSeminarData, PublicWebinarDateData} from "../generated/cronos/data";
import {MatDialog} from "@angular/material/dialog";
import {MobileDatepickerDialogComponent} from "../online-lecture/online-lecture-appointment/mobile-datepicker-dialog/mobile-datepicker-dialog.component";
import { WebinarReminderPopupComponent } from './webinar-reminder-popup/webinar-reminder-popup.component';
import {MediaService} from "../media.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'promotion-reminder',
  templateUrl: './promotion-reminder.component.html',
  styleUrls: ['./promotion-reminder.component.scss']
})
export class PromotionReminderComponent implements OnInit, OnDestroy {


  excludedRouteElements = ['angebote', 'thank-you', 'webinar']

  bannerActive: boolean = false;
  imminentWebinarDate?: PublicWebinarDateData = null;
  daysTillSeminar?: number = null;
  minutesTillSeminar?: number = null;
  promRegStateCode: string;

  opened = false;

  constructor(private internalCookieService: InternalCookieService,
              private promotionApiResource: PromotionApiResourceV2,
              private dialog: MatDialog,
              private promotionApiResourceV2: PromotionApiResourceV2,
              private mediaService: MediaService) { }

  ngOnInit(): void {
    //dont open on blocked routes
    for(let e of this.excludedRouteElements){
      if(window.location.href.indexOf(e) > -1)return
    }

    this.checkForUpcomingWebinar()
  }

  close(){
    this.opened = false;
  }

  ngOnDestroy() {
    // this.internalCookieService.signUpCookiesRefreshSubject.unsubscribe()
  }

  checkForUpcomingWebinar() {
    let signUpCookieData = this.internalCookieService.getSeminarCookieKeys()
    let promotionIds = signUpCookieData.map(x => parseInt(x.split("-")[1]))
    if(promotionIds.length > 0) {
      this.promotionApiResource.checkForImminentWebinarDate({promotionIds: promotionIds}).then(
        result => {
            this.imminentWebinarDate = result;
            if(this.imminentWebinarDate != null && Object.keys(this.imminentWebinarDate).length > 0) {
              this.opened = true;
              const oneDay = 24 * 60 * 60 * 1000;
              let dateDiff = new Date(this.imminentWebinarDate.date).getTime() - new Date().getTime()
              this.daysTillSeminar = Math.round(Math.abs(dateDiff / oneDay))
              const oneMinute = 60 * 1000;
              this.minutesTillSeminar = Math.round(dateDiff / oneMinute)
              //+15 minutes threshold
              if(this.minutesTillSeminar <= 30 && this.minutesTillSeminar > -15) {
                this.close()
                this.openReminderPopup()
              }
            } else this.close()
        }
      )
    }
  }

  openReminderPopup() {
    let cookieIdents = this.internalCookieService.getSeminarCookieIdentifiers()
    if (cookieIdents.length > 0) {
      let relevantCookieIdent = cookieIdents.filter(cookieData => cookieData.seminarId == this.imminentWebinarDate.id)[0].cookieIdent
      this.promotionApiResourceV2.getPromRegStateCodeByCookieIdent({cookieIdent: relevantCookieIdent}).then(
        result => {
          //stored value seems to be invalid and therefore has to be cleared
          if (result == null || Object.keys(result).length == 0) {
            this.internalCookieService.deleteSeminarCookieIdentifier(relevantCookieIdent)
          } else {
            let width = '400px'
            if(this.mediaService.isMobile()) {
              width = `${window.screen.width.toString()}px`
            }

            this.promRegStateCode = result.value;
            let dialogRef = this.dialog.open(WebinarReminderPopupComponent, {
              data: {
                imminentWebinarDate: this.imminentWebinarDate,
                minutesTillSeminar: this.minutesTillSeminar,
                promRegStateCode: this.promRegStateCode
              },
              id: "webinar-website-reminder-popup",
              width: width,
              maxWidth: '100%',
              panelClass: 'overflow-dialog'

            });

            dialogRef.afterClosed().subscribe(result => {

            })
          }
        })


    }
  }

}
