import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscountsComponent } from './discounts/discounts.component';
import {RouterModule, Routes} from "@angular/router";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import { WeeklyUpdateTimerComponent } from './weekly-update-timer/weekly-update-timer.component';
import { DiscountCardDesktopComponent } from './discount-card-desktop/discount-card-desktop.component';
import { DiscountCardMobileComponent } from './discount-card-mobile/discount-card-mobile.component';
import {MatButtonModule} from "@angular/material/button";
import { UpgradeAccountDialogComponent } from './upgrade-account-dialog/upgrade-account-dialog.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {MatTooltipModule} from "@angular/material/tooltip";
import { CouponInfoDialogComponent } from './coupon-info-dialog/coupon-info-dialog.component';
import { TermsAndConditionsDiscountsComponent } from './terms-and-conditions-discounts/terms-and-conditions-discounts.component';
import {AccountModule} from "../account/account.module";

//routing is deactivated for the moment - deals comp remains until it is needed later on
/*
const appRoutes: Routes = [
  {path: 'deals', component: DiscountsComponent, canActivate: [AccountAuthGuardService]},
  {path: 'deals-terms-and-conditions', component: TermsAndConditionsDiscountsComponent, canActivate: [AccountAuthGuardService]}
];
*/

@NgModule({
  declarations: [DiscountsComponent, WeeklyUpdateTimerComponent, DiscountCardDesktopComponent, DiscountCardMobileComponent, UpgradeAccountDialogComponent, CouponInfoDialogComponent, TermsAndConditionsDiscountsComponent],
    imports: [
        CommonModule,
        //RouterModule.forChild(appRoutes),
        FlexLayoutModule,
        FlexModule,
        MatButtonModule,
        ClipboardModule,
        MatTooltipModule,
        AccountModule
    ],

    exports: [
        RouterModule,
        WeeklyUpdateTimerComponent
    ]
})
export class DiscountsModule { }
