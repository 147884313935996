import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorPageComponent} from "./error-page/error-page.component";
import {RouterModule, Routes} from "@angular/router";
import {ContactSupportModalModule} from "../contact-support-modal/contact-support-modal.module";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {ErrorPageInvalidLinkComponent} from './error-page-invalid-link/error-page-invalid-link.component';

const appRoutes: Routes = [
  {path: 'error', component: ErrorPageComponent},
  {path: 'error/invalid-link', component: ErrorPageInvalidLinkComponent}
];

@NgModule({
  declarations: [ErrorPageComponent, ErrorPageInvalidLinkComponent],
  imports: [
    FlexModule,
    FlexLayoutModule,
    CommonModule,
    RouterModule.forChild(appRoutes),
    ContactSupportModalModule
  ]
})
export class ErrorModule { }
