import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StandardCheckoutS2ProcessService} from '../standard-checkout-s2-process.service';

@Component({
  selector: 'app-sta-checkout-s2-entry',
  templateUrl: './sta-checkout-s2-entry.component.html',
  styleUrls: ['./sta-checkout-s2-entry.component.sass']
})
export class StaCheckoutS2EntryComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private standard2ProcessService: StandardCheckoutS2ProcessService) {
  }

  ngOnInit(): void {
    //route to first step
    this.router.navigate([this.standard2ProcessService.prepareSplitRoutingUrl(this.route, 'checkout/standard/s2/location')]);
  }

}
