<div *ngFor="let weekItem of segmentedDates">
  <div *ngIf="weekItem.dates.length > 0">
    <div class="week-header w-100" *ngIf="weekItem.weeksFromNow == 0">Diese Woche</div>
    <div class="week-header w-100" *ngIf="weekItem.weeksFromNow == 1">Nächste Woche</div>
    <div class="week-header w-100" *ngIf="weekItem.weeksFromNow > 1">In {{weekItem.weeksFromNow}} Woche<span
      *ngIf="weekItem.weeksFromNow > 1">n</span></div>
    <div class="dateItem" fxLayout="row" fxLayoutAlign="space-between center"
         *ngFor="let promotion of weekItem.dates">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" style="white-space: nowrap;"><i fxHide.xs
                                                                                         class="fa fam fa-calendar-alt"></i>  {{promotion.date | date : 'EEE, dd.MM.yyyy'}}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center"><i fxHide.xs
                                                            class="far fam fa-clock"></i>
          {{promotion.date | date : 'HH:mm'}}
          -
          {{promotion.endTime | date : 'HH:mm'}}
        </div>
        <div fxHide fxShow.xs style="color: #777;font-style: italic;font-size: 12px">
            <span *ngIf="promotion.type === 'OfflineSeminarDate'"><i
              class="fa fam fa-map-marker-alt"></i>Vor Ort</span>
          <span *ngIf="promotion.type.indexOf('WebinarDate') > -1"> <i
            class="fas fa-globe fam"></i>Online-Termin</span>
        </div>
      </div>
      <div class="isu-padding-2x" fxHide.xs style="color: #777;">
          <span *ngIf="promotion.type === 'OfflineSeminarDate'"><i
            class="fa fam fa-map-marker-alt"></i>{{promotion.address}}</span>

        <span *ngIf="promotion.type.indexOf('WebinarDate') > -1"><i
          class="fas fa-globe fam"></i>Online-Termin</span>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <button class="hid-button secondary outline expand"
                [class.outline]="selectedPromotionId && selectedPromotionId != promotion.id"
                (click)="selectPromotion(promotion)">
          {{getButtonText(promotion)}}
        </button>
        <span class="freeSlots" *ngIf="promotion.freeSlots == 1">{{promotion.freeSlots}} Platz frei</span>
        <span class="freeSlots" *ngIf="promotion.freeSlots != 1">{{promotion.freeSlots}} Plätze frei</span>
      </div>
    </div>
  </div>
</div>
