import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'hid-progress-spinner',
  templateUrl: './hid-progress-spinner.component.html',
  styleUrls: ['./hid-progress-spinner.component.scss']
})
export class HidProgressSpinnerComponent implements OnInit {

  @Input() diameter: number = 20;

  constructor() {
  }

  ngOnInit() {
  }

}
