import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FeedbackSelectorService, SeminarIdentifier, SeminarUrlIdentifier} from "./utils/feedback-selector.service";
import {MetaService} from "../meta.service";
import {FeedbackDisplayData} from "../generated/cronos/data";
import {PromotionApiResourceV2} from "../generated/cronos/resources";
import {MediaService} from "../inno-utils/media.service";


@Component({
  selector: 'app-feedback-overview',
  templateUrl: './feedback-overview.component.html',
  styleUrls: ['./feedback-overview.component.scss']
})
export class FeedbackOverviewComponent implements OnInit {

  feedback: FeedbackDisplayData[] = [];
  initialized: boolean = false;
  feedbackLoading: boolean = false;
  pageSize: number = 10;
  page: number = 0;
  totalElements: number;
  filterForComments: boolean = true;
  seminarIdentity : number

  loadingSkeletonArray = new Array<number>(this.pageSize)
  constructor(
    private route:ActivatedRoute,
    private metaService: MetaService,
    private promotionApiResourceV2: PromotionApiResourceV2,
    public selectorService: FeedbackSelectorService,
    public mediaService: MediaService,
  ) {
  }

  ngOnInit(): void {
    this.route.url.subscribe( url =>{
      url.length > 1 ? this.determineSeminarIdentity(url[1].path) : this.seminarIdentity = SeminarIdentifier.All
      this.page = 0
      this.pageSize = 10
      this.loadFeedbackDataPage(this.page)
      }
    )

    this.metaService.setMetaTag("description", "Was denken Studenten über die Hochschulinitiative? Das Seminar-Feedback zu Steuer, Excel, Thesis & Co. findest du hier!")
    this.metaService.setCustomTitleTag("Erfahrungen zu den Workshops | Hochschulinitiative Deutschland")
  }

  determineSeminarIdentity(urlSegment: String){
    switch (urlSegment){
      case SeminarUrlIdentifier.Steuer:
        this.seminarIdentity = SeminarIdentifier.Steuer
        return
      case SeminarUrlIdentifier.Thesis:
        this.seminarIdentity = SeminarIdentifier.Thesis
        return
      case SeminarUrlIdentifier.Excel:
        this.seminarIdentity = SeminarIdentifier.Excel
        return
      case SeminarUrlIdentifier.Finance:
        this.seminarIdentity = SeminarIdentifier.Finance
        return
      case SeminarUrlIdentifier.Fin4Women:
        this.seminarIdentity = SeminarIdentifier.Fin4Women
        return
    }
  }

  loadFeedbackDataPage(page: number) {
    this.feedbackLoading = true;

    this.feedback = []
    this.page = page

    this.promotionApiResourceV2.getRatingsByPromotionType({
      formatIds: this.selectorService.formatToIds[this.seminarIdentity].ids,
      filterForCommentExists: this.filterForComments,
      page: this.page,
      pageSize: this.pageSize
    }).then(
      result => {
        result.content.forEach(value => {
          this.feedback.push(value)
        })
        this.pageSize += this.pageSize
        this.loadingSkeletonArray.length = this.pageSize
        this.totalElements = result.totalElements;
        this.feedbackLoading = false;
        this.initialized = true;
      }
    )
  }

  filterFeedbacksForComments(filter: boolean){
    this.filterForComments = filter
    this.loadFeedbackDataPage(0)
  }

  protected readonly Array = Array;
}
