<div class="content-wrapper" [ngClass.lt-sm]="'xtra-padding-bottom'">
  <hid-loading-spinner [loadingBool]="loading && loadingLesson">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!loading && !loadingLesson">
      <div *ngFor="let lesson of format.lessons; let i = index">
        <div *ngIf="currentStep == i">

          <div class="header" fxLayout="column" fxLayoutAlign="start center">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="title-wrapper">
              <div class="title">{{format.title}}</div>
              <button class="hid-button secondary inverse" (click)="initShare()"><span
                fxHide.xs>Angebot teilen </span><i
                class="fa fa-share-alt"></i></button>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="lesson-title">
              <h3 fxHide.xs fxShow>{{currentLesson.title}}</h3>

              <div class="lesson-dropdown width-100" fxHide fxShow.xs>

                <div (click)="toggleMenu()" fxLayout="row" fxLayoutAlign="space-between center">
                  <span>&nbsp;</span>
                  <span> Lektion {{currentStep + 1}} </span>
                  <i class="fa fa-angle-down"></i>
                </div>
                <div *ngIf="menuOpen" class="menu-open lesson-wrapper" (clickOutside)="toggleMenu()">
                  <div *ngFor="let lesson of format.lessons; let i = index"
                       class="lesson ita_stepper-go-to-lesson"
                       (click)="goToStep(i)">
                    Lektion {{i + 1}}: {{lesson.title}}
                  </div>
                  <div class="lesson ita_stepper-go-to-appointment" *ngIf="showAppointmentStep"
                       (click)="goToAppointment()">
                    Teil 2 des Online-Seminars
                  </div>
                  <div class="lesson ita_stepper-go-to-summary"  *ngIf="showSummaryStep"
                       (click)="goToSummary()">
                    Zusammenfassung
                  </div>

                </div>

              </div>

              <div class="wrapper-progressBar" [fxFlex]="numberOfSteps < 5 ? 30 : 45" fxHide.xs fxShow>
                <ul class="progressBar">
                  <li *ngFor="let lesson of getArray(numberOfSteps); let stepperIndex = index"
                      class="ita_stepper-go-to-lesson"
                      [ngClass]="{'active' : stepperIndex <= i}"
                      (click)="goToStep(stepperIndex)"
                      [ngStyle]="{'width':  100/(numberOfSteps+2)+'%'}">
                    <span class="stepper-index">{{stepperIndex + 1}}</span>
                  </li>
                  <li *ngIf="showAppointmentStep"
                    [ngClass]="{'active' : i == numberOfSteps+1}"
                    class="ita_stepper-go-to-appointment"
                    (click)="goToAppointment()"
                    [ngStyle]="{'width':  100/(numberOfSteps+2)+'%'}">
                    <span class="stepper-index">{{numberOfSteps + 1}}</span>
                  </li>
                  <li *ngIf="showSummaryStep"
                    [ngClass]="{'active' : i == numberOfSteps+2}"
                    class="ita_stepper-go-to-summary"
                    (click)="goToSummary()"
                    [ngStyle]="{'width':  100/(numberOfSteps+2)+'%'}">
                    <span class="stepper-index" *ngIf="showAppointmentStep" >{{numberOfSteps + 2}}</span>
                    <span class="stepper-index" *ngIf="!showAppointmentStep">{{numberOfSteps + 1}}</span>
                  </li>
                </ul>
              </div>
            </div>
            <p class="description" fxHide.xs fxShow>{{currentLesson.description}}</p>
          </div>
          <div fxHide fxShow.xs class="lesson-title">{{currentLesson.title}}</div>
          <div class="video">
            <iframe [width]="getVideoResolutionWidth()" [height]="getVideoResolutionHeight()"
                    [src]="videoUrl" data-hj-allow-iframe="" allowfullscreen>
            </iframe>
          </div>
          <div class="controls" fxLayout="row" fxLayoutAlign="space-between center">
            <button class="hid-button secondary inverse margin-0 ita-previous-lesson"
                    *ngIf="i > 0"
                    (click)="previousLesson()"><i class="fa fa-chevron-left"></i> Zurück
            </button>
            <div *ngIf="i == 0" fxFlex="33"></div>
            <button class="hid-button primary margin-0"
                    [ngClass]="{'ita_request-appointment' : !this.onlineLectureService.date, 'ita_check-appointment-date' : this.onlineLectureService.date}"
                    fxHide.lt-sm fxShow
                    *ngIf="currentLesson.appointmentContentConfig?.showAppointmentButton"
                    (click)="openAppointmentDialog()">
              <i class="fa fa-calendar-alt"></i> {{getAppointmentButtonText()}}
            </button>
            <button class="hid-button inverse primary ita_next-lesson"
                    (click)="nextLesson()"
                    [ngClass]="{'margin-left': i == 0}"
                    *ngIf="i < numberOfSteps - 1">
              <span fxHide fxShow.xs>Weiter </span>
              <span fxHide.xs fxShow>Weiter zur nächsten Lektion </span>
              <i class="fa fa-chevron-right"></i>
            </button>
            <button class="hid-button primary ita_go-to-appointment"
                    (click)="goToAppointment()"
                    *ngIf="i == numberOfSteps - 1 && showAppointmentStep"> Weiter <i class="fa fa-chevron-right"></i>
            </button>
            <button class="hid-button primary ita_stepper-go-to-summary"
                    (click)="goToSummary()"
                    *ngIf="i == numberOfSteps - 1  && !showAppointmentStep && showSummaryStep"> Weiter <i class="fa fa-chevron-right"></i>
            </button>
          </div>

          <p class="description" fxHide fxShow.xs>{{currentLesson.description}}</p>
        </div>
      </div>
    </div>
  </hid-loading-spinner>
</div>
<div id="mobile-cta" fxLayout="row" fxLayoutAlign="center center" fxShow.lt-sm fxHide
     *ngIf="!dialogOpen && !date && currentLesson && currentLesson.appointmentContentConfig.showAppointmentButton && !this.onlineLectureService.date">
  <button
    class="hid-button cta-button primary ita_mobile-appointment-menu"
    (click)="openDatePicker()">
    <span>Termin anfordern</span>
    <!--<hid-progress-spinner *ngIf="loading.getValue()"></hid-progress-spinner>-->
  </button>
</div>
