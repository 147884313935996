import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {SidenavService} from '../account-sidenav/sidenav.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showPasswordHint: boolean = false;
  loginInProgress: boolean = false;
  showBadCredentialError: boolean = false;
  @Input() showHeader: boolean = true;
  @Input() showRegisterButton: boolean = false;

  @Output() loggedInSuccessfully: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closingDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() integratedInPage: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private sidenavService: SidenavService
  ) {
  }

  email = new FormControl('');
  password = new FormControl('');

  ngOnInit(): void {
  }

  login() {
    this.loginInProgress = true;
    this.authService.login(this.email.value, this.password.value)
      .subscribe(result => {
          //console.log('Login Attempt: ' + result)
          this.loginInProgress = false;
          if (result == 'LoginSuccessful') {
            this.sidenavService.reportProfilePicChange();
            this.showBadCredentialError = false;
            // location.reload();
            if (!this.integratedInPage) {
              //this.router.navigateByUrl("/deals")
            }
            this.loggedInSuccessfully.emit(true);
          } else if (result == 'LoginFailedCredentialsIncorrect') {
            this.showBadCredentialError = true;
          }
        }
      );
  }

  closeDialog() {
    this.closingDialog.emit(true);
  }
}
