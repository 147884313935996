<div id="wrapper" #wrapper>
  <div class="search-input-wrapper">
    <div id="output-field-wrapper" (click)="toggleDropdown()"
         [ngClass]="{'filled': (multiple&& control.value.length > 0) || (!multiple && (control.value || nullable)), 'focussed': open, 'disabled':disabled, 'invalid': !control.valid && wasOpened,
         'validationForced': validationForced}">
      <div id="label">{{label}}</div>
      <div id="output-field" [className]="inputFieldClass">
        <span *ngIf="!multiple">{{getDisplayProperty(getOptionByIdentifier(control.value))}}</span>
        <span *ngIf="multiple">{{getDisplayProperty(getOptionByIdentifier(control.value[0]))}}
          <span *ngIf="control.value.length > 1"> + {{control.value.length - 1}}</span>
        </span>

        <i *ngIf="showValidatorIcons" class="validator-icon fas fa-minus-circle text-red"
           [ngClass]="{'show': !control.valid}"></i>
        <i *ngIf="showValidatorIcons" class="validator-icon far fa-check-circle text-green"
           [ngClass]="{'show': control.valid}"></i>
      </div>
      <div id="open-dropdown">
        <i *ngIf="this.open" class="fas fa-times" style="margin: 0 10px"></i>
        <i *ngIf="!this.open" class="fas fa-chevron-down" style="margin: 0 10px"></i>
      </div>

    </div>
    <div id="proposition-container-desktop" *ngIf="open && !mobileQuery.matches"
         (clickOutside)="clickOutsideHandler($event)"
    >
      <hid-loading-spinner [loadingBool]="loading">
        <div class="search-input" *ngIf="searchable">
          <i class="fas fa-search"></i>
          <input placeholder="Suche..." [formControl]="searchTerm"/>
        </div>

        <div class="option" fxLayout="row"
             *ngFor="let option of filteredOptions" (click)="setModel(option)"
             [ngClass]="{'selected': isSelected(option)}">
          {{getDisplayProperty(option)}}
        </div>
      </hid-loading-spinner>
    </div>

    <div id="proposition-container-mobile" *ngIf="mobileQuery.matches && open">
      <div id="proposition-container-backdrop">
        <div id="proposition-container-modal"  [ngClass]="{'multiple': multiple}">
          <div id="headline" fxLayout="row" fxLayoutAlign="end center">
            <button class="hid-icon-button" (click)="toggleDropdown()"><i class="fas fa-times"></i></button>
          </div>
            <div class="search-input" *ngIf="searchable">
              <i class="fas fa-search"></i>
              <input placeholder="Suche..." [formControl]="searchTerm" id="mobile-search-input"/>
            </div>
            <div class="options-wrapper"

            >
              <div class="option" fxLayout="row"
                   *ngFor="let option of filteredOptions" (click)="setModel(option)"
                   [ngClass]="{'selected': isSelected(option)}">
                <i class="far fa-circle"></i>
                <i class="fas fa-circle"></i>
                {{getDisplayProperty(option)}}
              </div>
            </div>
          <div id="finish-button" fxLayout="row" fxLayoutAlign="center center" *ngIf="multiple">
            <button class="hid-button secondary inverse" (click)="toggleDropdown()">
              Fertig
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
