import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {OfferPageBannerGroupData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.OfferPageBannerController
 */

@Injectable()
@ResourceParams({})
export class OfferPageBannerResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/banners/{id}/inline',
        endpoint: 'cms'
    })
    private _getInlineBannerPicture: IResourceMethodObservableStrict<void, {}, {id: number}, Blob>;
    getInlineBannerPicture(id: number): Promise<Blob> {
        return this._getInlineBannerPicture(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/{id}/banners',
        endpoint: 'cms'
    })
    private _getOfferPageBannerGroups: IResourceMethodObservableStrict<void, {}, {id: number}, OfferPageBannerGroupData[]>;
    getOfferPageBannerGroups(id: number): Promise<OfferPageBannerGroupData[]> {
        return this._getOfferPageBannerGroups(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/offerPages/banners/{id}/side',
        endpoint: 'cms'
    })
    private _getSideBannerPicture: IResourceMethodObservableStrict<void, {}, {id: number}, Blob>;
    getSideBannerPicture(id: number): Promise<Blob> {
        return this._getSideBannerPicture(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/offerPages/banners/{id}/inline',
        endpoint: 'cms'
    })
    private _uploadInlineBannerPicture: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, void>;
    uploadInlineBannerPicture(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<void> {
        return this._uploadInlineBannerPicture(requestBody, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/offerPages/banners/{id}/side',
        endpoint: 'cms'
    })
    private _uploadSideBannerPicture: IResourceMethodObservableStrict<ArrayBuffer, {fileName: string}, {id: number}, void>;
    uploadSideBannerPicture(requestBody: ArrayBuffer, id: number, requestParams: {fileName: string}): Promise<void> {
        return this._uploadSideBannerPicture(requestBody, requestParams, {id: id}).toPromise()
    }

}