<div class="main-wrapper" fxLayout.lt-md="column" fxLayout.gt-sm="row">


  <div id="register-section" fxLayout="column" fxLayoutAlign="center center">
    <img src="../../../../assets/img/uniwunder-logo.png" class="uniwunder-logo register-logo">
    <h2>Melde dich bei Uniwunder an und erhalte Zugang zu allen, exklusiven Angeboten!</h2>
    <div style="max-width: 480px;">
      <app-register></app-register>
    </div>
  </div>

  <div id="info-side-bar" fxLayout="column" fxLayoutAlign="center center">
    <div class="register-background-overlay"></div>
    <!-- img src="../../../../assets/img/uniwunder-logo.png" class="uniwunder-logo register-logo"-->
    <h1>Unsere Angebote</h1>
    <div class="register-info-box" fxLayout="column" fxLayoutAlign="start start">
      <img src="../../../../assets/img/brandlogo.png" class=" offer-logo">
      <p>Als eine der größten Studierendenplattformen Deutschlands begleiten wir dich während deines Studiums und auch darüber hinaus. Bei uns findest du nützliche Seminare, tolle Tipps für dein Studium, und Angebote, mit denen du dir deine Studienzeit leichter machst.</p>

      <!--      <div class="divider"></div>-->

    </div>

  </div>
</div>
