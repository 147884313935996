import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Component({
  selector: 'app-no-token-redirekt-dialog',
  templateUrl: './no-token-redirect-dialog.component.html',
  styleUrls: ['./no-token-redirect-dialog.component.scss']
})
export class NoTokenRedirectDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<NoTokenRedirectDialogComponent>,
    private router: Router,
  ) {
  }

  ngOnInit() {
  }

  goToOfferPage() {
    this.router.navigateByUrl("/angebote/" + this.data);
    this.dialogRef.close(true)
  }

  close(result: boolean = false) {
    this.dialogRef.close(result);
    this.redirectToHome();
  }

  redirectToHome() {
    this.router.navigate(["home"], {queryParamsHandling: 'preserve'})
  }

}
