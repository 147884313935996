import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ContactSupportModalComponent} from "../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {Router} from "@angular/router";
import {HidSnackbarService} from "../../hid-interactions/hid-snackbar/hid-snackbar.service";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  constructor(private modal: NgbModal, private router: Router, private snackBar: HidSnackbarService) { }

  ngOnInit() {
  }
  supportModal(){
    const modalref = this.modal.open(ContactSupportModalComponent)
    modalref.componentInstance.messagePlaceholder = "Was ist passiert?"
  }
  goHome(){
    this.router.navigate(['/home'],{queryParamsHandling: 'preserve'})
  }
}
