<!--<section class="config-header" *ngIf="!mediaService.isMobile()">-->
<!--  <div class="config-header-text">-->
<!--    <h1>-->
<!--      Willkommen im-->
<!--      <span *ngIf="configType == 'Thesis'">Abschlussarbeiten-Konfigurator!</span>-->
<!--      <span *ngIf="configType == 'Script'">Skript-Konfigurator!</span>-->
<!--    </h1>-->
<!--    <p>Studyscript x Hochschulinitiative</p>-->
<!--  </div>-->

<!--  <a (click)="switchConfigType()">-->
<!--    <span *ngIf="configType == 'Thesis'">Ich möchte lieber ein Skript drucken</span>-->
<!--    <span *ngIf="configType == 'Script'">Ich möchte lieber eine Abschlussarbeit drucken</span>-->
<!--  </a>-->

<!--</section>-->

<hid-loading-spinner [loadingSubject]="iframeLoading">

  <div class="iframe-wrapper">
    <div id="studyscript-iframe"></div>
  </div>

</hid-loading-spinner>

