import {Component, Inject, OnInit} from '@angular/core';
import {OnlineLectureInfoData} from "../../generated/cronos/data";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OnlineLectureAPIResourceV2, PromotionApiResourceV2} from "../../generated/cronos/resources";
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {ContactSupportModalComponent} from "../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {MediaService} from "../../inno-utils/media.service";
import {LocalStorageService} from "../../local-storage.service";
import {OnlineLectureService} from "../online-lecture.service";

@Component({
  selector: 'app-enter-online-lecture',
  templateUrl: './enter-online-lecture.component.html',
  styleUrls: ['./enter-online-lecture.component.scss']
})
export class EnterOnlineLectureComponent implements OnInit {

  token: string;
  state: Boolean;
  cronosFormatId;

  iteminfo: OnlineLectureInfoData = <OnlineLectureInfoData>{}
  recommendedUpsellPageIds: number[] = []

  loadingInfo = true;
  navigating = false;
  downloaded = false;

  offerPageId;

  seminarId;
  localSplitWasActive;
  waitingListSignup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private modal: NgbModal,
    private mediaService: MediaService,
    private cronosApi: OnlineLectureAPIResourceV2,
    private offerPageResource: PublicOfferPageResource,
    public localStorageService: LocalStorageService,
    public onlineLectureService: OnlineLectureService,
    @Inject("env") private environment
  ) {
  }

  ngOnInit() {
    this.route.snapshot.params.code ? this.token = this.route.snapshot.params.code : this.token = this.route.snapshot.params.token;
    this.localStorageService.setLectureAuthToken(this.token);
    this.state = (this.route.snapshot.params.state === 'true');
    this.cronosFormatId = this.route.snapshot.params.cronosFormatId;

    this.getItemInfo();
  }


  getItemInfo() {
    this.loadingInfo = true;
    if (this.onlineLectureService.getToken(this.cronosFormatId) != 'null') {
      this.onlineLectureService.loadFormatInitially(this.cronosFormatId).then(format => {
        this.iteminfo = format;
        this.loadingInfo = false;
      });

      this.onlineLectureService.loadOfferPage(this.cronosFormatId).then(result => {
        this.recommendedUpsellPageIds = this.onlineLectureService.recommendedUpsellPageIds;
        this.offerPageId = result.offerPageId
      });
    } else {
      this.loadingInfo = false;
    }
  }

  timeLeft: number;
  interval;

  checkForDateInFutureThenReturnTrue() :boolean{
    let dateString = this.iteminfo.expiry.toString().substring(0, 4) + '/' + this.iteminfo.expiry.toString().substring(5, 7) + '/' + this.iteminfo.expiry.toString().substring(8, 10) + ' ' + this.iteminfo.expiry.toString().substring(11, 17) + '00'
    let expiry = new Date(dateString)

    if(expiry > new Date()) {
      return true
    } else return false
  }

  getExpiryTime() {
    // generate date string to fix weird IE error... don't ask
    let dateString = this.iteminfo.expiry.toString().substring(0, 4) + '/' + this.iteminfo.expiry.toString().substring(5, 7) + '/' + this.iteminfo.expiry.toString().substring(8, 10) + ' ' + this.iteminfo.expiry.toString().substring(11, 17) + '00'
    let expiryDate: Date = new Date(dateString)

    expiryDate.setHours(expiryDate.getHours() - 1);
    this.timeLeft = expiryDate.setDate(expiryDate.getDate() - 1) - Date.now();

    this.interval = setInterval(function () {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }
    }, 1000)
  }

  navigateToFirstLesson() {
    this.navigating = true;
    this.router.navigate(["onlinelecture/" + this.iteminfo.formatId + "/validate/" + this.onlineLectureService.getToken(this.cronosFormatId)], {
    });
  }

  navigateToThankYouPage() {
    this.router.navigate(["/thank-you"], {
      queryParamsHandling: "preserve"
    });
  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }


}
