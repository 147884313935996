import {Injectable} from '@angular/core';
import {FullCustomDimensionsSet} from "./full-custom-dimensions-set";
import {DataLayerCustomDimensions} from "./data-layer-custom-dimensions.enum";
import {PartialCustomDimensionsSet} from "./partial-custom-dimensions-set";

@Injectable({
  providedIn: 'root'
})
export class DataLayerCustomDimensionsService {
  private currentSet: FullCustomDimensionsSet;

  constructor() {
    this.generateEmptyDimensionsSet();
  }

  set dimensions(dimensions: PartialCustomDimensionsSet) {
    Object.keys(DataLayerCustomDimensions).map(key => DataLayerCustomDimensions[key])
      .forEach(key => {
        this.currentSet[key] = dimensions[key]
      });
  }

  trigger() {
    (<any>window).dataLayer.push(this.currentSet);
  }

  private generateEmptyDimensionsSet() {
    this.currentSet = {
      [DataLayerCustomDimensions.PageTitle]: undefined,
      [DataLayerCustomDimensions.UrlParams]: undefined,
      [DataLayerCustomDimensions.UtmSource]: undefined,
      [DataLayerCustomDimensions.UtmContent]: undefined,
      [DataLayerCustomDimensions.UtmMedium]: undefined,
      [DataLayerCustomDimensions.UtmCampaign]: undefined,
      [DataLayerCustomDimensions.ItmCampaign]: undefined,
      [DataLayerCustomDimensions.ItmAdset]: undefined,
      [DataLayerCustomDimensions.ItmCreative]: undefined,
    };
  }
}
