import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { LinkHubPageComponent } from './link-hub-page/link-hub-page.component';
import {RouterModule, Routes} from "@angular/router";
import {MatCardModule} from "@angular/material/card";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatIconModule} from "@angular/material/icon";
import {PublicLinkHubResource} from "../generated/cms/resources";

const appRoutes: Routes = [
  {path: 'linkhub/:pathName', component: LinkHubPageComponent},
];

@NgModule({
  declarations: [
    LinkHubPageComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(appRoutes),
        MatCardModule,
        FormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        DragDropModule,
        MatIconModule,
        NgOptimizedImage
    ],
  exports: [
    RouterModule
  ],
  providers: [
    PublicLinkHubResource
  ]
})
export class LinkHubModule { }
