import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../auth.service";
import {AccountResource} from "../../generated/cronos/resources";

@Component({
  selector: 'app-token-login',
  templateUrl: './token-login.component.html',
  styleUrls: ['./token-login.component.sass']
})
export class TokenLoginComponent implements OnInit {

  loginToken: string

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountResource: AccountResource,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.loginToken = params['token'];
      this.accountResource.loginWithToken(this.loginToken).then(jwtToken => {

          let redirectPath: string;

          if (jwtToken.value == null) {
            this.router.navigate(['/account/login']);
          } else {
            this.authService.setToken(jwtToken.value).subscribe(() => this.router.navigate(['/account/profile']))
          }
        }
      )
    })
  }
}
