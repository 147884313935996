import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, CreateOfferBundleData, InternalOfferBundleData, PagedData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.OfferPageBundleController
 */

@Injectable()
@ResourceParams({})
export class OfferPageBundleResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/bundles',
        endpoint: 'cms'
    })
    private _createBundle: IResourceMethodObservableStrict<CreateOfferBundleData, {}, {}, JsonWrappedValue<number>>;
    createBundle(requestBody: CreateOfferBundleData): Promise<JsonWrappedValue<number>> {
        return this._createBundle(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/bundles/{id}',
        endpoint: 'cms'
    })
    private _deleteBundle: IResourceMethodObservableStrict<void, {}, {id: number}, void>;
    deleteBundle(id: number): Promise<void> {
        return this._deleteBundle(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/bundles',
        endpoint: 'cms'
    })
    private _getAllBundles: IResourceMethodObservableStrict<void, {query?: string, page?: number, pageSize?: number}, {}, PagedData<InternalOfferBundleData>>;
    getAllBundles(requestParams?: {query?: string, page?: number, pageSize?: number}): Promise<PagedData<InternalOfferBundleData>> {
        return this._getAllBundles(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/bundles/{id}',
        endpoint: 'cms'
    })
    private _getBundle: IResourceMethodObservableStrict<void, {}, {id: number}, InternalOfferBundleData>;
    getBundle(id: number): Promise<InternalOfferBundleData> {
        return this._getBundle(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/bundles/{id}',
        endpoint: 'cms'
    })
    private _updateBundle: IResourceMethodObservableStrict<InternalOfferBundleData, {}, {id: number}, void>;
    updateBundle(requestBody: InternalOfferBundleData, id: number): Promise<void> {
        return this._updateBundle(requestBody, null, {id: id}).toPromise()
    }

}