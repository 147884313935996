import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {SafeHtmlPipe} from "./safe-html.pipe";
import { GDPRConsentComponent } from '../gdprconsent/gdprconsent/gdprconsent.component';
import {GDPRConsentService} from "../gdprconsent/gdprconsent.service";
import {InternalCookieService} from "../internal-cookie.service";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {StringSearchPipe} from "./string-search.pipe";

@NgModule({
  declarations: [
    SafeHtmlPipe,
    StringSearchPipe
  ],
  imports: [
    CommonModule,
    FlexModule,
    MatSnackBarModule,
    FlexLayoutModule
  ],
  providers:[InternalCookieService],
  exports:[
    SafeHtmlPipe,
    StringSearchPipe
  ]
})
export class GlobalModule { }
