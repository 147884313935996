import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {ProfileComponent} from './profile/profile.component';
import {RegisterComponent} from './register/register.component';
import {RouterModule, Routes} from '@angular/router';
import {GlobalModule} from '../global/global.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './auth.service';
import {AccountResource} from '../generated/cronos/resources';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ConfirmEmailComponent} from './confirm-email/confirm-email.component';
import {HidInteractionsModule} from '../hid-interactions/hid-interactions.module';
import {InnoUtilsModule} from '../inno-utils/inno-utils.module';
import {MatButtonModule} from '@angular/material/button';
import {TokenLoginComponent} from './token-login/token-login.component';
import {AccountSidenavComponent} from './account-sidenav/account-sidenav.component';
import {EditAvatarDialogComponent} from './profile/edit-avatar-dialog/edit-avatar-dialog.component';
import {RegisterPageComponent} from './register/register-page/register-page.component';
import {ConfirmEmailDialogComponent} from './confirm-email-dialog/confirm-email-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {AuthGuard} from './auth.guard';

const appRoutes: Routes = [
  {path: 'account/login', component: LoginComponent},
  {path: 'account/profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: 'account/register', component: RegisterComponent},
  {path: 'account/register-page', component: RegisterPageComponent},
  {path: 'account/password-reset', component: PasswordResetComponent},
  {path: 'token/login', component: TokenLoginComponent},
  {path: 'account/confirm-email', component: ConfirmEmailComponent}
];

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    PasswordResetComponent,
    TokenLoginComponent,
    ConfirmEmailComponent,
    AccountSidenavComponent,
    EditAvatarDialogComponent,
    RegisterPageComponent,
    ConfirmEmailDialogComponent
  ],
  imports: [
    CommonModule,
    GlobalModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(appRoutes),
    FlexLayoutModule,
    HidInteractionsModule,
    InnoUtilsModule,
    MatButtonModule,
    MatDialogModule,
  ],
  exports: [
    RouterModule,
    LoginComponent,
    RegisterComponent,
    AccountSidenavComponent
  ],
  providers: [
    AuthService,
    AccountResource
  ]
})
export class AccountModule {
}
