<div class="actionlink-page-wrapper">
  <div class="actionlink-main-box">
    <h1>Danke für dein Feedback</h1>
    <p>Damit du die Chance doch noch nutzen kannst, hole dir einfach einen neuen Termin!</p>

    <div id="new-termin" class="ita-get-new-appointment-div" fxLayout="row" fxLayoutAlign="space-around center" (click)="newTermin()">
      <div>
        <i class="far fa-clock icon"></i>
      </div>
      <div class="text">Neuen Termin holen</div>
      <div>
        <i class="fas fa-arrow-circle-right"></i>
      </div>
    </div>

  </div>
</div>




<div id="upsell" fxLayout="column" fxLayoutAlign="start center">
  <div fxLayout="column" fxLayoutAlign="start center" *ngIf="recommendedUpsellPageIds.length > 0">

    <h1>Das könnte dich auch interessieren:</h1>
    <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
      <app-offer-page-panel
        [queryParams]="{utm_source:'hidpage',utm_medium:'thankyoupage', utm_campaign:'thankyoupage-offer-caroussel'}"
        [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">
      </app-offer-page-panel>
    </div>
  </div>

  <div *ngIf="recommendedUpsellPageIds.length == 0" fxLayout="column" fxLayoutAlign="center center" id="to-main-page">
    <div class="explanation">Wir haben noch andere spannende Seminare und Aktionen für dich</div>
    <img src="../../../../../assets/img/seminars2.jpg">
    <button class="hid-button secondary" (click)="goHome()">Weiterstöbern</button>
  </div>
</div>

