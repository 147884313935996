import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {MediaMatcher} from "@angular/cdk/layout";
import {Subject} from "rxjs";
import {pushOrRemove} from "../../inno-utils/inno-utils.module";
import {delay} from "../../inno-utils/general-utils.service";
import {LoadingFunctionConfiguration} from "../hid-async-dropdown/hid-async-dropdown.component";

@Component({
  selector: 'hid-dropdown',
  templateUrl: './hid-dropdown.component.html',
  styleUrls: ['./hid-dropdown.component.scss']
})
export class HidDropdownComponent implements OnInit {


  //MANDATORY to either providde options or loadign config to let the component get the options. In the latter case, request must not require parameters
  @Input() options: any[];
  @Input() loadingFunctionConfig: LoadingFunctionConfiguration;
  @Input() label: string;

  //Model& Data
  @Input() model: any;
  //Control always holds identifying prop
  @Input() control: AbstractControl;
  //Data config
  @Input() mode: 'Simple' | 'Object' = 'Simple';
  //Set if object
  @Input() displayProperty: string;
  @Input() identifyingProperty: string;

  @Input() inputFieldClass: string;


  //OPTIONAL
  @Input() forceValidation: Subject<any>;
  //basic config
  @Input() multiple: boolean = false;
  @Input() disabled: boolean;
  @Input() searchable: boolean = false;
  @Input() nullable: boolean = false;
  @Input() debounceTime?: number;
  @Input() nullValueDisplayText: string;

  @Output() modelChange: EventEmitter<any> = new EventEmitter();

  @Input() showValidatorIcons: boolean = false;

  validationForced;
  open = false;

  wasOpened = false;

  searchTerm = new FormControl("");
  preventClosing = false;
  mobileQuery: MediaQueryList;

  filteredOptions;

  constructor(media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
  }

  loading
  ngOnInit() {
    if (this.loadingFunctionConfig) {
      this.loading = true;
      this.loadingFunctionConfig.resource[this.loadingFunctionConfig.functionName]().then(res => {
        this.options = res;
        if (this.nullable) {
          this.options.push(null);
        }
        this.filteredOptions = this.options;
        this.loading = false;
      })
    } else {
      this.filteredOptions = this.options
    }


    if (!this.nullValueDisplayText && this.nullable) {
      this.nullValueDisplayText = 'Egal';
    }

    if (this.forceValidation) {
      this.forceValidation.subscribe(() => {
        this.validationForced = true;
      })
    }

    if (this.mode === 'Object' && (this.displayProperty == null || this.identifyingProperty == null)) {
      throw {message: 'HID Dropdown initialized with object input mode, but did not provide display and/or identifyingPRoperty'};
    }

    if (this.nullable) {
      this.options.push(null);
    }



    if (!this.control) {
      this.control = new FormControl();
      this.control.setValue(this.model)
    }

    if(this.multiple && this.control.value == null){
      this.control.setValue([], {emitEvent: false})
    }

    this.control.valueChanges
      .pipe(debounceTime(this.debounceTime))
      .subscribe(newVal => {
        this.emit(newVal);
      });

    this.searchTerm.valueChanges.subscribe(newValue => {
      this.filteredOptions = Object.assign([], this.options).filter(
        item => this.getDisplayProperty(item).toLowerCase().indexOf(newValue.toLowerCase()) > -1
      )

    })
  }

  //Toggle in luist for multiple or just override for single select
  setModel(option) {
    if(this.disabled)return
    if (this.multiple) {
      const mutablevalue = this.control.value
      pushOrRemove(mutablevalue, this.getIdentifier(option))
      this.control.setValue(mutablevalue)
    } else {
      this.control.setValue(this.getIdentifier(option))
      this.toggleDropdown();
    }
  }

  clickOutsideHandler(event){
    if(!open)return
    if(event.target.className.indexOf('option') > -1)return

    this.toggleDropdown()
  }


  //clickoutside will trigger directly after open. So we need to ensure it doesnt close again right away
  toggleDropdown() {
    if (!this.open) {
      this.preventClosing = true;
      if(this.disabled)return
      this.wasOpened = true;
      this.open = true;
      delay(100).then(
        () => {
          this.preventClosing = false;
          if (this.mobileQuery.matches && this.searchable) {
            document.getElementById("mobile-search-input").focus()
          }
        })

    } else {
      if(!this.preventClosing) {
        this.open = false
      }
    }
  }

  isSelected(option) {
    if (this.multiple) {
      return this.control.value.indexOf(this.getIdentifier(option)) > -1
    } else {
      return this.control.value == this.getIdentifier(option);
    }
  }

  getIdentifier(option) {
    if (option == null) return option;
    if (this.mode === 'Simple' || this.identifyingProperty == null) {
      return option;
    } else {
      return option[this.identifyingProperty];
    }
  }

  //in this component you can work around this by using an internal control that holds the entire object
  getOptionByIdentifier(identifier: any){
    if (!this.options) return
    for(let o of this.options){
      if(this.getIdentifier(o) === identifier){
        return o
      }
    }
  }

  getDisplayProperty(option: any) {
    if (!option) return this.nullValueDisplayText;
    if (this.displayProperty) {
      return option[this.displayProperty]
    }
    return option
  }

  emit(value: any) {
    this.modelChange.emit(value);
  }
}
