import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicGlobalConfigController
 */

@Injectable()
@ResourceParams({})
export class PublicGlobalConfigResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/config/global/css',
        endpoint: 'cms'
    })
    private _getGlobalCss: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<string>>;
    getGlobalCss(): Promise<JsonWrappedValue<string>> {
        return this._getGlobalCss(null, null, null).toPromise()
    }

}