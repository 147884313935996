<div id="header">
  <div id="header-content" fxLayout="column" fxLayoutAlign.lt-sm="center center" fxLayoutAlign="center end">
    <div fxLayout="column" fxLayoutAlign.lt-sm="center center" fxLayoutAlign="center start" class="isu-padding">
      <p>Dein Online-Seminar</p>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <hid-loading-spinner [loadingBool]="loadingInfo">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="itemInfo mat-elevation-z3" *ngIf="token != 'null'">
        <div fxLayout="column" fxLayoutAlign="start center" class="circle-box" (click)="navigateToFirstLesson()">
          <div class="mat-elevation-z1 circle" fxLayout="row" fxLayoutAlign="center center">
            <i class="fa fa-play circle-item text-orange"></i>
          </div>
        </div>
        <div class="itemInfoText">
          <h2>Online-Seminar freigegeben</h2>
          <p>Du kannst nun 7 Tage lang jederzeit auf das Seminar zugreifen.</p>
          <div class="timer" *ngIf="iteminfo && iteminfo.expiry && checkForDateInFutureThenReturnTrue()" fxLayout="row" fxLayoutAlign="center center"
               fxLayoutGap="10px">
            {{getExpiryTime()}}
            <div fxLayout="column" fxLayoutAlign="center center"><h4>{{timeLeft | date: 'd'}}</h4>
              <div>Tage</div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center"><h4>{{timeLeft | date: 'HH'}}</h4>
              <div>Stunden</div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center"><h4>{{timeLeft | date: 'mm'}}</h4>
              <div>Minuten</div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center"><h4>{{timeLeft | date: 'ss'}}</h4>
              <div>Sekunden</div>
            </div>
          </div>

          <p>Wir haben dir eine Email mit einem Zugangslink geschickt, dieser bleibt die ganze Zeit gültig.
            Du kannst aber auch direkt loslegen!</p>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
            <button fxHide.lt-sm [disabled]="navigating" class="hid-button inverse secondary  ita_watch-later"
                    (click)="navigateToThankYouPage()">
              <span>Später starten</span>
            </button>
            <button [disabled]="navigating" class="hid-button primary ita_watch-seminar extra-padding-for-linda"
                    (click)="navigateToFirstLesson()">
              <span *ngIf="!navigating">Seminar jetzt starten <i class="fa fa-play"></i></span>
              <span *ngIf="navigating" class="text-green">...Weiterleiten...</span>
            </button>
            <button fxHide.gt-xs [disabled]="navigating"
                    class="hid-button inverse secondary margin-top-10 ita_watch-later"
                    (click)="navigateToThankYouPage()">
              <span>Später starten</span>
            </button>
          </div>
        </div>
      </div>
      <div class="itemInfo" id="tokenError" fxLayout="column" fxLayoutAlign="start center" *ngIf="token == 'null'">
        <i class="fa fa-times text-red" style="font-size: 50px; margin-right: 30px"></i>
        <p>Dein Link ist leider ungültig <i class="fa fa-frown"></i></p>
        <p>Wir haben dir eine Email mit dem passenden Link geschickt.</p>
        <button class="hid-button secondary inverse" (click)="openSupportModal()"><i class="fa fa-question-mark"></i>Support
          kontaktieren
        </button>
      </div>
    </div>

  </hid-loading-spinner>
</div>
