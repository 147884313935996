import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'badFeedbackReason'
})
export class BadFeedbackReasonPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch(value) {
      case "LinkNotWorking":
        return "Link hat nicht funktioniert";
      case "CanNotAccess":
        return "Wurde nicht reingelassen";
      case "BadContent":
        return "Inhalt war nicht gut";
      case "DidNotTakePlace":
        return "Hat nicht stattgefunden";
      case "Kicked":
        return "Wurde rausgeworfen";
      case "Other":
        return "Anderes";
      case "ContentTooHard":
        return "Inhalte waren zu schwierig";
      case "ContentTooSimple":
        return "Inhalte waren zu leicht";
      default: return null;
    }
  }

}
