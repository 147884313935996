import { Component, OnInit } from '@angular/core';
import {IsolatedPageData} from "../../generated/cms/data";
import {ActivatedRoute} from "@angular/router";
import {PublicStaticPageResource} from "../../generated/cms/resources";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  page: IsolatedPageData;

  constructor(
    private route: ActivatedRoute,
    private publicStaticPageResource: PublicStaticPageResource
  ) {
    this.load();
  }

  ngOnInit() {
  }


  load() {
    this.publicStaticPageResource.getStaticPage('ueber-uns').then(res => {
      this.page = res;
    })
  }
}
