import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feedbackPromotiontypePhrasing'
})
export class FeedbackPromotiontypePhrasingPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch(value) {
      case "Coupon":
        return "deinen Gutschein";
      case "OnlineLecture":
        return "deinen Online-Workshop";
      case "Giveaway":
        return "deinen Download";
      case "GeneralPromotion":
        return "dein Angebot";
      case "Competition":
        return "dein Gewinnspiel";
      case "PerfectMatchEvent":
        return "dein Event";
      default: return null;
    }
  }

}
