import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GDPRConsentComponent} from './gdprconsent/gdprconsent.component';
import {GlobalModule} from '../global/global.module';
import {HidInteractionsModule} from '../hid-interactions/hid-interactions.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';


@NgModule({
  declarations: [
    GDPRConsentComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    GlobalModule,
    HidInteractionsModule,
    FlexModule,
    FlexLayoutModule,
  ],exports:[]
})
export class GDPRConsentModule { }
