<hid-loading-spinner [loadingBool]="loadingServiceFormData">

  <div class="wrapper">
    <div fxLayout="row" fxLayoutAlign="end center">
      <img src="../../../assets/img/icons/mlp_164x50.png">
    </div>
    <div class="inner-wrapper"
         *ngIf="serviceFormDataInitallyLoaded && initialServiceFormData.alreadyExists == false && initialServiceFormData.tokenState == 'VALID' && !invalidLink">
      <h1>Interessentenbogen Workshop -
        <span *ngIf="formatTitle.includes('Thesis')">Thesis Guide</span>
        <span *ngIf="formatTitle.includes('Excel')">Excel-Seminar</span>
      </h1>

      <div style="font-style: italic;
    margin-bottom: 10px;">Vielen Dank für deine Teilnahme am {{formatTitle}}-Webinar!<br>
        Mit diesem Servicebogen kannst du dein Interesse an weiteren Angeboten unseres Partners MLP mitteilen. <br>
        Im Anschluss hast du noch Gelegenheit, dem Referenten ein Feedback auf der MLP-Website dazulassen.<br>
      </div>
      <div style="font-weight: bold;margin-bottom: 20px">Die folgenden Angaben und Anforderungen sind freiwillig.</div>
      <form [formGroup]="createForm">
        <h4>MLP Karriere- und Finanzthemen</h4>
        Bitte kontaktieren Sie mich telefonisch oder per E-Mail zu folgenden Themen:

        <div *ngIf="formatTitle.includes('Thesis')" style="padding-top: 10px;">
          <ng-container formArrayName="contactTopics">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start">
              <div *ngFor="let topic of contactTopicsThesis; let i = index" style="width: 50%" fxLayout="row"
                   fxLayoutAlign="start center">
                <mat-checkbox color="primary" class="example-margin" (change)="onChange($event, 'contactTopics')"
                              [value]="topic">
                  {{topic}}
                </mat-checkbox>
              </div>
            </div>
          </ng-container>
          <hr>
        </div>

        <div *ngIf="formatTitle.includes('Excel')" style="padding-top: 10px;">
          <ng-container formArrayName="contactTopics">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="start start">
              <div *ngFor="let topic of contactTopicsExcel; let i = index" style="width: 50%" fxLayout="row"
                   fxLayoutAlign="start center">
                <mat-checkbox color="primary" class="example-margin" (change)="onChange($event, 'contactTopics')"
                              [value]="topic">
                  {{topic}}
                </mat-checkbox>
              </div>
            </div>
          </ng-container>
          <hr>
        </div>
        <div>
          <h4>Ihre Karrieremöglichkeiten bei MLP</h4>
          Ich interessie mich für den Einstieg als Finanzberatur (w/m/d) bei MLP. Bitte kontaktieren Sie mich
          telefonisch
          oder
          per E-Mail zum Thema:

          <ng-container formArrayName="careerOfferTopics">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutAlign.xs="start start">
              <div *ngFor="let topic of careerOfferTopics; let i = index" style="width: 33%" fxLayout="row"
                   fxLayoutAlign="start center">
                <mat-checkbox color="primary" class="checkbox" (change)="onChange($event, 'careerOfferTopics')"
                              [value]="topic">
                  {{topic}}
                </mat-checkbox>
              </div>
            </div>
          </ng-container>

          <hr>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="start start">
          <div>Mein persönliches fortführendes (Feedback-) Gespräch wünsche ich mir ab:</div>
          <mat-form-field appearance="standard" color="primary" class="datepicker-lineheight">
            <mat-label>Wähle ein Datum aus</mat-label>
            <input matInput formControlName="feedbackAppointmentDate" [matDatepicker]="picker1">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>


        <!--      <hid-date-flow-input [control]="createForm.controls['feedbackAppointmentDate']"></hid-date-flow-input>-->
        <h4>Angaben zur Person</h4>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutAlign.xs="start start">
          <div fxLayout="column" class="personaldata-box-left">
            <div fxLayout="row">
              <mat-radio-group
                class="radio-group"
                formControlName="gender">
                <mat-radio-button color="primary" class="radio-button"
                                  *ngFor="let gender of ['Male', 'Female', 'Unknown']"
                                  [value]="gender">
                  {{gender | gender}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <hid-input-field
              class="input-300w"
              [control]="createForm.controls['lastName']"
              [label]="'Name'">
            </hid-input-field>
            <hid-input-field
              class="input-300w"
              [control]="createForm.controls['firstName']"
              [label]="'Vorname'">
            </hid-input-field>
            <app-hid-input-phone-number
              [showValidatorIcons]="true"
              inputFieldClass="ita_input_phone"
              class="input-300w"
              name="phoneNumber" type="tel"
              [control]="createForm.controls['phoneNumber']"
              label="Mobilnummer"
            ></app-hid-input-phone-number>
            <hid-input-field
              class="input-300w"
              [control]="createForm.controls['email']"
              [label]="'E-Mail'"
              type="email">
            </hid-input-field>
          </div>
          <div fxLayout="column" class="personaldata-box-right">
            <div class="mobile-padding-top">
              Um den für sie passenden Beratungsstandort Ihren Wünschen entsprechend heraussuchen zu können, bitten wir
              Sie um folgende Mitteilung:
            </div>
            <div style="line-height: 75px" fxLayout="row" fxLayoutAlign="end center">
              <mat-form-field color="primary" appearance="standard">
                <mat-label>Geburtsdatum</mat-label>
                <input matInput formControlName="birthDate" [matDatepicker]="picker2" [max]="maxBirthDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>

            <hid-input-field
              style="width: 100%"
              [control]="createForm.controls['universityName']"
              [label]="'Universitätsname'">
            </hid-input-field>

<!--           todo use an async dropdown which returns the name or the searchterm if no element selected-->
            <!--<hid-async-dropdown [control]="createForm.controls['universityName']"
            displayProperty="name" [placeholder]="'Universität'" [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
                                [loadingFunctionConfig]="{resource:serviceFormResource, functionName:'getUniversitiesByQueryString'}">

            </hid-async-dropdown>-->
<!--            {resource: campaignModelResource, functionName: 'getTrafficMeasures', requestParams: [{platFormId: filterForm.value['platform']}]}-->
            <!--<hid-university-autocomplete [ngClass]="{'mobile-dropdown': mediaService.isMobile()}"
                                         [control]="createForm.controls['universityName']"
            [placeholder]="'Universität'">

            </hid-university-autocomplete>-->
            <div>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <hid-dropdown *ngIf="studyAreas.length > 0 && this.studyAreasInitiallyLoaded"
                              [options]="studyAreas"
                              label="Fachrichtung"
                              [control]="createForm.controls['studyAreaId']"
                              displayProperty="title"
                              style="width: 200px">

                </hid-dropdown>
                <hid-input-field
                  style="max-width: 150px"
                  [control]="createForm.controls['semester']"
                  [label]="'Fachsemester'"
                  type="number">
                </hid-input-field>


              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center">

                <mat-radio-group
                  class="radio-group"
                  formControlName="degreeType">
                  <mat-radio-button color="primary" class="radio-button"
                                    *ngFor="let degreeType of degreeTypes"
                                    [value]="degreeType">
                    {{degreeType}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <div>
                <mat-form-field color="primary" appearance="standard" class="date-input">
                  <mat-label>Studienende</mat-label>
                  <input matInput formControlName="studyEndDate" [matDatepicker]="picker3">
                  <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="mobile-padding-top">
          <h4>Besteht bereits Kontakt zu MLP?</h4>
          <div fxLayout="row" fxLayoutAlign="start center" class="mobile-padding-top" fxLayout.xs="column"
               fxLayoutAlign.xs="start start">
            <mat-checkbox color="primary" style="padding-right: 10px"
                          formControlName="hadAlreadyContactWithMLP"> Falls ja, bitte Namen des Beraters angeben
            </mat-checkbox>

            <hid-input-field
              [disabled]="createForm.value['hadAlreadyContactWithMLP'] == false"
              class="input-300w consultant-input"
              [control]="createForm.controls['alreadyAdvisingConsultantName']"
              [label]="'Beratername'"
            >
            </hid-input-field>
          </div>

          <div style="font-size: 12px" class="mobile-padding-top">* benötigte Angabe</div>
        </div>
        <div class="mobile-padding-top">
          <hid-textarea [control]="createForm.controls['notes']" [label]="'Notizen'" [maxLength]="250"></hid-textarea>
        </div>
        <div style="font-size: 12px; padding-top: 10px">
          <div style="font-weight: bold">INFORMATIONEN ZUR DATENVERARBEITUNG</div>
          Die MLP Finanzberatung SE, Alte Heerstraße 40, 69168 Wiesloch erhebt, speichert und verarbeitet Ihre
          personenbezogenen Daten (auch etwaige eingereichte Bewerbungsunterlagen) ausschließlich zur Kontaktaufnahme
          per Telefon oder E-Mail im Hinblick auf Ihre Auswahl der angebotenen Seminare/ Informationen und/ oder
          Karrieremöglichkeiten.
          Die Datenverarbeitung beruht in den genannten Fällen auf Art. 6 Abs. 1 Buchst. b DSGVO.
          Bei freiwilligen Angaben erfolgt eine Verarbeitung auf Basis Ihrer Einwilligung gemäß Art. 6 Abs. 1 Buchst. a
          DSGVO.
          Eine Weitergabe Ihrer Daten an Dritte erfolgt nicht.
          Ihre Daten werden bis zur vollständigen Erledigung Ihres Auftrages (angekreuzte Themen bzw. eingereichte
          Bewerbung) gespeichert.
          Weitere Informationen zur Datenverarbeitung durch MLP finden Sie unter: <a
          href="https://mlp.de/mlp/datenschutz/">mlp.de/mlp/datenschutz/</a>
        </div>
        <div style="padding-top: 20px">
          <div style="padding-right: 10px">
            <mat-checkbox formControlName="privacyContactLegitimization">Die Informationen zur Datenverarbeitung habe
              ich zur Kenntnis genommen.
            </mat-checkbox>
          </div>
        </div>

      </form>


      <div fxLayout="row" fxLayout.xs="column" class="form-button" fxLayoutAlign="end center">
        <button class="hid-button primary" [disabled]="createForm.invalid"
                (click)="enterFormData()">Formular abschicken
        </button>
      </div>

    </div>
    <div *ngIf="invalidLink" fxLayout="column"
         fxLayoutAlign="start center"
         class="token-not-valid">
      <div id="logo">
        <!--img src="../../../assets/img/wrongSmiley.jpg"-->
      </div>
      <div class="headline">Dein Link ist leider Ungültig!</div>
      <div class="explanation">Leider können wir deinen Link nicht zu einem Seminar zuordnen. Wenn du dennoch einen
        Servicebogen
        zu
        einem Seminar abgeben willst, kontaktiere uns gerne.
      </div>

      <div class="contact-box" fxLayout="column" fxLayoutAlign="start center">
        <div>
          <i aria-hidden="true" class="fab fa-whatsapp fam"></i>
          <a href="whatsapp://send?text=Hey Nina, ich hab eine Frage:&amp;phone=+4915735980244">+49 157 359 80 244</a>
        </div>
        <button class="hid-button secondary" (click)="openSupportModal()">Support kontaktieren</button>
      </div>

      <div class="other">ODER</div>

      <button class="hid-button primary" (click)="goHome()">zu den Angeboten</button>
    </div>
    <div *ngIf="serviceFormDataInitallyLoaded && initialServiceFormData.alreadyExists == true">
      Du hast bereits den Service-Bogen ausgefüllt. Danke nochmals dafür!
    </div>
  </div>
</hid-loading-spinner>
