import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.CronosSyncController
 */

@Injectable()
@ResourceParams({})
export class CronosSyncResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/sync/formats/force',
        endpoint: 'cms'
    })
    private _forceFormatSync: IResourceMethodObservableStrict<void, {}, {}, void>;
    forceFormatSync(): Promise<void> {
        return this._forceFormatSync(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/sync/lastTimeStamp',
        endpoint: 'cms'
    })
    private _getSyncActuality: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<Date>>;
    getSyncActuality(): Promise<JsonWrappedValue<Date>> {
        return this._getSyncActuality(null, null, null).toPromise()
    }

}