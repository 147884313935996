import { Injectable } from '@angular/core';
import {PromotionApiResourceV2} from "../../generated/cronos/resources";
import {RegistrationInfoData} from "../../generated/cronos/data";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RegistrationInfoService {

  constructor(private promotionResource: PromotionApiResourceV2, private router: Router) { }


  getRegistrationInfoForToken(token: string):Promise<RegistrationInfoData>{
    return this.promotionResource.getRegistrationInfo(token).then(res => {
      if(!res.promotionData){
        this.router.navigate(["/actions/token-error"])
        return
      }
      return res
    })
  }

}
