import {Component, Inject, OnInit} from '@angular/core';
import {OnlineLectureInfoData} from "../../generated/cronos/data";
import {ActivatedRoute, Router} from "@angular/router";
import {MediaService} from "../../media.service";
import {OnlineLectureAPIResourceV2, PromotionApiResourceV2} from "../../generated/cronos/resources";
import {ShareService} from "../../share/share.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MatDialog} from "@angular/material/dialog";
import {LocalStorageService} from "../../local-storage.service";
import {PublicOfferPageResource} from "../../generated/cms/resources";
import {UtilsService} from "../../utils.service";
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {saveAs} from "file-saver/dist/FileSaver";
import {OnlineLectureService} from "../online-lecture.service";

@Component({
  selector: 'app-online-lecture-summary',
  templateUrl: './online-lecture-summary.component.html',
  styleUrls: ['./online-lecture-summary.component.scss']
})
export class OnlineLectureSummaryComponent implements OnInit {

  format: OnlineLectureInfoData;
  recommendedUpsellPageIds;
  offerPageId;
  offerPageUrl;

  numberOfSteps: number = 0;
  currentStep: number = 0;

  showAppointmentStep: Boolean = false;

  loading: Boolean = false;
  downloading: Boolean = false;

  token: string;
  cronosFormatId;
  lessonId: string;

  date = null;

  constructor(
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private onlineLectureResource: OnlineLectureAPIResourceV2,
    private shareService: ShareService,
    private router: Router,
    private modal: NgbModal,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private publicOfferPageResource: PublicOfferPageResource,
    private utilsService: UtilsService,
    @Inject("env") private env,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private onlineLectureService: OnlineLectureService,
  ) {
  }

  ngOnInit() {
    this.cronosFormatId = (this.route.snapshot.paramMap.get("cronosFormatId"));
    this.lessonId = (this.route.snapshot.paramMap.get("lessonId"));
    this.token = this.localStorageService.getLectureAuthToken();

    this.date = this.onlineLectureService.date;
    this.showAppointmentStep = this.onlineLectureService.showAppointmentStep;

    this.load();
  }

  load() {
    this.loading = true;
    this.token = this.onlineLectureService.getToken(this.cronosFormatId);

    this.onlineLectureService.loadFormat(this.cronosFormatId).then(format => {
      this.format = format;
      this.numberOfSteps = this.onlineLectureService.numberOfSteps;
    });

    this.onlineLectureService.loadOfferPage(this.cronosFormatId).then(offerPage => {
      this.offerPageUrl = offerPage.offerPageUrl;
      this.offerPageId = offerPage.offerPageId;
      this.recommendedUpsellPageIds = offerPage.upSellIds;
      this.loading = false;
    });
  }

  download() {
    this.downloading = true;
    this.http.get(this.env.cronosApiUrl + '/formats/onlineLecture/content/' + this.token, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    }).subscribe(
      response => {
        this.downloading = false;
        saveAs(response, this.format.fileName)
      }
    )
  }

  getArray(length) {
    return new Array(length)
  }

  goToStep(i) {
    this.currentStep = i;
    let lessonIdToGoTo = this.format.lessons[i].id;
    if (i == this.numberOfSteps) {
      this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/summary');
    } else {
      this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/lesson/' + lessonIdToGoTo);
    }
  }

  backToLessons() {
    let previousLessonId = this.format.lessons[this.format.lessons.length - 1].id;
    this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/lesson/' + previousLessonId);
  }

  goToAppointment() {
    this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/appointment');
  }

  goToSummary() {
    this.onlineLectureResource.finishOnlineLecture(this.token).then(() => {
      this.router.navigateByUrl('/onlinelecture/' + this.cronosFormatId + '/summary');
    });
  }

  initShare() {
    //offerpage.routingUrl
    this.shareService.showSharingButtons('/angebote/' + this.offerPageUrl, 'offerpage-share', 'Hey, ich habe ein cooles Angebot auf HID für dich: ', null);
  }
}
