
<!-- CASE 1: Standard (feedback was not yet given -->
<div *ngIf="registrationInfo.feedbackEntered != true && !registrationInfoLoading && !perfectMatchAndNotCheckedIn"
     class="feedback-main-wrapper ita-format-{{registrationInfo.promotionData.formatId}}" fxLayout="column" fxLayoutAlign="start center">
  <div class="logo">
    🎉
    <!--img src="../../../../../assets/img/likeSmiley.jpg"-->
  </div>

  <div class="seminar-data" fxLayout="column" fxLayoutAlign="center center">
    <div
      style="font-weight: bold">{{registrationInfo.promotionData.formatTitle}}</div>
  </div>

  <h3>Um unser Angebot zu verbessern, brauchen wir <b>dein Feedback</b>. Wie bewertest
    du {{registrationInfo.promotionData.promotionType | feedbackPromotiontypePhrasing }}?</h3>

  <div class="reason-box">
    <div id="stars">
      <div class="rating-good">nicht gut</div>
      <hid-star-rating size="50px" [model]="0" (valueChange)="updateFormControlValue($event)"></hid-star-rating>
      <div class="rating-bad">super gut</div>
    </div>

    <div class="text-area">
      <hid-textarea
        label="Kommentar (optional)"
        minRows="5"
        maxLength="500"
        charCounterShow="true"
        [control]="feedbackGroup.controls['comment']"></hid-textarea>
    </div>

    <app-anonymize-feedback [anonymizeControl]="feedbackGroup.controls['anonymized']"></app-anonymize-feedback>


    <div class="action-box" fxLayout="column" fxLayoutAlign="center end">
      <div *ngIf="errorMessage && !(feedbackGroup.controls.rating.value < 6 && feedbackGroup.controls.rating.value > 0)"
           class="error-message">Bitte gib erst eine Bewertung ab!
      </div>
      <hid-checkbox [control]="feedbackGroup.controls.enterGoogleReview" style="font-size: 12px">Anschließend eine Bewertung bei Google abgeben</hid-checkbox>
      <button [disabled]="feedbackSent" class="hid-button primary ita-send-feedback-present-btn" (click)="sendFeedback()">Feedback senden</button>
    </div>
  </div>
</div>

<div *ngIf="registrationInfo.feedbackEntered != true && !registrationInfoLoading && perfectMatchAndNotCheckedIn"
     class="feedback-main-wrapper ita-format-{{registrationInfo.promotionData.formatId}}"
     fxLayout="column" fxLayoutAlign="start center">
  <div class="logo">
    😕
  </div>

  <h1>Schade, dass du nicht da warst!</h1>

  <div class="student-was-present" fxLayout="column" fxLayoutAlign="start center">
    <div class="link" (click)="openConfirmDialog()">Du warst beim {{registrationInfo.promotionData.promotionType | promotionType}}? Klicke hier.</div>
  </div>

  <app-promotion-info-box [promotionData]="registrationInfo.promotionData"></app-promotion-info-box>

  <div class="reason-box">
    <div>
      <div class="reason" fxLayout="row" fxLayoutAlign="start center">
        Bitte gib einen Grund an, damit wir unseren Service verbessern können:
      </div>
    </div>

    <hid-radio-group  [options]="radioOptionsMissedEvent" [control]="feedbackGroup.controls['reason']"></hid-radio-group>

    <div class="text-area">
      <hid-textarea
        label="Kommentar"
        minRows="5"
        maxLength="500"
        charCounterShow="true"
        [control]="feedbackGroup.controls['comment']"></hid-textarea>
    </div>

    <app-anonymize-feedback [anonymizeControl]="feedbackGroup.controls['anonymized']"></app-anonymize-feedback>

    <div class="action-box" fxLayout="column" fxLayoutAlign="center end">
      <div *ngIf="errorMessage && !missedEventReasons.includes(feedbackGroup.controls.reason.value?.value)" class="error-message">
        Bitte gib erst einen Grund ab!
      </div>
      <button [disabled]="feedbackSent" class="hid-button primary ita-send-feedback-not-present-btn" (click)="sendFeedback()">Feedback senden</button>
    </div>
  </div>
</div>


<!-- CASE 2: Feedback was already given -->
<div *ngIf="registrationInfo?.feedbackEntered == true" fxLayout="column" fxLayoutAlign="start center"
     class="token-not-valid">

  <div id="logo">
    😕
    <!--img src="../../../../../assets/img/wrongSmiley.jpg"-->
  </div>
  <div class="headline">Du hast bereits Feedback abgegeben.</div>

  <div class="explanation">Du hast schon Feedback
    zu {{registrationInfo.promotionData.promotionType | feedbackPromotiontypePhrasing}} abgegeben.
  </div>
</div>


<!--BOTTOM Case 1: upsells available -->
<div fxLayout="column" fxLayoutAlign="start center"
     *ngIf="recommendedUpsellPageIds.length > 0 ">

  <h1>Das könnte dich auch interessieren:</h1>
  <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
    <app-offer-page-panel
      [queryParams]="{utm_source:'hidpage',utm_medium:'thankyoupage', utm_campaign:'thankyoupage-offer-caroussel'}"
      [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">
    </app-offer-page-panel>
  </div>
</div>
<!--BOTTOM Case 2: no upsells available -->
<div *ngIf="recommendedUpsellPageIds.length == 0" fxLayout="column"
     fxLayoutAlign="center center" id="to-main-page">
  <div class="explanation">Wir haben noch andere spannende Seminare und Aktionen für dich!</div>
  <button class="hid-button secondary" (click)="goHome()">Weiterstöbern</button>
</div>
