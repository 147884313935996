import { Component, OnInit } from '@angular/core';
import {PublicOfferPageResource} from "../../../generated/cms/resources";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-offer-page-redirect',
  templateUrl: './offer-page-redirect.component.html',
  styleUrls: ['./offer-page-redirect.component.sass']
})
export class OfferPageRedirectComponent implements OnInit {

  cronosFormatId: number
  promId: number
  offerRoutingUrl: string
  loading = new BehaviorSubject<boolean>(true);

  constructor(private offerPageResource: PublicOfferPageResource,
              private route: ActivatedRoute,
              private router: Router) {

    route.params.subscribe(params => {
      this.cronosFormatId = params['formatId']
    })

    route.queryParams.subscribe(params => {
      this.promId = params['promId']
    })

  }

  ngOnInit(): void {
    this.offerPageResource.getFormatOfferPageByCronosId(this.cronosFormatId).then(res => {
      this.offerRoutingUrl = res.routingUrl
      if (this.promId == null) {
        this.router.navigate([`/angebote/${this.offerRoutingUrl}`], {queryParamsHandling: "preserve"})
      } else {
        this.router.navigate([`/angebote/d/${this.offerRoutingUrl}`], {queryParamsHandling: "preserve"})
      }
    })
  }

}
