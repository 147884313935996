<div class="content-wrapper">
  <hid-loading-spinner [loadingBool]="loading">
    <div fxLayout="column" fxLayoutAlign="start start" *ngIf="!loading">
      <div class="controls" fxLayout="row" fxLayoutAlign="start start">
        <button class="hid-button secondary inverse margin-0 ita_back-to-lessons"
                (click)="backToLessons()"><i
          class="fa fa-chevron-left"></i> Zurück zu den Lektionen
        </button>
      </div>
      <div class="header" fxLayout="column" fxLayoutAlign="start center" *ngIf="format">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="title">{{format.title}}</div>
          <button fxHide class="hid-button secondary inverse" (click)="initShare()"><span
            fxHide>Angebot teilen </span><i
            class="fa fa-share-alt"></i></button>
        </div>

        <div fxLayout="column" *ngIf="date" style="background: #e8e8e8; margin-bottom: 20px;padding:8px" fxHide
             fxShow.xs>

          <h5>Super! Dein Wunschtermin ist am:</h5>
          <div fxLayout="column" fxLayoutAlign="start start" class="appointment">
            <p><i class="fa fam fa-calendar"></i>{{date | date:'EEEE, dd.MM.yyyy'}}</p>
            <p><i class="fas fam fa-clock"></i>{{date | date:'HH:mm'}} Uhr</p>
          </div>
          <p>Wir melden uns bezüglich des Termins bei dir.</p>

          <button class="hid-button primary ita_go-to-summary-again"
                  (click)="goToSummary()">Weiter
          </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h3>Nächste Schritte</h3>


          <div class="wrapper-progressBar" [fxFlex]="numberOfSteps < 5 ? 30 : 45" fxHide.xs fxShow>
            <ul class="progressBar">
              <li *ngFor="let lesson of getArray(numberOfSteps); let stepperIndex = index"
                  class="active ita_stepper-go-to-lesson"
                  (click)="goToStep(stepperIndex)"
                  [ngStyle]="{'width':  100/(numberOfSteps+2)+'%'}">
                <span class="stepper-index">{{stepperIndex + 1}}</span>
              </li>
              <li
                class="active ita_stepper-go-to-appointment"
                (click)="goToAppointment()"
                [ngStyle]="{'width':  100/(numberOfSteps+2)+'%'}">
                <span class="stepper-index">{{numberOfSteps + 1}}</span>
              </li>
              <li *ngIf="showSummaryStep"
                (click)="goToSummary()"
                class="ita_stepper-go-to-summary"
                [ngStyle]="{'width':  100/(numberOfSteps+2)+'%'}">
                <span class="stepper-index">{{numberOfSteps + 2}}</span>
              </li>
            </ul>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxFlex="65" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="start center">
            <div [innerHTML]="format.nextStepsText | safeHtml"></div>
          </div>
          <div fxLayout="column" fxFlex="30" fxLayoutGap="10px" fxShow fxHide.lt-sm>

            <div fxLayout="column" *ngIf="!date">
              <form [formGroup]="form" style="margin-bottom: 10px">
                <mat-calendar
                  [dateFilter]="onlineLectureService.dateFilter"
                  [selected]="form.get('date').value"
                              style="border: 1px solid #e8e8e8;margin-bottom: 5px"
                              (selectedChange)="updateFormDate($event)"></mat-calendar>
                <div fxLayout="row">
                  <hid-input-field [control]="form.controls['hour']" type="number"
                                   [pattern]="'^(0[0-9]|1[0-9]|2[0-3])|[0-9]'"
                                   [label]="'Stunde'" name="time"></hid-input-field>
                  <span class="colon">:</span>
                  <hid-input-field [control]="form.controls['minute']" type="number" [pattern]="'([0-5]?\\d)'"
                                   [label]="'Minute'" name="time"></hid-input-field>
                </div>
                <div style="font-size: 12px;text-align: center" class="small-text">Werktags zwischen 9:00 und 18:00 möglich</div>
              </form>


              <button class="hid-button primary ita_request-appointment"
                      [disabled]="!form.valid"
                      (click)="onlineLectureService.setAppointment(form)">Termin vereinbaren
              </button>
              <button class="hid-button inverse secondary ita_skip"
                      *ngIf="!date"
                      (click)="goToSummaryAndShowSnackbar()">Überspringen
              </button>
            </div>
            <div *ngIf="date" fxLayout="column" fxLayoutAlign="start center">
              <div fxLayout="column" style="background: #e8e8e8; padding: 8px;">
                <h5>Super! Dein Wunschtermin ist am:</h5>
                <div fxLayout="column" fxLayoutAlign="start start" class="appointment">
                  <p><i class="fa fam fa-calendar"></i>{{date | date:'EEEE, dd.MM.yyyy'}}</p>
                  <p><i class="fas fam fa-clock"></i>{{date | date:'HH:mm'}} Uhr</p>
                </div>
                <p>Wir melden uns bezüglich des Termins bei dir.</p>
              </div>

              <button class="hid-button primary w-100 ita_go-to-summary" style="margin-top:30px"
                      (click)="goToSummary()">Weiter
              </button>
            </div>
          </div>


        </div>

        <div id="mobile-cta" fxLayout="row" fxLayoutAlign="space-around center" fxShow.lt-sm fxHide
             *ngIf="!dialogOpen && !date">

          <button
            class="hid-button inverse secondary ita_go-to-summary"
            (click)="goToSummary()">
            <span>Überspringen</span>
            <!--<hid-progress-spinner *ngIf="loading.getValue()"></hid-progress-spinner>-->
          </button>

          <button
            class="hid-button cta-button primary ita_mobile-appointment-menu"
            (click)="openDatePicker()">
            <span>Termin anfordern</span>
            <!--<hid-progress-spinner *ngIf="loading.getValue()"></hid-progress-spinner>-->
          </button>
        </div>
      </div>
    </div>
  </hid-loading-spinner>
</div>
