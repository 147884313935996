import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  ContactSupportModalComponent
} from "../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {TokenFeedbackData, UnsubData} from "../../../generated/cronos/data";
import {MailmarketingAPIResourceV2} from "../../../generated/cronos/resources";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

/**
 * @deprecated Component is deprecated and only still in use for the last genreated unsubTokens. Unsubs are now handled by mailjet directly. Remove after 1.8.2024
 */
@Component({
  selector: 'app-unsub-action-link',
  templateUrl: './unsub-action-link.component.html',
  styleUrls: ['./unsub-action-link.component.scss']
})
export class UnsubActionLinkComponent implements OnInit {


  token: string;

  iteminfo: TokenFeedbackData = <TokenFeedbackData>{};

  loading = true;

  submitted = false;

  optOutForm: FormGroup;
  optOutReason = ["FeedbackNoStudent", "FeedbackTooManyMails", "FeedbackNoInterest", "FeedbackOther"];

  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private cronosApi: MailmarketingAPIResourceV2,
    private fb: FormBuilder
  ) {
    route.snapshot.queryParamMap.get("code") ? this.token = route.snapshot.queryParamMap.get("code") : this.token = route.snapshot.paramMap.get("token");
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.optOutForm = this.fb.group({
      reason: [null, Validators.required],
      comment: ['']
    });
  }

  submit() {
    this.loading = true;
    let unsubData: UnsubData = {
      token: this.token,
      reason: this.optOutForm.value.reason,
      comment: this.optOutForm.value.comment
    };
    this.cronosApi.unsubscribeFromMailMarketing(unsubData).then(res => {
      this.iteminfo.state = res.state;
      this.submitted = true;
      this.loading = false;
    })
  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }
}
