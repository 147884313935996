<div class="dialog-main-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

  <div fxLayout="row" fxLayoutAlign="space-between start" class="width-100">
    <h3>Termin vereinbaren</h3>
  </div>

  <div class="small-text" *ngIf="!data.isDateSet">{{getAppointmentRequestText()}}</div>

  <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!data.isDateSet" class="calendar-wrapper width-100">
    <form [formGroup]="form" style="margin-bottom: 10px; width: 200px">
      <mat-form-field class="date-wrapper">
        <mat-label>Datum auswählen</mat-label>
        <input matInput readonly="true" [matDatepickerFilter]="onlineLectureService.dateFilter" [matDatepicker]="picker"
               formControlName="date"
               (click)="openedPicker=!openedPicker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker [opened]="openedPicker" #picker></mat-datepicker>
      </mat-form-field>
      <div fxLayout="row">
        <hid-input-field [control]="form.controls['hour']" type="number"
                         [pattern]="'^(0[0-9]|1[0-9]|2[0-3])|[0-9]'"
                         [label]="'Stunde'" name="time"></hid-input-field>
        <span class="colon">:</span>
        <hid-input-field [control]="form.controls['minute']" type="number" [pattern]="'([0-5]?\\d)'"
                         [label]="'Minute'" name="time"></hid-input-field>
      </div>
      <div style="font-size: 12px;text-align: center" class="small-text">Werktags zwischen 9:00 und 18:00 möglich</div>
    </form>
  </div>

  <div *ngIf="data.isDateSet" fxLayout="column" fxLayoutAlign="start center">
    <div fxLayout="column" style="background: #e8e8e8; padding: 8px; margin: 20px 0">
      <h5>Super! Dein Wunschtermin ist am:</h5>
      <div fxLayout="column" fxLayoutAlign="start start" class="appointment">
        <p><i class="fa fam fa-calendar"></i>{{date | date:'EEEE, dd.MM.yyyy'}}</p>
        <p><i class="fas fam fa-clock"></i>{{date | date:'HH:mm'}} Uhr</p>
      </div>
      <p>Wir melden uns bezüglich des Termins bei dir.</p>
    </div>
  </div>

  <div class="action-box width-100 height-100" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
    <button class="hid-button inverse"
            *ngIf="data.isDateSet"
            (click)="close()">Schließen
    </button>
    <button class="hid-button inverse secondary"
            *ngIf="!data.isDateSet"
            (click)="close()">Abbrechen
    </button>
    <button class="hid-button primary ita_request-appointment"
            *ngIf="!data.isDateSet"
            [disabled]="!form.valid"
            (click)="requestAppointment()">Termin vereinbaren
    </button>
  </div>

</div>
