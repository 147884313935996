import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

type Path = `/${string}`

export interface RouteRedirectData {
  redirectTo?: Path;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return of(this.authService.isAuthenticated()).pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          const redirect = (route.data as RouteRedirectData).redirectTo;
          if (redirect) {
            return this.router.createUrlTree([redirect]);
          }
          return this.router.createUrlTree(['/home']);
        } else {
          return true;
        }
      })
    );
  }
}
