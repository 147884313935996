import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {BlogOverviewComponent} from './blog-overview/blog-overview.component';
import {BlogPostComponent} from './blog-post/blog-post.component';
import {BlogPostPanelComponent} from "./blog-post-panel/blog-post-panel.component";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {BlogPostSidebarComponent} from './blog-post-sidebar/blog-post-sidebar.component';
import {BlogPostOverviewPanelComponent} from "./blog-post-overview-panel/blog-post-overview-panel.component";
import {GlobalModule} from "../global/global.module";
import {BlogPostGridComponent} from "../blog-post-grid/blog-post-grid.component";
import {OfferPagePanelModule} from "../offer-page-panel/offer-page-panel.module";
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";

const appRoutes: Routes = [
  {path: 'blog', component: BlogOverviewComponent},
  {path: 'blog/:id', component: BlogPostComponent},
];

@NgModule({
  declarations: [
    BlogOverviewComponent,
    BlogPostComponent,
    BlogPostPanelComponent,
    BlogPostSidebarComponent,
    BlogPostGridComponent,
    BlogPostOverviewPanelComponent
  ],
  imports: [
    CommonModule,
    GlobalModule,
    RouterModule.forChild(appRoutes),
    HidInteractionsModule,
    FlexLayoutModule,
    FlexModule,
    OfferPagePanelModule
  ],
  exports: [
    RouterModule
  ]
})


export class BlogModule {
}
