import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeedbackStarsComponent} from './feedback-stars/feedback-stars.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';

@NgModule({
  declarations: [FeedbackStarsComponent],
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule,
    CommonModule,
  ],
  exports:[
    FeedbackStarsComponent
  ]
})
export class FeedbackStarsModule { }
