import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShareOverlayComponent} from './share-overlay/share-overlay.component';
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";

@NgModule({
  declarations: [ShareOverlayComponent],
  imports: [
    FlexModule,
    FlexLayoutModule,
    HidInteractionsModule,
    CommonModule
  ]
})
export class ShareModule { }
