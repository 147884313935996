
<div class="wave-container">
  <h1>Dein Profil</h1>

  <div  *ngIf="!info?.avatarId" class="avatar-wrapper center-content"
        (mouseenter)="avatarHover = true" (mouseleave)="avatarHover = false">
    <i class="mdi mdi-account-circle default-account-icon"></i>
    <div *ngIf="avatarHover" class="edit-overlay center-content" (click)="openAvatarEditDialog()">
      <i class="mdi mdi-pencil"></i>
    </div>
  </div>

  <div *ngIf="info?.avatarId"
       (mouseenter)="avatarHover = true" (mouseleave)="avatarHover = false"
       class="avatar-wrapper" [ngStyle]="{'background-image': utilService.configureStylingPathForProfileAvatar(info.avatarId)}">
    <div class="edit-overlay center-content" *ngIf="avatarHover" (click)="openAvatarEditDialog()">
      <i class="mdi mdi-pencil"></i>
    </div>
  </div>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#ffffff" fill-opacity="1" d="M0,96L120,90.7C240,85,480,75,720,101.3C960,128,1200,192,1320,224L1440,256L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
  </svg>
</div>


<div fxLayout="column" fxLayoutAlign="center center" class="profile-wrapper" *ngIf="info">
  <div fxLayout="column" class="w-100 data-container" fxLayoutAlign="center center">

    <div fxLayout="row" class="w-100" fxLayoutAlign="center center">
      <div class="categories">
        <p>Name</p>
      </div>
      <div class="user-data" fxLayout="row" fxLayoutAlign="center center">
        <input [(ngModel)]="info.firstName" (ngModelChange)="nameIsChanged = true" class="profile-input" type="text" placeholder="Name" />
      </div>

    </div>

    <br>

    <div fxLayout="row" class="w-100" fxLayoutAlign="center center">
      <div class="categories">
        <p>E-Mail</p>
      </div>
      <div class="user-data" fxLayout="row" fxLayoutAlign="center center">
        <input [(ngModel)]="info.emailAddress" (ngModelChange)="mailIsChanged = true" class="profile-input" type="text" placeholder="E-Mail" />
      </div>
    </div>

    <button mat-flat-button class="primary-qa-button"
            (click)="saveEdits()"
            [disabled]="!mailIsChanged && !nameIsChanged">
      Änderungen speichern</button>

    <h4 style="margin-top: 10px" class="login-hint" (click)="showPasswordHint = true">Passwort vergessen?</h4>
    <app-password-reset *ngIf="showPasswordHint"></app-password-reset>

  </div>

</div>
