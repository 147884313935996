
<div class="hid-input-container" #wrapper *ngIf="show"
     [ngClass]="{'required':required,'validationForced': validationForced, 'validatorIcons': showValidatorIcons || isPassword, 'hideLabelWhenFilled': hideLabelWhenFilled}">
  <div class="lock-overlay" *ngIf="lock"></div>

  <input
    #input
    class="hid-input {{inputFieldClass}}"
    (focus)="focus()"
    id="{{dynamicId}}"
    [pattern]="pattern"
    [type]="type"
    [step]="step"
    [attr.disabled]="disabled ? '' : null"
    (blur)="reportTouch()"
    *ngIf="control"
    [readOnly]="readonly"
    [formControl]="control"
    (change)="updateControl($event)"
    [name]="name"
    [ngClass]="{'filled': control.value != null && control.value.toString().length > 0}"
  >
  <!---->
  <!---->

  <i *ngIf="showValidatorIcons" class="input-icon validator-icon fas fa-spinner"
     [ngClass]="{'show': validationPending}"></i>
  <i *ngIf="showValidatorIcons" class="input-icon validator-icon fas fa-minus-circle text-red"
     [ngClass]="{'show': !control.valid && !validationPending}"></i>
  <i *ngIf="showValidatorIcons" class="input-icon  validator-icon far fa-check-circle text-green"
     [ngClass]="{'show': control.valid && !validationPending}"></i>

  <!-- Validicons are not supported in combination with password mode -->
  <i *ngIf="isPassword && !showValidatorIcons && type === 'password'" (click)="togglePasswordVisibility()" class="input-icon far fa-eye text-grey"
     [ngClass]="{'show': control.valid && !validationPending}"></i>
  <i *ngIf="isPassword && !showValidatorIcons && type === 'text'" (click)="togglePasswordVisibility()" class="input-icon far fa-eye-slash text-grey"
     [ngClass]="{'show': control.valid && !validationPending}"></i>
  <label [for]="dynamicId" [ngClass]="{placeholder: type === 'tel' && isTelephonySplit}" >{{label}}</label>


</div>
