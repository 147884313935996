<div id="header">
  <div id="header-content" fxLayout="column" fxLayoutAlign="center end">
    <div fxLayout="column" fxLayoutAlign="center start" class="isu-padding">
      <p>Wartelisten-Abmeldung</p>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <div *ngIf="!submitted" fxLayout="column" fxLayoutAlign="center center" style="margin-top: 30px">
    Willst du dich wirklich abmelden?
    <button class="hid-button warn" (click)="submit()">Abmelden</button>
  </div>
  <hid-loading-spinner [loadingBool]="loading" *ngIf="submitted">
    <div fxLayout="row" fxLayoutAlign="center center">

      <div class="itemInfo"  fxLayout="column" fxLayoutAlign="start center" *ngIf="iteminfo.state === 'VALID'">
        <h1 class="text-red">Abmeldung bestätigt</h1>
        <p>Du wurdest aus der Warteliste entfernt und wirst nicht mehr über neue Termine informiert.</p>
      </div>

      <!-- On Token Invalid -->
      <div class="itemInfo" fxLayout="column" fxLayoutAlign="start center" *ngIf="iteminfo.state !== 'VALID'">
        <i class="fa fa-times text-red" style="font-size: 50px; margin-right: 30px"></i>
        <p>Dein Link ist leider ungültig <i class="fa fa-frown"></i></p>
        <button class="hid-button secondary inverse" (click)="openSupportModal()"><i class="fa fa-question-mark"></i>Support kontaktieren</button>
      </div>
    </div>
  </hid-loading-spinner>
</div>

<!-- TODO has to be configured somehwere but its unclear if HIDCMS or CRONOS. Recmommend first because it's onpage trafficcontrol, but not sure
<app-offer-page-panel [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">

</app-offer-page-panel>

-->


