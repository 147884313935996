<div *ngIf="!submitted" class="content-wrapper">
  <mat-card class="opt-out-card">
    <img alt="Bild für Newsletter" class="opt-out-header-image" mat-card-image
         src="../../../../assets/img/thankyou-header.png">

    <mat-card-title>Mailing-Abmeldung</mat-card-title>
    <mat-card-content class="card-content">

      <p>Wir finden es schade, dass du uns verlässt! 😔 <br/>
        Bitte teile uns den Grund für deine Abmeldung mit:</p>

      <form (ngSubmit)="submit()" [formGroup]="optOutForm">
        <mat-radio-group aria-label="Wähle einen Grund" formControlName="reason">
          <div *ngFor="let reason of this.optOutReason">
            <mat-radio-button [value]="reason">{{reason | optOutReason}}</mat-radio-button>
          </div>
        </mat-radio-group>

        <div class="text-area w-100">
          <hid-textarea
            [control]="optOutForm.controls['comment']"
            charCounterShow="true"
            label="Der Grund für deine Abmeldung (optional)"
            maxLength="500"
            minRows="5"></hid-textarea>
        </div>
      </form>

    </mat-card-content>
    <mat-card-actions>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="footer-text">Wir wünschen dir weiterhin viel Erfolg! 🎓🤝</div>
        <button (click)="submit()" color="warn" mat-flat-button type="submit">Abmelden</button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>


<div *ngIf="submitted" class="content-wrapper">
  <hid-loading-spinner [loadingBool]="loading">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!loading">
      <div *ngIf="iteminfo.state === 'VALID'" class="itemInfo" fxLayout="column" fxLayoutAlign="start center">
        <h1 class="text-red">Abmeldung bestätigt</h1>
        <p>Du erhältst bis auf weiteres keine Neuigkeiten mehr von uns.</p>
      </div>

      <!-- On Token Invalid -->
      <div class="itemInfo" fxLayout="column" fxLayoutAlign="start center" *ngIf="!iteminfo.state === 'VALID'">
        <i class="fa fa-times text-red" style="font-size: 50px; margin-right: 30px"></i>
        <p>Dein Link ist leider ungültig <i class="fa fa-frown"></i></p>
        <button (click)="openSupportModal()" class="hid-button secondary inverse"><i
          class="fa fa-question-mark"></i>Support kontaktieren
        </button>
      </div>
    </div>
  </hid-loading-spinner>
</div>

<!-- TODO has to be configured somehwere but its unclear if HIDCMS or CRONOS. Recmommend first because it's onpage trafficcontrol, but not sure
<app-offer-page-panel [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">

</app-offer-page-panel>

-->


