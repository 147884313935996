import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-hid-snackbar',
  templateUrl: './hid-snackbar.component.html',
  styleUrls: ['./hid-snackbar.component.scss']
})
export class HidSnackbarComponent implements OnInit {

  @Input() text: string;
  @Input() closeText: string;
  @Input() error: boolean;

  @Output() selfDestroy: EventEmitter<any> = new EventEmitter()

  initialized;

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      this.initialized = true;
    }, 300)
  }

  destroySelf() {
    this.selfDestroy.emit()
  }
}
