import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-offer-last-page-panel',
  templateUrl: './offer-last-page-panel.component.html',
  styleUrls: ['./offer-last-page-panel.component.scss']
})
export class OfferLastPagePanelComponent implements OnInit {

  @ViewChild("image", { static: true }) image: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

}
