import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {HomePageResource} from "./generated/cms/resources";

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private homeResource: HomePageResource
  ) {
  }

  homepageTitleTag: string = "Hochschulinitiative Deutschland";

  resetMetaTags() {
    this.meta.updateTag({name: 'title', content: 'Hochschulinitiative Deutschland'});
    this.meta.updateTag({
      name: 'description', content: 'Die Hochschulinitiative Deutschland ' +
        'ist dein Partner im Studium:' +
        ' Wir bieten dir interessante Seminare, Vorlagen & tolle Blogartikel für dein erfolgreiches Studium!'
    });
    this.resetRobotsTag()

  }

  resetTitleTag() {
      this.titleService.setTitle(this.homepageTitleTag)
  }

  resetRobotsTag() {
    this.meta.updateTag({name: 'robots', content: 'index'});
  }

  setTitleTagForHomepage() {
    this.homeResource.getHomePageTitleTag().then(result => {
      this.homepageTitleTag = result
      this.titleService.setTitle(this.homepageTitleTag)
    })
  }

  setCustomTitleTag(customTitleTag: string) {
    this.titleService.setTitle(customTitleTag)
  }

  setRobotsTag(index: boolean) {
    let content = ''
    if (index == true) {
      content = 'index'
    } else content = 'noindex'
    this.setMetaTag('robots',  content)
  }

  setMetaTag(tag: string, text: string) {
    this.removeMetaTag(tag)

    if (this.getMetaTag(tag) == null) {
      this.meta.addTag({name: tag, content: text});
    } else {
      this.meta.updateTag({name: tag, content: text});
    }
  }

  getMetaTag(tag: string) {
    this.meta.getTag(`name="${tag}"`)
  }

  removeMetaTag(tag: string) {
    this.meta.removeTag(`name="${tag}"`)
  }

}
