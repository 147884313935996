

<div class="confirmation-container" fxLayoutAlign="center center" fxLayout="column">
  <h3 style="text-align: center" *ngIf="confirmationToken">Bitte bestätige deine Email-Adresse</h3>
  <h3 style="text-align: center" *ngIf="!confirmationToken">Dir wurde noch keine Bestätigungs-Mail zugeschickt oder du hast deine Email-Adresse schon bestätigt.</h3>

  <div *ngIf="init || confirming ">

    <svg *ngIf="emailConfirmed" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
      <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
    </svg>

    <div class="status-text confirm" *ngIf="emailConfirmed">
      Email wurde bestätigt
    </div>

    <div class="status-text failed" *ngIf="!emailConfirmed">
      Email wurde bereits bestätigt
    </div>

  </div>

  <button mat-flat-button class="primary-uniwunder-button" [disabled]="!confirmationToken || emailConfirmed" (click)="confirmEmail()">Email bestätigen</button>


</div>


