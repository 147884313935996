import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {OffersDropdownConfigData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.OfferDropdownConfigController
 */

@Injectable()
@ResourceParams({})
export class OfferDropdownConfigResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/pageconfigs/offersdropdown',
        endpoint: 'cms'
    })
    private _getOfferDropdownConfig: IResourceMethodObservableStrict<void, {}, {}, OffersDropdownConfigData>;
    getOfferDropdownConfig(): Promise<OffersDropdownConfigData> {
        return this._getOfferDropdownConfig(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/pageconfigs/offersdropdown',
        endpoint: 'cms'
    })
    private _updateOfferDropdownConfig: IResourceMethodObservableStrict<OffersDropdownConfigData, {}, {}, void>;
    updateOfferDropdownConfig(requestBody: OffersDropdownConfigData): Promise<void> {
        return this._updateOfferDropdownConfig(requestBody, null, null).toPromise()
    }

}