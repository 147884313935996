import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {MlpServiceFormComponent} from "./mlp-service-form/mlp-service-form.component";
import {MlpServiceFormRedirectPageComponent} from "./mlp-service-form/mlp-service-form-redirect-page/mlp-service-form-redirect-page.component";
import {HidInteractionsModule} from "../hid-interactions/hid-interactions.module";
import {FlexModule} from "@angular/flex-layout";
import {MatRadioModule} from "@angular/material/radio";
import {MatDialogModule} from "@angular/material/dialog";
import {OfferPagePanelModule} from "../offer-page-panel/offer-page-panel.module";
import {RatingModule} from "ng-starrating";
import {InnoUtilsModule} from "../inno-utils/inno-utils.module";
import {MatCardModule} from "@angular/material/card";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {SeminarProcessAPIResourceV2, ServiceFormResource} from "../generated/cronos/resources";

const appRoutes: Routes = [
  {path: 'actions/service-form/token', component: MlpServiceFormComponent },
  {path: 'actions/service-form/redirect', component: MlpServiceFormRedirectPageComponent}
];

@NgModule({
  declarations: [
    MlpServiceFormComponent,
    MlpServiceFormRedirectPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(appRoutes),
    HidInteractionsModule,
    FlexModule,
    MatRadioModule,
    MatDialogModule,
    InnoUtilsModule,
    MatPaginatorModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule
  ],
  exports: [
  ],
  providers: [
    SeminarProcessAPIResourceV2,
    ServiceFormResource
  ]
})
export class MlpServiceFormStudentModule { }
