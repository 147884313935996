import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OnlineLectureService} from "../../online-lecture.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-create-appointment-request-dialog',
  templateUrl: './create-appointment-request-dialog.component.html',
  styleUrls: ['./create-appointment-request-dialog.component.scss']
})
export class CreateAppointmentRequestDialogComponent implements OnInit {

  form: FormGroup;
  date;
  openedPicker = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateAppointmentRequestDialogComponent>,
    public onlineLectureService: OnlineLectureService,
  ) {
  }

  ngOnInit() {
    if (this.data.date) this.date = new Date(this.data.date);
    else this.date = new Date()
    this.form = this.onlineLectureService.initializeAppointmentDate(this.form);
  }

  close() {
    this.dialogRef.close();
  }

  requestAppointment() {
    this.dialogRef.close(this.form);
  }

  updateFormDate(value) {
    this.form.get('date').setValue(value)
  }

  getAppointmentRequestText() {
    if (this.data.currentLesson.appointmentContentConfig && this.data.currentLesson.appointmentContentConfig.dialogText) return this.data.currentLesson.appointmentContentConfig.dialogText;
    else return "Sichere dir jetzt deinen Wunschtermin für den zweiten Seminarteil!";
  }

  isDateSet() {
    return this.data.isDateSet;
  }

}
