import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  NgxGalleryAnimation,
  NgxGalleryComponent,
  NgxGalleryImage,
  NgxGalleryImageSize,
  NgxGalleryOptions
} from "@kolkov/ngx-gallery";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {
  StudyscriptPrintOptionDialogComponent
} from "./studyscript-print-option-dialog/studyscript-print-option-dialog.component";
import {MediaService} from "../media.service";
import {AnalyticsService} from "../analytics/analytics.service";

@Component({
  selector: 'app-studyscript',
  templateUrl: './studyscript.component.html',
  styleUrls: ['./studyscript.component.scss']
})
export class StudyscriptComponent implements OnInit, OnDestroy {

  productContentData: StudyScriptProductContent[] = [
    {
      type: 'Thesis',
      heading: !this.mediaService.isMobile() ?
        'Abschlussarbeit professionell drucken <br> & binden lassen' : 'Abschlussarbeit professionell drucken & binden lassen',
      price: '10,12',
      shipping: 'Kostenfreier Versand',
      usp: 'Hochwertige Bindung und das beste Papier',
      details: 'Deine Arbeit ist in 2-3 Werktagen bei dir. Wenn es ganz eilig ist, wähle einfach den Expressversand mit Zustellung innerhalb von 24h.',
      buttonText: 'Jetzt Abschlussarbeit drucken'
    },
    {
      type: 'Script',
      heading: 'Skripte ausdrucken & effektiver lernen',
      price: '2,30',
      shipping: 'Versandzeit 3-4 Werktage',
      usp: 'Zufriedenheits- und Bestpreisgarantie',
      details: 'Schon gewusst? 98% aller Studis lernen mit ausgedruckten Skripten besser.*',
      subDetail: "*Studyscript-Umfrage mit 63 Studierenden",
      buttonText: 'Jetzt Skript drucken'
    }
  ]

  galleryOptions: NgxGalleryOptions[] = [
    {
      thumbnails: false,
      preview: false,
      arrowPrevIcon: 'mdi mdi-chevron-left',
      arrowNextIcon: 'mdi mdi-chevron-right',
      imageAnimation: NgxGalleryAnimation.Slide,
      width: '100%',
      imageSwipe: true,
      imageSize: NgxGalleryImageSize.Contain
    }
  ]

  galleryImages: NgxGalleryImage[] = [
    {
      small: "assets/img/studyscript/bachelorarbeit.jpg",
      medium: "assets/img/studyscript/bachelorarbeit.jpg",
      big: "assets/img/studyscript/bachelorarbeit.jpg"
    },
    {
      small: "assets/img/studyscript/skript.jpg",
      medium: "assets/img/studyscript/skript.jpg",
      big: "assets/img/studyscript/skript.jpg"
    }
  ]

  productIndex: number = 0

  @ViewChild('gallery') gallery: NgxGalleryComponent


  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public mediaService: MediaService,
    private analyticsService: AnalyticsService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  openSelectPrintDialog() {
    this.dialog.open(StudyscriptPrintOptionDialogComponent, {
      width: 'calc(100% - 2em)',
      maxWidth: '55em'
    })
  }

  reportStudyscriptTrackingEvent(context: string) {
    if (this.productContentData[this.productIndex].type == 'Thesis') this.analyticsService.reportRequestThesisPrint(context)
    else this.analyticsService.reportRequestScriptPrint(context)
  }

  onToggleChange(event: any) {
    this.productIndex = event.target.checked ? 1 : 0
    this.gallery.show(this.productIndex)
  }

  onImageChange(event: any) {
    this.productIndex = event.index
  }

}

export class StudyScriptProductContent {
  type: 'Script' | 'Thesis'
  heading: string
  price: string
  shipping: string
  usp: string
  details: string
  subDetail?: string
  buttonText: string
}
