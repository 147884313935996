import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {StandardCheckoutGatewayComponent} from './standard-checkout-gateway/standard-checkout-gateway.component';
import {StandardCheckoutS2Module} from './standard-checkout-s2/standard-checkout-s2.module';
import {StandardCheckoutS1Module} from './standard-checkout-s1/standard-checkout-s1.module';


const appRoutes: Routes = [{path: 'angebote/:routingUrl/checkout/standard/g', component: StandardCheckoutGatewayComponent}];

@NgModule({
  declarations: [StandardCheckoutGatewayComponent],
  imports: [
    CommonModule,
    StandardCheckoutS2Module,
    StandardCheckoutS1Module,
    RouterModule.forChild(appRoutes)
  ]
})
export class StandardCheckoutModule {
}
