import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {IsolatedPageData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.IsolatedPageController
 */

@Injectable()
@ResourceParams({})
export class IsolatedPageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/pages/isolated',
        endpoint: 'cms'
    })
    private _getIsolatedPages: IResourceMethodObservableStrict<void, {}, {}, IsolatedPageData[]>;
    getIsolatedPages(): Promise<IsolatedPageData[]> {
        return this._getIsolatedPages(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/pages/isolated/{id}',
        endpoint: 'cms'
    })
    private _updateIsolatedPage: IResourceMethodObservableStrict<IsolatedPageData, {}, {id: number}, void>;
    updateIsolatedPage(requestBody: IsolatedPageData, id: number): Promise<void> {
        return this._updateIsolatedPage(requestBody, null, {id: id}).toPromise()
    }

}