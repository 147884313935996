import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'optOutReason'
})
export class OptOutReasonPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case "FeedbackNoStudent":
        return "Ich bin kein Student mehr";
      case "FeedbackTooManyMails":
        return "Ich bekomme zu viele E-Mails";
      case "FeedbackNoInterest":
        return "Ich bin nicht mehr interessiert";
      case "FeedbackOther":
        return "Sonstiges";

      default:
        return null;
    }
  }

}
