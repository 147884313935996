import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {MediaService} from "../../media.service";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-studyscript-purchase-config',
  templateUrl: './studyscript-purchase-config.component.html',
  styleUrls: ['./studyscript-purchase-config.component.scss']
})
export class StudyscriptPurchaseConfigComponent implements OnInit, OnDestroy {

  iframeLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  iframeSrc: string = "https://studyscript.de/produkt/bachelor-oder-masterarbeit/?random="

  configType: 'Thesis' | 'Script' = 'Thesis'

  fragmentSub: Subscription

  constructor(
    public mediaService: MediaService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.fragmentSub = this.route.fragment.subscribe(fragment => {
      if (fragment == 'Thesis' || fragment == 'Script') {
        this.configType = fragment
        this.setIframeSrc()
        this.location.replaceState(window.location.pathname)
      }
    })
  }

  ngOnInit(): void {
    this.setIframeSrc()
  }

  ngOnDestroy() {
    this.fragmentSub.unsubscribe()
  }

  private initIframe() {
    this.iframeLoading.next(true)

    const iframe = document.createElement('iframe')
    const placeholder = document.getElementById('studyscript-iframe')
    iframe.id = "studyscript-iframe"
    iframe.name = "the_frame_" + Math.floor(Date.now() / 1000)
    iframe.src = this.iframeSrc + Math.floor(Date.now() / 1000)

    //iframe styles
    iframe.style.position = "relative";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.bottom = "0";
    iframe.style.right = "0";
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.border = "none";
    iframe.style.margin = "0";
    iframe.style.padding = "0";
    iframe.style.overflow = "hidden";

    //catch when iframe loaded
    iframe.onload = () => {
      this.iframeLoading.next(false)
    }

    placeholder?.parentNode.replaceChild(iframe, placeholder)
  }

  setIframeSrc() {
    if (this.configType == 'Thesis') {
      this.iframeSrc = "https://studyscript.de/produkt/bachelor-oder-masterarbeit/?random="
    } else if (this.configType == 'Script') {
      this.iframeSrc = "https://studyscript.de/produkt/skript-ohne-werbung/?random="
    }

    this.initIframe()
  }

  switchConfigType() {
    switch (this.configType) {
      case "Script":
        this.configType = "Thesis"
        break
      case "Thesis":
        this.configType = "Script"
        break
    }

    this.setIframeSrc()
  }

}
